import { useEffect, useState } from "react";
//
import { getFarVisionList, getIdentifiedLetters, getNearVisionList } from "@/services";
import { VisualAcuityParams } from "../VisualAcuity/VisualAcuity";
//
import { SectionCard, Select } from "@/components";
import { IdentifiedLetter } from "@/models/select/indentifiedLetter";
import { Vision } from "@/models/select/vision";
import { Ao, Correction } from "@/models/sheets/optometry";

export default function AvNoCorrection({ externalPayload, setExternalPayload, onSubmit, isDisabledForm, historySheet }: VisualAcuityParams) {
    const [farVision, setFarVision] = useState<Vision[]>([]);
    const [nearVision, setNearVision] = useState<Vision[]>([]);
    const [idLetters, setIdLetters] = useState<IdentifiedLetter[]>([]);

    useEffect(() => {
        async function fetch() {
            if (!historySheet) {
                const farVisionResponse = await getFarVisionList();
                const nearVisionResponse = await getNearVisionList();
                const idLettersResponse = await getIdentifiedLetters();

                setFarVision(farVisionResponse.results);
                setNearVision(nearVisionResponse.results);
                setIdLetters(idLettersResponse.results);
            }
        }
        fetch();
    }, []);

    const farVisionFormatted = farVision?.map(item => ({ label: item.value, value: item.id }));
    const nearVisionFormatted = nearVision?.map(item => ({ label: item.value, value: item.id }));
    const idLettersFormatted = idLetters?.map(item => ({ label: item.symbol, value: item.id }));

    const onChangeCorrectionsSelect = (tableType: {
        vision: keyof Ao,
        type: "correction" | "noCorrection";
        eye: keyof Correction;
        value?: number | string;
        label: "id" | "idle";
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                [tableType.type]: {
                    ...externalPayload.visualAcuity?.[tableType.type],
                    [tableType.eye]: {
                        ...externalPayload.visualAcuity?.[tableType.type]?.[tableType.eye],
                        [tableType.vision]: {
                            ...externalPayload.visualAcuity?.[tableType.type]?.[tableType.eye]?.[tableType.vision],
                            [tableType.label]: tableType.value
                        }
                    }
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const renderSmallTable = (tableType: {
        label: string,
        vision: "far" | "near",
        type: "correction" | "noCorrection";
    }) => {
        const odValue = tableType.vision === "far"
            ? farVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.id)
            : nearVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.id);

        const odValueInput = tableType.vision === "far"
            ? externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.value
            : externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.value;

        const oiValue = tableType.vision === "far"
            ? farVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.id)
            : nearVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.id);

        const oiValueInput = tableType.vision === "far"
            ? externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.value
            : externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.value;

        const aoValue = tableType.vision === "far"
            ? farVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.id)
            : nearVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.id);
        const aoValueInput = tableType.vision === "far"
            ? externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.value
            : externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.value;

        const odIdleValue = idLettersFormatted.find(idf => idf.value === externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.idle);
        const odIdleValueInput = externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.value;
        const oiIdleValue = idLettersFormatted.find(idf => idf.value === externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.idle);
        const oiIdleValueInput = externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.value;
        const aoIdleValue = idLettersFormatted.find(idf => idf.value === externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.idle);
        const aoIdleValueInput = externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.value;


        return (
            <table className="flex-fill">
                <thead>
                    <tr>
                        {tableType.vision === "far" ? (
                            <td className="p-2"></td>
                        ) : (
                            <td className="px-1"></td>
                        )}
                        <td colSpan={2} align="center" className="text-secondary">{tableType.label}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr className="rounded">
                        {tableType.vision === "far" ? (
                            <td className="p-2 text-secondary ps-2 bg-od" style={{ borderTopLeftRadius: "0.5rem" }}>OD</td>
                        ) : (
                            <td className="px-1"></td>
                        )}
                        <td className="p-2 bg-od" style={{ borderTopLeftRadius: tableType.vision === "near" ? "0.5rem" : undefined }}>
                            <Select
                                options={tableType.vision === "far" ? farVisionFormatted : nearVisionFormatted}
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={odValueInput}
                                style={{ width: "100%" }}
                                name="od"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "od",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "id"
                                })}
                                value={odValue}
                            />
                        </td>
                        <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                            <Select
                                options={idLettersFormatted}
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={odIdleValueInput}
                                style={{ width: "100%" }}
                                name="od"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "od",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "idle"
                                })}
                                value={odIdleValue}
                            />
                        </td>
                    </tr>
                    <tr>
                        {tableType.vision === "far" ? (
                            <td className="bg-oi p-2 text-secondary ps-2" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                        ) : (
                            <td className="px-1 text-secondary"></td>
                        )}
                        <td className="p-2 bg-oi" style={{ borderBottomLeftRadius: tableType.vision === "near" ? "0.5rem" : undefined }}>
                            <Select
                                options={tableType.vision === "far" ? farVisionFormatted : nearVisionFormatted}
                                style={{ width: "100%" }}
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={oiValueInput}
                                name="oi"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "oi",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "id"
                                })}
                                value={oiValue}
                            />
                        </td>
                        <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                            <Select
                                options={idLettersFormatted}
                                style={{ width: "100%" }}
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={oiIdleValueInput}
                                name="oi"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "oi",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "idle"
                                })}
                                value={oiIdleValue}
                            />
                        </td>
                    </tr>
                    <tr>
                        {tableType.vision === "far" ? (
                            <td className="p-2 text-secondary ps-2">AO</td>
                        ) : (
                            <td className="px-1 text-secondary"></td>
                        )}
                        <td className="p-2">
                            <Select
                                options={tableType.vision === "far" ? farVisionFormatted : nearVisionFormatted}
                                style={{ width: "100%" }}
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={aoValueInput}
                                name="ao"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "ao",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "id"
                                })}
                                value={aoValue}
                            />
                        </td>
                        <td className="p-2">
                            <Select
                                options={idLettersFormatted}
                                style={{ width: "100%" }}
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={aoIdleValueInput}
                                name="ao"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "ao",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "idle"
                                })}
                                value={aoIdleValue}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const render = () => {
        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">AV sin corrección</h5>
                <hr className="text-primary mt-2 mb-1" />
                <div className="row g-0">
                    <div className="col-6">
                        {renderSmallTable({
                            label: "VL",
                            vision: "far",
                            type: "noCorrection"
                        })}
                    </div>
                    <div className="col-6">
                        {renderSmallTable({
                            label: "VP",
                            vision: "near",
                            type: "noCorrection"
                        })}
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}