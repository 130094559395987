import { Checkbox, SectionCard, TextField } from "@/components";
import { AdmissionNotes } from "@/models/surgery/patientAttention/patientAttention";

const Admission = ({ data, readOnly }: {
    data?: AdmissionNotes,
    readOnly?: boolean;
}) => {
    return (
        <div className="my-3 mx-4  row">
            <div className=" col-4">

                <SectionCard className="mt-3" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Signos vitales</h5>
                    </div>
                    <hr className="lineCards" />
                    <div className="row g-3 align-items-center ">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="w-100">FC</div>
                            <TextField
                                value={data?.vitalSigns?.fc?.value}
                                type="number"
                                variant="plain"
                                readOnly
                            />
                        </div>

                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="w-100">FR</div>
                            <TextField
                                value={data?.vitalSigns?.fr?.value}
                                type="number"
                                variant="plain"
                                readOnly
                            />
                        </div>

                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="w-100">SO2</div>
                            <TextField
                                value={data?.vitalSigns?.so2?.value}
                                type="number"
                                variant="plain"
                                readOnly
                            />
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="w-100">TA</div>
                            <TextField
                                value={data?.vitalSigns?.ta?.value}
                                type="number"
                                variant="plain"
                                readOnly
                            />
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="w-100">TAM</div>
                            <TextField
                                value={data?.vitalSigns?.tam?.value}
                                type="number"
                                variant="plain"
                                readOnly
                            />
                        </div>


                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="w-100">G</div>
                            <TextField
                                value={data?.vitalSigns?.g?.value}
                                type="number"
                                variant="plain"
                                readOnly
                            />
                        </div>
                    </div>

                </SectionCard>
            </div>
            <div className=" col-8">

                <SectionCard className="mt-3" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Notas de admisión</h5>
                    </div>
                    <hr className="lineCards" />
                    <textarea
                        className="form-control no-resize w-100"
                        style={{ height: 100 }}
                        readOnly
                        value={data?.admissionNotes}
                    />
                </SectionCard>
            </div>
            <div className="col-12">

                <SectionCard className="mt-3" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Lista de chequeo</h5>
                    </div>
                    <hr className="lineCards" />
                    {data?.checklist && data?.checklist?.length > 0 && data?.checklist?.map((check) => {
                        return (
                            <Checkbox
                                className="text-muted fw-normal mb-2"
                                label={check.description}
                                readOnly
                                checked={check?.checked === 1 ? true : false}
                            />

                        );
                    })}
                </SectionCard>
            </div>


        </div>
    );
};

export default Admission;