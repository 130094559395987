import { SectionCard } from "@/components";
//
import LeftHeadImage from "@/assets/orthoptic/left.png";
import RightHeadImage from "@/assets/orthoptic/right.png";
import { BielschowskyProps, OrthopticPayload } from "@/models/sheets/orthoptic";

interface BielschowskyParams {
    orthopticPayload: OrthopticPayload;
    setBielschowskyPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
}

export default function BielschowskyTest({ orthopticPayload, setBielschowskyPayload, onSubmit, isDisabledForm }: BielschowskyParams) {

    const onChangeObservation = (label: BielschowskyProps, value: string) => {
        const options = {
            ...orthopticPayload,
            bielshowschky: {
                ...orthopticPayload.bielshowschky,
                [label]: value
            }
        };
        setBielschowskyPayload(options);
        onSubmit();
    };

    const render = () => {
        return (
            <SectionCard>
                <h5 className="text-secondary fw-bold">Test de Bielschowsky</h5>
                <hr className="text-primary mt-2" />
                <div className="d-flex">
                    <div className="pe-3 col-12">
                        <div className="row">
                            <div className="col-2">
                            </div>
                            <div className="col-5">
                                <h4 className="text-center text-secondary">VL</h4>
                            </div>
                            <div className="col-5">
                                <h4 className="text-center text-secondary">VP</h4>
                            </div>
                        </div>

                        <div className="d-flex gap-2">
                            <div className="col-2 border rounded pt-2 ps-2 pe-2 text-center ">
                                <img src={LeftHeadImage} className="img-fluid" width="50px" alt="" />
                                <h6 className="text-muted mt-2">Derecha</h6>
                            </div>
                            <div className="col-5">

                                <textarea
                                    disabled={isDisabledForm}
                                    className="form-control h-100 no-resize border"
                                    placeholder="Observaciones..."
                                    value={orthopticPayload.bielshowschky?.bielshowschkyVlRight}
                                    onChange={({ target }) => onChangeObservation("bielshowschkyVlRight", target.value)}
                                />
                            </div>
                            <div className="col-5">

                                <textarea
                                    disabled={isDisabledForm}
                                    className="form-control h-100 no-resize border"
                                    placeholder="Observaciones..."
                                    value={orthopticPayload.bielshowschky?.bielshowschkyVpRight}
                                    onChange={({ target }) => onChangeObservation("bielshowschkyVpRight", target.value)}
                                />
                            </div>
                        </div>
                        <div className=" mt-3 d-flex gap-2">
                            <div className="col-2 border rounded pt-2 ps-3 pe-2 text-center">
                                <img src={RightHeadImage} className="img-fluid" width="50px" alt="" />
                                <h6 className="text-muted mt-2">Izquierda</h6>
                            </div>
                            <div className="col-5">
                                <textarea
                                    disabled={isDisabledForm}
                                    className="form-control h-100 no-resize border"
                                    placeholder="Observaciones..."
                                    value={orthopticPayload.bielshowschky?.bielshowschkyVlLeft}
                                    onChange={({ target }) => onChangeObservation("bielshowschkyVlLeft", target.value)}
                                />
                            </div>
                            <div className="col-5">
                                <textarea
                                    disabled={isDisabledForm}
                                    className="form-control h-100 no-resize border"
                                    placeholder="Observaciones..."
                                    value={orthopticPayload.bielshowschky?.bielshowschkyVpLeft}
                                    onChange={({ target }) => onChangeObservation("bielshowschkyVpLeft", target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}