import React from "react";
import ReactDOM from "react-dom/client";
import "dayjs/locale/es-mx";
import dayjs from "dayjs";
//
import Root from "./Root";
import reportWebVitals from "./reportWebVitals";
//
import "bootstrap/dist/js/bootstrap.bundle.min";
//
import "react-tooltip/dist/react-tooltip.css";
import "./index.scss";

dayjs.locale("es-mx");

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
