import { ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
//
import { TraditionalTonometry } from "@/models/historyDetails/ophthalmology";
import { formatHistoryDetails } from "@/utils";
//
import { Segments } from "@/models/generalFields";
import { DIListItem } from "@/models/select/diagnosticImpression";
import { OftalmologySheet, ProvocativeEvidence } from "@/models/sheets/oftalmology";
import "../Details.scss";

export default function OphthalmologyDetails({ ophthalmologyDetails, forDownload }: { ophthalmologyDetails: OftalmologySheet; forDownload?: boolean; }) {

    const options: ChartOptions<"line"> = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: "PIO",
                    color: "#003F80",
                }
            },
            x: {
                title: {
                    display: true,
                    text: "Hora",
                    color: "#003F80",

                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: true,
                align: "center",
                fullSize: false,
                labels: {
                    usePointStyle: true,
                    boxHeight: 8,
                },
                title: {
                    display: true,
                    position: "start",
                },
            },
        },
    };


    const plugin = {
        id: "a",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        beforeInit(chart: any) {
            const originalFit = chart.legend.fit;

            // override the fit function
            chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
            };
        }
    };

    const renderAttentionOrigin = (attentionOrigin: Segments | null) => {

        const motiveIss = attentionOrigin?.predefinedTxts ? attentionOrigin?.predefinedTxts?.find(t=> t.prefix === "current_disease") : {predifinedText: []};
        const motiveReason = attentionOrigin?.predefinedTxts ? attentionOrigin?.predefinedTxts?.find(t=> t.prefix === "reason_attention") : { predifinedText: []};


        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin?.generalFields?.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin?.generalFields?.attentionOrigin?.name}
                        </h5>
                    ) : ""}
                    {attentionOrigin.generalFields.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.generalFields.abserveEvent === 1 ? "SÍ" : "NO"}
                        </h5>
                    ) : ""}

                    <>
                        <h5 className="fw-bold mt-3">
                                Motivo consulta:
                        </h5> 
                        <h5>{motiveReason?.predifinedText.map(x=> x.name).join(", ")}</h5>
                            
                        <h5>{attentionOrigin.reasonForConsultingJst}</h5>
                    </>
                    <>
                        <h5 className="fw-bold mt-3">
                                Enfermedad actual:
                        </h5>

                        <h5>{motiveIss?.predifinedText.map(x=> x.name).join(", ")}</h5>
                        <h5>{attentionOrigin.currentDiseaseJst || ""}</h5>
                    </>
                    {attentionOrigin.generalFields.evolutionTime ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.generalFields.evolutionTime} - {attentionOrigin.generalFields.evolutionTimeUnit?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderPhysicalExams = () => {
        const external = ophthalmologyDetails.external;
        const ppl = ophthalmologyDetails.ppl;
        const screraConjunctiva = ophthalmologyDetails.screra_conjunctiva;
        const cornea = ophthalmologyDetails.cornea;
        const previousChamber = ophthalmologyDetails.previous_chamber;
        const iris = ophthalmologyDetails.iris;
        const crystalline = ophthalmologyDetails.crystalline;
        const retinaVitreous = ophthalmologyDetails.retina_vitreous;
        const opticNerve = ophthalmologyDetails.optic_nerve;

        if (external || ppl || screraConjunctiva || cornea || previousChamber || iris || crystalline || retinaVitreous || opticNerve) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Examen físico
                    </h4>
                    {external?.od?.findings && external?.od?.findings?.length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Externo OD: {external.od.findings.map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{external.od.justifications}</h5>
                        </>
                    ) : ""}
                    {external?.oi?.findings && external?.oi?.findings.length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Externo OI: {(external.oi.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{external.oi.justifications}</h5>
                        </>
                    ) : ""}

                    {ppl?.od?.findings && (ppl.od.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                <span className="">P/P/L OD:</span> {(ppl.od.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{ppl.od.justifications}</h5>
                        </>
                    ) : ""}
                    {ppl?.oi?.findings && (ppl.oi.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                P/P/L OI: {(ppl.oi.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{ppl.oi.justifications}</h5>
                        </>
                    ) : ""}

                    {screraConjunctiva?.od?.findings && (screraConjunctiva.od.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Conjuntiva/Esclera OD: {(screraConjunctiva.od.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{screraConjunctiva.od.justifications}</h5>
                        </>
                    ) : ""}
                    {screraConjunctiva?.oi?.findings && screraConjunctiva.oi.findings.length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Conjuntiva/Esclera OI: {(screraConjunctiva.oi.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{screraConjunctiva.oi.justifications}</h5>
                        </>
                    ) : ""}

                    {cornea?.od?.findings && (cornea.od.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Córnea OD: {(cornea.od.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{cornea.od.justifications}</h5>
                        </>
                    ) : ""}
                    {cornea?.oi?.findings && (cornea.oi.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Córnea OI: {(cornea.oi.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{cornea.oi.justifications}</h5>
                        </>
                    ) : ""}

                    {previousChamber?.od?.findings && (previousChamber.od.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Cámara anterior OD: {(previousChamber.od.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{previousChamber.od.justifications}</h5>
                        </>
                    ) : ""}
                    {previousChamber?.oi?.findings && (previousChamber.oi.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Cámara anterior OI: {(previousChamber.oi.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{previousChamber.oi.justifications}</h5>
                        </>
                    ) : ""}

                    {iris?.od?.findings && (iris.od.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Iris OD: {(iris.od.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{iris.od.justifications}</h5>
                        </>
                    ) : ""}
                    {iris?.oi?.findings && (iris.oi.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Iris OI: {(iris.oi.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{iris.oi.justifications}</h5>
                        </>
                    ) : ""}

                    {crystalline?.od?.findings && (crystalline.od.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Cristalino OD: {(crystalline.od.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{crystalline.od.justifications}</h5>
                        </>
                    ) : ""}
                    {crystalline?.oi?.findings && (crystalline.oi.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Cristalino OI: {(crystalline.oi.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{crystalline.oi.justifications}</h5>
                        </>
                    ) : ""}

                    {retinaVitreous?.od?.findings && (retinaVitreous.od.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Retina/Vítreo OD: {(retinaVitreous.od.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{retinaVitreous.od.justifications}</h5>
                        </>
                    ) : ""}
                    {retinaVitreous?.oi?.findings && (retinaVitreous.oi.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Retina/Vítreo OI: {(retinaVitreous.oi.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{retinaVitreous.oi.justifications}</h5>
                        </>
                    ) : ""}

                    {opticNerve?.od?.findings && (opticNerve.od.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Nervio óptico OD: {(opticNerve.od.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{opticNerve.od.justifications}</h5>
                        </>
                    ) : ""}
                    {opticNerve?.oi?.findings && (opticNerve.oi.findings).length > 0 ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Nervio óptico OI: {(opticNerve.oi.findings).map(x => x.name).join(" - ")}
                            </h5>
                            <h5>{opticNerve.oi.justifications}</h5>
                        </>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderIntraocularPresion = (traditionalTonometry: TraditionalTonometry | null) => {

        if (traditionalTonometry) {
            return (
                <div className="item">
                    <h4 className="fw-bold text-primary">Presión intraocular</h4>
                    <h4 className="mb-3">Tonometría tradicional</h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Detalle</th>
                                <th>OD</th>
                                <th>OI</th>
                            </tr>
                        </thead>
                        <tbody className="text-muted">
                            {traditionalTonometry.pachymetry ? (
                                <tr>
                                    <td className="fw-bold">Paquimetría</td>
                                    <td>{traditionalTonometry.pachymetry.od}</td>
                                    <td>{traditionalTonometry.pachymetry.oi}</td>
                                </tr>
                            ) : ""}

                            {traditionalTonometry.correction ? (
                                <tr>
                                    <td className="fw-bold">Corrección</td>
                                    <td>{traditionalTonometry.correction.od}</td>
                                    <td>{traditionalTonometry.correction.oi}</td>
                                </tr>
                            ) : ""}
                            {traditionalTonometry.pio ? (
                                <tr>
                                    <td className="fw-bold">PIO</td>
                                    <td>{traditionalTonometry.pio.od}</td>
                                    <td>{traditionalTonometry.pio.oi}</td>
                                </tr>
                            ) : ""}
                            {traditionalTonometry.pioPachymetryAdjust ? (
                                <tr>
                                    <td className="fw-bold">PIO Ajustado a paquimetría</td>
                                    <td>{traditionalTonometry.pioPachymetryAdjust.od}</td>
                                    <td>{traditionalTonometry.pioPachymetryAdjust.oi}</td>
                                </tr>
                            ) : ""}
                            <tr>
                                <td colSpan={3}>
                                    <span className="fw-bold">Método: </span>{traditionalTonometry.pmmName}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else return "";
    };

    const renderProvocativeEvidence = (provocativeEvidence: ProvocativeEvidence[] | null) => {

        if (provocativeEvidence && provocativeEvidence.length) {
            const chartData = {
                labels: provocativeEvidence.map(p => p.time),
                datasets: [
                    {
                        label: "OD",
                        data: provocativeEvidence.map(p => p.evidenceOd),
                        borderColor: "#A3E4EC",
                        backgroundColor: "#A3E4EC",
                    },
                    {
                        label: "OI",
                        data: provocativeEvidence.map(p => p.evidenceOi),
                        borderColor: "#5C84AD",
                        backgroundColor: "#5C84AD",
                    }
                ],
            };

            return (
                <div className="item">
                    <h4 className="mb-3">Tonometría con pruebas provocativas</h4>
                    <div className="row">
                        <div className="col">
                            <table className="table table-bordered h-75">
                                <thead>
                                    <tr className="text-secondary">
                                        <th>Hora</th>
                                        <th>OD</th>
                                        <th>OI</th>
                                    </tr>
                                </thead>
                                <tbody className="text-muted">
                                    {provocativeEvidence.map(pe => (
                                        <tr key={pe.order}>
                                            <td>{pe.time}</td>
                                            <td>{pe.evidenceOd}</td>
                                            <td>{pe.evidenceOi}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="col" style={{ marginTop: -20 }}>
                            <Line options={options} data={chartData} plugins={[plugin]} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return "";
        }
    };

    const renderExtDiagnostic = (extDiagnostic: DIListItem[] | null) => {

        if (extDiagnostic && extDiagnostic.length > 0) {
            const mainExt = extDiagnostic.find(item => item.isMain === 1);

            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {extDiagnostic.map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td>{item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderAnalysisAndPlan = (analysisPlan: string | null) => {
        if (analysisPlan) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Análisis y plan
                    </h4>
                    <h5>{analysisPlan}</h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const render = () => {
        const attentionOrigin = ophthalmologyDetails.generalsFields ? ophthalmologyDetails.generalsFields[0] : null;
        const traditionalTonometry = formatHistoryDetails(ophthalmologyDetails.traditionalTonometry);
        const provocativeEvidence = formatHistoryDetails(ophthalmologyDetails.provocativeEvidence);
        const cie10ExtDiagnostic = ophthalmologyDetails.cie10ExtDiagnostic ?? null;
        const analysisPlan = formatHistoryDetails(ophthalmologyDetails.generalsFields ? ophthalmologyDetails.generalsFields[0].analysisPlanJst : "");

        return (
            <div className="detail-container">
                <div className={`${!forDownload ? "columns" : "column"}`}>
                    {renderAttentionOrigin(attentionOrigin)}
                    {renderPhysicalExams()}
                    {renderIntraocularPresion(traditionalTonometry)}
                    {renderProvocativeEvidence(Object.values(provocativeEvidence || {}))}
                    <div className="block-item">
                        {renderExtDiagnostic(cie10ExtDiagnostic)}
                        {renderAnalysisAndPlan(analysisPlan)}
                    </div>
                </div>

            </div >
        );
    };

    return render();
}