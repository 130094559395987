import { SectionCard } from "@/components";
import { LowVisionPayload } from "@/models/sheets/lowVision";
import { OptometryPayload } from "@/models/sheets/optometry";

interface DistancesParams {
    externalPayload: OptometryPayload | LowVisionPayload;
    setExternalPayload: (visualPayload: OptometryPayload | LowVisionPayload) => void;
    onSubmit: () => void;
    isDisabledForm?: boolean;
}
const Distances = ({ externalPayload, setExternalPayload, onSubmit, isDisabledForm }: DistancesParams) => {

    const onChangePupillaryDistance = (sec: "vl" | "vp", value: string) => {
        const options: OptometryPayload = {
            ...externalPayload,
            pupillaryDistance: {
                ...externalPayload.pupillaryDistance,
                [sec]: value
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const onChangeNasopupillaryDistance = (eye: "od" | "oi", value: string) => {
        const options: OptometryPayload = {
            ...externalPayload,
            nasopupillaryDistance: {
                ...externalPayload.nasopupillaryDistance,
                [eye]: value
            }
        };
        setExternalPayload(options);
        onSubmit();
    };
    return (
        <div className="row h-100">
            <div className="col-6 h-100">
                <SectionCard>
                    <h5 className="text-secondary fw-bold">Distancia pupilar</h5>
                    <hr className="text-primary mt-2" />
                    <table>
                        <tbody>
                            <tr>
                                <td className="p-2 text-secondary">VL</td>
                                <td className="p-2">
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.pupillaryDistance?.vl}
                                        onChange={({ target }) => onChangePupillaryDistance("vl", target.value)}
                                        onKeyDown={(event) => event.key === "." && event.preventDefault()}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2 text-secondary">VP</td>
                                <td className="p-2">
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.pupillaryDistance?.vp}
                                        onChange={({ target }) => onChangePupillaryDistance("vp", target.value)}
                                        onKeyDown={(event) => event.key === "." && event.preventDefault()}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </SectionCard>
            </div>
            <div className="col-6">
                <SectionCard>
                    <h5 className="text-secondary fw-bold">Distancia nasopupilar</h5>
                    <hr className="text-primary mt-2" />
                    <table>
                        <tbody>
                            <tr>
                                <td className="p-2 text-secondary">OD</td>
                                <td className="p-2">
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.nasopupillaryDistance?.od}
                                        onChange={({ target }) => onChangeNasopupillaryDistance("od", target.value)}
                                        onKeyDown={(event) => event.key === "." && event.preventDefault()}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2 text-secondary">OI</td>
                                <td className="p-2">
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.nasopupillaryDistance?.oi}
                                        onChange={({ target }) => onChangeNasopupillaryDistance("oi", target.value)}
                                        onKeyDown={(event) => event.key === "." && event.preventDefault()}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </SectionCard>

            </div>
        </div>
    );
};

export default Distances;