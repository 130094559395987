type allowedFormats = "jpg" | "jpeg" | "png" | "svg" | "pdf"

interface IConvertFileBase64 {
    files: FileList | null,
    allowedFormats: allowedFormats[]
}

export interface IResultConvertFileBase64 {
    success: boolean
    message: string
    data: {
        filename: string,
        base64: string,
        src: string,
    }
}

export const convertFileBase64 = ({ files, allowedFormats }: IConvertFileBase64) => {

    let result: IResultConvertFileBase64 = {
        success: false,
        message: "",
        data: { base64: "", filename: "", src: "" }
    };

    if (files?.length) {

        return new Promise<IResultConvertFileBase64>((resolve, reject) => {
            const type = files[0].type.split("/")[1];

            if (!allowedFormats.includes(type as allowedFormats)) {
                result = { ...result, message: `Formato "${type}" no permitido`, data: { base64: "", filename: "", src: "" } };
                reject(result);
            }

            Array.from(files).forEach(file => {

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async function () {
                    const rst = reader.result;
                    const file64 = String(rst).split(",")[1];

                    result = {
                        success: true,
                        message: "completed process",
                        data: {
                            filename: file.name,
                            base64: file64,
                            src: URL.createObjectURL(file),
                        }
                    };
                    resolve(result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
            });

        });
    }
};