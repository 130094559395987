import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { PredefinedTextPayload, PredefinedTextsParams, PredefinedTextsResponse } from "@/models/predefinedTexts";
import { setHeaders } from "@/utils";

export async function getPredefinedTextsService(params: PredefinedTextsParams): Promise<PredefinedTextsResponse> {
    const headers = setHeaders();
    const paramsData= {...params};
    const axiosParams = { headers, params: paramsData};

    const { data: serviceData } = await axiosInstance.get<PredefinedTextsResponse>(
        "/medical/clinicHistory/predefinedTexts/", axiosParams
    );
    return serviceData;
}

export async function createPredefinedTextService(payload: PredefinedTextPayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/predefinedTexts/", payload, axiosParams
    );
    return serviceData;
}

export async function updatePredefinedTextService(payload: PredefinedTextPayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/predefinedTexts/", payload, axiosParams
    );
    return serviceData;
}
export async function inhabilitatePredifinedTextService(payload: PredefinedTextPayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/predefinedTexts/", payload, axiosParams
    );
    return serviceData;
}