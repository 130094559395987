import { SectionCard, Select } from "@/components";
import { useAppDispatch } from "@/hooks";
import { IdentifiedLetter } from "@/models/select/indentifiedLetter";
import { OptotypeItem } from "@/models/select/optotype";
import { Vision } from "@/models/select/vision";
import { LowVisionPayload } from "@/models/sheets/lowVision";
import { Ao, Correction, GeneralSelects, LentsVision, OptometryPayload } from "@/models/sheets/optometry";
import { OrthopticPayload } from "@/models/sheets/orthoptic";
import { useEffect, useState } from "react";
import { getOptometrySelects } from "./visualAcuity.actions";
export interface VisualAcuityParams {
    externalPayload: OptometryPayload | LowVisionPayload | OrthopticPayload;
    setExternalPayload: (visualPayload: OptometryPayload | LowVisionPayload | OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm?: boolean;
    historySheet?: boolean;
}
export function Optotipe({ externalPayload, setExternalPayload, onSubmit, isDisabledForm, historySheet }: VisualAcuityParams) {
    const dispatch = useAppDispatch();

    const [generalSelects, setGeneralSelects] = useState<GeneralSelects>({
        optotypes: [],
        farVision: [],
        nearVision: [],
        idLetters: [],
        lensAdd: [],
        lensType: []
    });

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await dispatch(getOptometrySelects());
                setGeneralSelects({
                    optotypes: data?.optotypesResponse as OptotypeItem[],
                    farVision: data?.farVisionResponse as Vision[],
                    nearVision: data?.nearVisionResponse as Vision[],
                    idLetters: data?.idLettersResponse as IdentifiedLetter[],
                    lensAdd: data?.lensAddResponse as Vision[],
                    lensType: data?.lensTypeResponse as Vision[]
                });

            }
        }
        fetchData();
    }, [dispatch]);



    const optotypesFormatted = generalSelects.optotypes?.map(item => ({ label: item.name, value: item.id }));

    const render = () => {
        const optotypeValue = optotypesFormatted.find(opt => opt.value === externalPayload.visualAcuity?.optotype);
        const optotypeValueInput = externalPayload.visualAcuity?.optotype;


        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">Optotipo</h5>
                <hr className="text-primary mt-2" />
                <div className="d-flex flex-column align-items-center mt-4">
                    <div className="w-100 ">
                        <Select
                            style={{ width: 110 }} 
                            disabled={isDisabledForm}
                            options={optotypesFormatted}
                            changeToInput={historySheet}
                            inputValue={optotypeValueInput}
                            onChange={({ option }) => {
                                setExternalPayload({
                                    ...externalPayload,
                                    visualAcuity: {
                                        ...externalPayload.visualAcuity,
                                        optotype: +option.value
                                    }
                                });
                                onSubmit();
                            }}
                            value={optotypeValue}
                        />
                    </div>
                    <div className="w-100 mt-3">
                        <span className="text-label">Clasificación</span>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={externalPayload.visualAcuity?.clasification}
                            onChange={({ target }) => {
                                setExternalPayload({
                                    ...externalPayload,
                                    visualAcuity: {
                                        ...externalPayload.visualAcuity,
                                        clasification: target.value
                                    }
                                });
                                onSubmit();
                            }}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}
export function AVWithoutCorrection({ externalPayload, setExternalPayload, onSubmit, isDisabledForm, historySheet }: VisualAcuityParams) {
    const dispatch = useAppDispatch();

    const [generalSelects, setGeneralSelects] = useState<GeneralSelects>({
        optotypes: [],
        farVision: [],
        nearVision: [],
        idLetters: [],
        lensAdd: [],
        lensType: []
    });

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await dispatch(getOptometrySelects());
                setGeneralSelects({
                    optotypes: data?.optotypesResponse as OptotypeItem[],
                    farVision: data?.farVisionResponse as Vision[],
                    nearVision: data?.nearVisionResponse as Vision[],
                    idLetters: data?.idLettersResponse as IdentifiedLetter[],
                    lensAdd: data?.lensAddResponse as Vision[],
                    lensType: data?.lensTypeResponse as Vision[]
                });
            }
        }
        fetchData();
    }, [dispatch]);

    const onChangeCorrectionsSelect = (tableType: {
        vision: keyof Ao,
        type: "correction" | "noCorrection";
        eye: keyof Correction;
        value?: number | string;
        label: "id" | "idle";
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                [tableType.type]: {
                    ...externalPayload.visualAcuity?.[tableType.type],
                    [tableType.eye]: {
                        ...externalPayload.visualAcuity?.[tableType.type]?.[tableType.eye],
                        [tableType.vision]: {
                            ...externalPayload.visualAcuity?.[tableType.type]?.[tableType.eye]?.[tableType.vision],
                            [tableType.label]: tableType.value
                        }
                    }
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const farVisionFormatted = generalSelects.farVision?.map(item => ({ label: item.value, value: item.id }));
    const nearVisionFormatted = generalSelects.nearVision?.map(item => ({ label: item.value, value: item.id }));
    const idLettersFormatted = generalSelects.idLetters?.map(item => ({ label: item.symbol, value: item.id }));
    const renderSmallTable = (tableType: {
        label: string,
        vision: "far" | "near",
        type: "correction" | "noCorrection";
    }) => {
        const odValue = tableType.vision === "far"
            ? farVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.id)
            : nearVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.id);
        const odValueInput = tableType.vision === "far"
            ? externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.value
            : externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.value;

        const oiValue = tableType.vision === "far"
            ? farVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.id)
            : nearVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.id);
        const oiValueInput = tableType.vision === "far"
            ? externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.value
            : externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.value;

        const aoValue = tableType.vision === "far"
            ? farVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.id)
            : nearVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.id);
        const aoValueInput = tableType.vision === "far"
            ? externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.value
            : externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.value;

        const odIdleValue = idLettersFormatted.find(idf => idf.value === externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.idle);
        const odIdleValueInput = externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.value;
        const oiIdleValue = idLettersFormatted.find(idf => idf.value === externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.idle);
        const oiIdleValueInput = externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.value;
        const aoIdleValue = idLettersFormatted.find(idf => idf.value === externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.idle);
        const aoIdleValueInput = externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.value;


        return (
            <table className="flex-fill">
                <thead>
                    <tr>
                        <td className="p-2"></td>
                        <td colSpan={2} align="center" className="text-secondary">{tableType.label}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="p-2 text-secondary bg-od" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                        <td className="p-2 bg-od">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={odValueInput}
                                options={tableType.vision === "far" ? farVisionFormatted : nearVisionFormatted}
                                style={{ width: 60 }}
                                name="od"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "od",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "id"
                                })}
                                value={odValue}
                            />
                        </td>
                        <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={odIdleValueInput}
                                options={idLettersFormatted}
                                style={{ width: 60 }}
                                name="od"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "od",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "idle"
                                })}
                                value={odIdleValue}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 text-secondary bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                        <td className="p-2 bg-oi">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={oiValueInput}
                                options={tableType.vision === "far" ? farVisionFormatted : nearVisionFormatted}
                                style={{ width: 60 }}
                                name="oi"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "oi",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "id"
                                })}
                                value={oiValue}
                            />
                        </td>
                        <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={oiIdleValueInput}
                                options={idLettersFormatted}
                                style={{ width: 60 }}
                                name="oi"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "oi",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "idle"
                                })}
                                value={oiIdleValue}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 text-secondary">AO</td>
                        <td className="p-2">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={aoValueInput}
                                options={tableType.vision === "far" ? farVisionFormatted : nearVisionFormatted}
                                style={{ width: 60 }}
                                name="ao"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "ao",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "id"
                                })}
                                value={aoValue}
                            />
                        </td>
                        <td className="p-2">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={aoIdleValueInput}
                                options={idLettersFormatted}
                                style={{ width: 60 }}
                                name="ao"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "ao",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "idle"
                                })}
                                value={aoIdleValue}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const render = () => {

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">AV sin corrección</h5>
                <hr className="text-primary mt-2" />

                <div className="d-flex w-100 flex-wrap gx-5">
                    <div className="d-flex w-100 gap-2">
                        {renderSmallTable({
                            label: "VL",
                            vision: "far",
                            type: "noCorrection"
                        })}
                        {renderSmallTable({
                            label: "VP",
                            vision: "near",
                            type: "noCorrection"
                        })}
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}
export function AVWithCorrection({ externalPayload, setExternalPayload, onSubmit, isDisabledForm, historySheet }: VisualAcuityParams) {
    const dispatch = useAppDispatch();

    const [generalSelects, setGeneralSelects] = useState<GeneralSelects>({
        optotypes: [],
        farVision: [],
        nearVision: [],
        idLetters: [],
        lensAdd: [],
        lensType: []
    });

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await dispatch(getOptometrySelects());
                setGeneralSelects({
                    optotypes: data?.optotypesResponse as OptotypeItem[],
                    farVision: data?.farVisionResponse as Vision[],
                    nearVision: data?.nearVisionResponse as Vision[],
                    idLetters: data?.idLettersResponse as IdentifiedLetter[],
                    lensAdd: data?.lensAddResponse as Vision[],
                    lensType: data?.lensTypeResponse as Vision[]
                });
            }
        }
        fetchData();
    }, [dispatch]);

    const onChangeCorrectionsSelect = (tableType: {
        vision: keyof Ao,
        type: "correction" | "noCorrection";
        eye: keyof Correction;
        value?: number | string;
        label: "id" | "idle";
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                [tableType.type]: {
                    ...externalPayload.visualAcuity?.[tableType.type],
                    [tableType.eye]: {
                        ...externalPayload.visualAcuity?.[tableType.type]?.[tableType.eye],
                        [tableType.vision]: {
                            ...externalPayload.visualAcuity?.[tableType.type]?.[tableType.eye]?.[tableType.vision],
                            [tableType.label]: tableType.value
                        }
                    }
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const farVisionFormatted = generalSelects.farVision?.map(item => ({ label: item.value, value: item.id }));
    const nearVisionFormatted = generalSelects.nearVision?.map(item => ({ label: item.value, value: item.id }));
    const idLettersFormatted = generalSelects.idLetters?.map(item => ({ label: item.symbol, value: item.id }));

    const renderSmallTable = (tableType: {
        label: string,
        vision: "far" | "near",
        type: "correction" | "noCorrection";
    }) => {
        const odValue = tableType.vision === "far"
            ? farVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.id)
            : nearVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.id);
        const odValueInput = tableType.vision === "far"
            ? externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.value
            : externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.value;

        const oiValue = tableType.vision === "far"
            ? farVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.id)
            : nearVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.id);
        const oiValueInput = tableType.vision === "far"
            ? externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.value
            : externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.value;

        const aoValue = tableType.vision === "far"
            ? farVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.id)
            : nearVisionFormatted.find(fvf => fvf.value === externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.id);
        const aoValueInput = tableType.vision === "far"
            ? externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.value
            : externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.value;

        const odIdleValue = idLettersFormatted.find(idf => idf.value === externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.idle);
        const odIdleValueInput = externalPayload.visualAcuity?.[tableType.type]?.od?.[tableType.vision]?.value;
        const oiIdleValue = idLettersFormatted.find(idf => idf.value === externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.idle);
        const oiIdleValueInput = externalPayload.visualAcuity?.[tableType.type]?.oi?.[tableType.vision]?.value;
        const aoIdleValue = idLettersFormatted.find(idf => idf.value === externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.idle);
        const aoIdleValueInput = externalPayload.visualAcuity?.[tableType.type]?.ao?.[tableType.vision]?.value;


        return (
            <table className="flex-fill">
                <thead>
                    <tr>
                        <td className="p-2"></td>
                        <td colSpan={2} align="center" className="text-secondary">{tableType.label}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="p-2 text-secondary bg-od" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                        <td className="p-2 bg-od">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={odValueInput}
                                options={tableType.vision === "far" ? farVisionFormatted : nearVisionFormatted}
                                style={{ width: 60 }}
                                name="od"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "od",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "id"
                                })}
                                value={odValue}
                            />
                        </td>
                        <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={odIdleValueInput}
                                options={idLettersFormatted}
                                style={{ width: 60 }}
                                name="od"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "od",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "idle"
                                })}
                                value={odIdleValue}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 text-secondary bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                        <td className="p-2 bg-oi">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={oiValueInput}
                                options={tableType.vision === "far" ? farVisionFormatted : nearVisionFormatted}
                                style={{ width: 60 }}
                                name="oi"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "oi",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "id"
                                })}
                                value={oiValue}
                            />
                        </td>
                        <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={oiIdleValueInput}
                                options={idLettersFormatted}
                                style={{ width: 60 }}
                                name="oi"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "oi",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "idle"
                                })}
                                value={oiIdleValue}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 text-secondary">AO</td>
                        <td className="p-2">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={aoValueInput}
                                options={tableType.vision === "far" ? farVisionFormatted : nearVisionFormatted}
                                style={{ width: 60 }}
                                name="ao"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "ao",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "id"
                                })}
                                value={aoValue}
                            />
                        </td>
                        <td className="p-2">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={aoIdleValueInput}
                                options={idLettersFormatted}
                                style={{ width: 60 }}
                                name="ao"
                                onChange={({ option }) => onChangeCorrectionsSelect({
                                    eye: "ao",
                                    type: tableType.type,
                                    value: option.value,
                                    vision: tableType.vision,
                                    label: "idle"
                                })}
                                value={aoIdleValue}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };


    const render = () => {
        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">AV con corrección</h5>
                <hr className="text-primary mt-2" />
                <div className="d-flex w-100 flex-wrap gx-5">
                    <div className="mx-4"></div>
                    {/* <div className="flex-fill mt-4"> */}
                    <div className="d-flex w-100 gap-2">
                        {renderSmallTable({
                            label: "VL",
                            vision: "far",
                            type: "correction"
                        })}
                        {renderSmallTable({
                            label: "VP",
                            vision: "near",
                            type: "correction"
                        })}
                    </div>
                    {/* </div> */}
                    <div className="mx-4"></div>
                </div>
            </SectionCard>
        );
    };

    return render();
}
export function AVPH({ externalPayload, setExternalPayload, onSubmit, isDisabledForm, historySheet }: VisualAcuityParams) {
    const dispatch = useAppDispatch();

    const [generalSelects, setGeneralSelects] = useState<GeneralSelects>({
        optotypes: [],
        farVision: [],
        nearVision: [],
        idLetters: [],
        lensAdd: [],
        lensType: []
    });

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await dispatch(getOptometrySelects());
                setGeneralSelects({
                    optotypes: data?.optotypesResponse as OptotypeItem[],
                    farVision: data?.farVisionResponse as Vision[],
                    nearVision: data?.nearVisionResponse as Vision[],
                    idLetters: data?.idLettersResponse as IdentifiedLetter[],
                    lensAdd: data?.lensAddResponse as Vision[],
                    lensType: data?.lensTypeResponse as Vision[]
                });
            }
        }
        fetchData();
    }, [dispatch, historySheet]);

    const onChangePH = (tableType: {
        eye: "oi" | "od";
        value?: number | string;
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                ph: {
                    ...externalPayload.visualAcuity?.ph,
                    [tableType.eye]: tableType.value
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const farVisionFormatted = generalSelects.farVision?.map(item => ({ label: item.value, value: item.id }));

    const render = () => {
        const phOdValue = farVisionFormatted.find(vs => vs.value === externalPayload.visualAcuity?.ph?.od);
        const phOdValueInput = externalPayload.visualAcuity?.ph?.od;
        const phOiValue = farVisionFormatted.find(vs => vs.value === externalPayload.visualAcuity?.ph?.oi);
        const phOiValueInput = externalPayload.visualAcuity?.ph?.oi;

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">AV PH </h5>
                <hr className="text-primary mt-2" />
                <div className="d-flex w-100 flex-wrap gx-5 pt-2">
                    <table className="flex-fill mt-3">
                        <tbody>
                            <tr>
                                <td className="text-secondary p-2 bg-od" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                                <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }} colSpan={2}>
                                    <Select
                                        disabled={isDisabledForm}
                                        changeToInput={historySheet}
                                        inputValue={phOdValueInput}
                                        options={farVisionFormatted}
                                        style={{ width: 80 }}
                                        onChange={({ option }) => onChangePH({ eye: "od", value: option.value })}
                                        value={phOdValue}
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td className="text-secondary p-2 bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                                <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }} colSpan={2}>
                                    <Select
                                        disabled={isDisabledForm}
                                        changeToInput={historySheet}
                                        inputValue={phOiValueInput}
                                        options={farVisionFormatted}
                                        style={{ width: 80 }}
                                        onChange={({ option }) => onChangePH({ eye: "oi", value: option.value })}
                                        value={phOiValue}
                                    />
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </SectionCard>
        );
    };

    return render();
}
export function AVGlassesOnUse({ externalPayload, setExternalPayload, onSubmit, isDisabledForm, historySheet }: VisualAcuityParams) {
    const dispatch = useAppDispatch();

    const [generalSelects, setGeneralSelects] = useState<GeneralSelects>({
        optotypes: [],
        farVision: [],
        nearVision: [],
        idLetters: [],
        lensAdd: [],
        lensType: []
    });

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await dispatch(getOptometrySelects());
                setGeneralSelects({
                    optotypes: data?.optotypesResponse as OptotypeItem[],
                    farVision: data?.farVisionResponse as Vision[],
                    nearVision: data?.nearVisionResponse as Vision[],
                    idLetters: data?.idLettersResponse as IdentifiedLetter[],
                    lensAdd: data?.lensAddResponse as Vision[],
                    lensType: data?.lensTypeResponse as Vision[]
                });
            }
        }
        fetchData();
    }, [dispatch, historySheet]);

    const onChangeLentsInUse = (tableType: {
        vision: keyof LentsVision,
        eye: "oi" | "od";
        value?: number | string;
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                lentsInUse: {
                    ...externalPayload.visualAcuity?.lentsInUse,
                    [tableType.eye]: {
                        ...externalPayload.visualAcuity?.lentsInUse?.[tableType.eye],
                        [tableType.vision]: tableType.value
                    }
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const onChangeLentsInUseInputs = (inputType: {
        lentsType: "lentsOd" | "lentsOi";
        lentsItem: "sphere" | "cylinder" | "axis" | "brand";
        value: string;
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                lentsInUse: {
                    ...externalPayload.visualAcuity?.lentsInUse,
                    [inputType.lentsType]: {
                        ...externalPayload.visualAcuity?.lentsInUse?.[inputType.lentsType],
                        [inputType.lentsItem]: inputType.value
                    }
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const onChangeLentsType = (lentsType: {
        lentsType: "lentsOd" | "lentsOi";
        valueType: "add" | "len";
        value: number | string;
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                lentsInUse: {
                    ...externalPayload.visualAcuity?.lentsInUse,
                    [lentsType.lentsType]: {
                        ...externalPayload.visualAcuity?.lentsInUse?.[lentsType.lentsType],
                        [lentsType.valueType]: lentsType.value
                    }
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const farVisionFormatted = generalSelects.farVision?.map(item => ({ label: item.value, value: item.id }));
    const nearVisionFormatted = generalSelects.nearVision?.map(item => ({ label: item.value, value: item.id }));
    const lensAddFormatted = generalSelects.lensAdd?.map(item => ({ label: item.value, value: item.id }));
    const lensTypeFormatted = generalSelects.lensType?.map(item => ({ label: item.value, value: item.id }));


    const renderSmallTable2 = () => {
        const odAddValue = lensAddFormatted.find(laf => laf.value === externalPayload.visualAcuity?.lentsInUse?.lentsOd?.add);
        const odAddValueInput = externalPayload.visualAcuity?.lentsInUse?.lentsOd?.add;
        const odLensTypeValue = lensTypeFormatted.find(laf => laf.value === externalPayload.visualAcuity?.lentsInUse?.lentsOd?.len);
        const odLensTypeValueInput = externalPayload.visualAcuity?.lentsInUse?.lentsOd?.len;

        const oIAddValue = lensAddFormatted.find(laf => laf.value === externalPayload.visualAcuity?.lentsInUse?.lentsOi?.add);
        const oIAddValueInput = externalPayload.visualAcuity?.lentsInUse?.lentsOi?.add;
        const oiLensTypeValue = lensTypeFormatted.find(laf => laf.value === externalPayload.visualAcuity?.lentsInUse?.lentsOi?.len);
        const oiLensTypeValueInput = externalPayload.visualAcuity?.lentsInUse?.lentsOi?.len;

        return (
            <table className="flex-fill align-self-end">
                <thead>
                    <tr>
                        <td className="p-2"></td>
                        <td align="center" className="text-secondary">Esfera</td>
                        <td align="center" className="text-secondary">Cilindro</td>
                        <td align="center" className="text-secondary">Eje°</td>
                        <td align="center" className="text-secondary">ADD</td>
                        <td align="center" className="text-secondary">Tipo de lente</td>
                        <td align="center" className="text-secondary">Marca</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="p-2 nowrap text-secondary bg-od" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                        <td className="p-2 bg-od">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeLentsInUseInputs({
                                            lentsType: "lentsOd",
                                            lentsItem: "sphere",
                                            value: event.target.value
                                        });
                                    }
                                }}
                                value={externalPayload.visualAcuity?.lentsInUse?.lentsOd?.sphere}
                            />
                        </td>
                        <td className="p-2 bg-od">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeLentsInUseInputs({
                                            lentsType: "lentsOd",
                                            lentsItem: "cylinder",
                                            value: event.target.value
                                        });
                                    }
                                }}
                                value={externalPayload.visualAcuity?.lentsInUse?.lentsOd?.cylinder}
                            />
                        </td>
                        <td className="p-2 bg-od">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                onChange={({ target }) => onChangeLentsInUseInputs({
                                    lentsType: "lentsOd",
                                    lentsItem: "axis",
                                    value: target.value
                                })}
                                value={externalPayload.visualAcuity?.lentsInUse?.lentsOd?.axis}
                                onKeyDown={(event) => event.key === "." && event.preventDefault()}
                            />
                        </td>
                        <td className="p-2 bg-od">

                            <Select
                                style={{ width: 70 }} 
                                id={"add+70"}
                                changeToInput={historySheet}
                                inputValue={odAddValueInput}
                                disabled={isDisabledForm}
                                options={lensAddFormatted}
                                onChange={({ option }) => onChangeLentsType({
                                    lentsType: "lentsOd",
                                    valueType: "add",
                                    value: option.value
                                })}
                                value={odAddValue}
                            />
                        </td>
                        <td className="p-2 bg-od">
                            <Select 
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={odLensTypeValueInput}
                                options={lensTypeFormatted}
                                style={{ width: 70 }}
                                onChange={({ option }) => onChangeLentsType({
                                    lentsType: "lentsOd",
                                    valueType: "len",
                                    value: option.value
                                })}
                                value={odLensTypeValue}
                            />
                        </td>
                        <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                onChange={({ target }) => onChangeLentsInUseInputs({
                                    lentsType: "lentsOd",
                                    lentsItem: "brand",
                                    value: target.value
                                })}
                                value={externalPayload.visualAcuity?.lentsInUse?.lentsOd?.brand}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2 nowrap text-secondary bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }} width={30}>OI</td>
                        <td className="p-2 bg-oi">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeLentsInUseInputs({
                                            lentsType: "lentsOi",
                                            lentsItem: "sphere",
                                            value: event.target.value
                                        });
                                    }
                                }}
                                value={externalPayload.visualAcuity?.lentsInUse?.lentsOi?.sphere}
                            />
                        </td>
                        <td className="p-2 bg-oi">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeLentsInUseInputs({
                                            lentsType: "lentsOi",
                                            lentsItem: "cylinder",
                                            value: event.target.value
                                        });
                                    }
                                }}
                                value={externalPayload.visualAcuity?.lentsInUse?.lentsOi?.cylinder}
                            />
                        </td>
                        <td className="p-2 bg-oi">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                onChange={({ target }) => onChangeLentsInUseInputs({
                                    lentsType: "lentsOi",
                                    lentsItem: "axis",
                                    value: target.value
                                })}
                                value={externalPayload.visualAcuity?.lentsInUse?.lentsOi?.axis}
                                onKeyDown={(event) => event.key === "." && event.preventDefault()}
                            />
                        </td>
                        <td className="p-2 bg-oi">
                            <Select
                                style={{ width: 70 }}
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={oIAddValueInput}
                                options={lensAddFormatted}
                                onChange={({ option }) => onChangeLentsType({
                                    lentsType: "lentsOi",
                                    valueType: "add",
                                    value: option.value
                                })}
                                value={oIAddValue}
                            />
                        </td>
                        <td className="p-2 bg-oi">
                            <Select
                                style={{ width: 70 }} 
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={oiLensTypeValueInput}
                                options={lensTypeFormatted}
                                onChange={({ option }) => onChangeLentsType({
                                    lentsType: "lentsOi",
                                    valueType: "len",
                                    value: option.value
                                })}
                                value={oiLensTypeValue}
                            />
                        </td>
                        <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                onChange={({ target }) => onChangeLentsInUseInputs({
                                    lentsType: "lentsOi",
                                    lentsItem: "brand",
                                    value: target.value
                                })}
                                value={externalPayload.visualAcuity?.lentsInUse?.lentsOi?.brand}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const renderLensInUse = () => {
        const odFarValue = farVisionFormatted.find(fv => fv.value === externalPayload.visualAcuity?.lentsInUse?.od?.far);
        const odFarValueInput = externalPayload.visualAcuity?.lentsInUse?.od?.far;
        const odNearValue = nearVisionFormatted.find(fv => fv.value === externalPayload.visualAcuity?.lentsInUse?.od?.near);
        const odNearValueInput = externalPayload.visualAcuity?.lentsInUse?.od?.near;

        const oiFarValue = farVisionFormatted.find(fv => fv.value === externalPayload.visualAcuity?.lentsInUse?.oi?.far);
        const oiFarValueInput = externalPayload.visualAcuity?.lentsInUse?.oi?.far;
        const oiNearValue = nearVisionFormatted.find(fv => fv.value === externalPayload.visualAcuity?.lentsInUse?.oi?.near);
        const oiNearValueInput = externalPayload.visualAcuity?.lentsInUse?.oi?.near;

        return (
            <div className="col-xl-4">
                <div className="d-flex w-100">
                    <table className="flex-fill">
                        <thead>
                            <tr>
                                <td className="p-2"></td>
                                <td align="center" className="text-secondary">VL</td>
                                <td align="center" className="text-secondary">VP</td>
                                <td align="center" className="text-secondary"></td>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-2 text-secondary bg-od" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                                <td className="p-2 bg-od">
                                    <Select
                                        disabled={isDisabledForm}
                                        changeToInput={historySheet}
                                        inputValue={odFarValueInput}
                                        options={farVisionFormatted}
                                        style={{ width: 60 }}
                                        onChange={({ option }) => onChangeLentsInUse({
                                            eye: "od",
                                            vision: "far",
                                            value: option.value
                                        })}
                                        value={odFarValue}
                                    />
                                </td>
                                <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                                    <Select
                                        disabled={isDisabledForm}
                                        changeToInput={historySheet}
                                        inputValue={odNearValueInput}
                                        options={nearVisionFormatted}
                                        style={{ width: 60 }}
                                        onChange={({ option }) => onChangeLentsInUse({
                                            eye: "od",
                                            vision: "near",
                                            value: option.value
                                        })}
                                        value={odNearValue}
                                    />
                                </td>
                                <td className="p-2" rowSpan={2}>
                                    <textarea
                                        disabled={isDisabledForm}
                                        className="form-control no-resize"
                                        style={{ height: 70, width: 280 }}
                                        placeholder="Observaciones"
                                        onChange={({ target }) => {
                                            setExternalPayload({
                                                ...externalPayload,
                                                visualAcuity: {
                                                    ...externalPayload.visualAcuity,
                                                    lentsInUse: {
                                                        ...externalPayload.visualAcuity?.lentsInUse,
                                                        observations: target.value
                                                    }
                                                }
                                            });
                                            onSubmit();
                                        }}
                                        value={externalPayload.visualAcuity?.lentsInUse?.observations}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2 text-secondary bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                                <td className="p-2 bg-oi">
                                    <Select
                                        disabled={isDisabledForm}
                                        changeToInput={historySheet}
                                        inputValue={oiFarValueInput}
                                        options={farVisionFormatted}
                                        style={{ width: 60 }}
                                        onChange={({ option }) => onChangeLentsInUse({
                                            eye: "oi",
                                            vision: "far",
                                            value: option.value
                                        })}
                                        value={oiFarValue}
                                    />
                                </td>
                                <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                                    <Select
                                        disabled={isDisabledForm}
                                        changeToInput={historySheet}
                                        inputValue={oiNearValueInput}
                                        options={nearVisionFormatted}
                                        style={{ width: 60 }}
                                        onChange={({ option }) => onChangeLentsInUse({
                                            eye: "oi",
                                            vision: "near",
                                            value: option.value
                                        })}
                                        value={oiNearValue}
                                    />
                                </td>
                                <td className="p-2">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <SectionCard className="">
                <h5 className="text-secondary fw-bold">AV Lentes en uso</h5>
                <hr className="text-primary mt-2" />
                <div className="row mt-2">
                    <div className="col-6">
                        <div className="w-100 h-100">
                            <h5 className="text-secondary fw-bold text-center pb-2">Lentes</h5>

                            {renderSmallTable2()}
                        </div>
                    </div>
                    <div className="col-6" style={{ borderLeft: "0.5px solid rgb(0, 180, 204, 0.25)" }}>
                        <h5 className="text-secondary fw-bold text-center pb-2">AV</h5>

                        {renderLensInUse()}
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}