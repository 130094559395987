import { BodyD, DownloadHistory, Preconsulting } from "@/models/generalHistory/generalHistory";
import { BackgroundDetail as IBackgroundDetail } from "@/models/historyDetails/background";
import { OrderDetail } from "@/models/historyDetails/orders";
import { UnexpectedSituationDetail as IUnexpectedSituationDetail } from "@/models/historyDetails/unexpectedSituation";
import { CommonSelect, Squints } from "@/models/sheets/orthoptic";
import { formatHistoryDetails, formatteDocument } from "@/utils";
import { forwardRef } from "react";
import AdaptationDetails from "../PatientAttention/Consultation/History/components/Details/AdaptationDetails";
import AnalysisAndPlanDetails from "../PatientAttention/Consultation/History/components/Details/AnalysisAndPlanDetails";
import AttentionOriginDetails from "../PatientAttention/Consultation/History/components/Details/AttentionOriginDetails";
import DiagnosticImpressionNewDetails from "../PatientAttention/Consultation/History/components/Details/DiagnosticImpressionNewDetail";
import LowVisionDetails from "../PatientAttention/Consultation/History/components/Details/LowVisionDetails";
import OphthalmologyDetails from "../PatientAttention/Consultation/History/components/Details/OphthalmologyDetails";
import OptometryDetails from "../PatientAttention/Consultation/History/components/Details/OptometryDetails";
import OrthopticDetails from "../PatientAttention/Consultation/History/components/Details/OrthopticDetails";
import PreconsultDetails from "../PatientAttention/Consultation/History/components/Details/PreconsultDetails";
import PreoperativeDetails from "../PatientAttention/Consultation/History/components/Details/PreoperativeDetails";
import PressionAndPachimetryDetails from "../PatientAttention/Consultation/History/components/Details/PressionAndPachimetryDetails";
import BackgroundDetail from "../PatientAttention/Consultation/History/components/Details/SurgeryDetails/BackgroundDetail/BackgroundDetail";
import OrdersDetail from "../PatientAttention/Consultation/History/components/Details/SurgeryDetails/OrdersDetail/OrdersDetail";
import UnexpectedSituationDetail from "../PatientAttention/Consultation/History/components/Details/SurgeryDetails/UnexpectedSituationDetail/UnexpectedSituationDetail";

interface ISurgeryDownloadFileProps {
    data: DownloadHistory[];
    currentTab?: string;
    fromHistory?: boolean;
    dataVersionList?: CommonSelect[]
    squintsData?: Squints[];
    degreesData?: CommonSelect[]

}





const DownloadHistoryFile = forwardRef<HTMLTableElement, ISurgeryDownloadFileProps>((props, ref,) => {

    const currentTab = props?.currentTab;
    const downloadData = props?.data[0]?.detailForDownload;

  

    const renderEvent = (event: BodyD, sheet: string) => {
        return (
            <>
                <div className="d-flex justify-content-between text-secondary w-100 bg-primary-lighter p-3" style={{ marginTop: -5 }}>
                    <h5 className="fw-bold m-0">
                        {event?.datetime_it_was_made}
                    </h5>
                    <h5 className="fw-bold m-0">
                        Evento N. {event?.event_number} - {event?.schedule_name}
                    </h5>
                    <h5 className="fw-bold m-0">
                        Edad: {event?.patient_age} años
                    </h5>
                </div>
                <div><h4 className="fw-bold m-0 text-secondary mt-4">
                    {sheet}
                </h4></div>
            </>
        );
    };

    const unexpectedSituationSheet = (isGeneral?: boolean, data?: IUnexpectedSituationDetail[]) => {
        const unexpectedSituation = props.data.some(x => x.unexpectedSituation) ? props.data.filter(x => x.unexpectedSituation).map(data => ({
            sheet: data.unexpectedSituation,
            event: data.detailForDownload?.body
        })) : [];
        return isGeneral
            ?
            <>
                {unexpectedSituation.length > 0 &&
                    unexpectedSituation.map((data, ind) => {
                        
                        return (

                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {(data.event && currentTab === "generals") && renderEvent(data.event, "")}
                                {data.sheet && <UnexpectedSituationDetail data={data.sheet} />}
                            </div>
                        );
                    })
                }
            </>
            : data && <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                <UnexpectedSituationDetail data={data} />
            </div>;


    };
    const ordersSheet = (isGeneral?: boolean, data?: OrderDetail) => {
        const orders = props.data.some(x => x.orders) ? props.data.filter(x => x.orders).map(data => ({
            sheet: { ...data.orders, },
            event: data.detailForDownload?.body
        })) : [];
        return isGeneral
            ?
            <>
                {orders.length > 0 &&
                    orders.map((data, ind) => { 
                        return (

                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {(data.event && currentTab === "generals") && renderEvent(data.event, "")}
                                {data.sheet && <OrdersDetail data={data.sheet} download />}
                            </div>
                        );
                    })
                }
            </>
            : data && <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                <OrdersDetail data={data} download />
            </div>;


    };
    const backgroundSheet = (isGeneral?: boolean, data?: IBackgroundDetail) => {
        const background = props.data.some(x => x.background) ? props.data.filter(x => x.background).map(data => ({
            sheet: { ...data.background, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body
        })) : [];
        return isGeneral
            ?
            <>
                {background.length > 0 &&
                    background.map((data, ind) => { 
                        return (

                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {(data.event && currentTab === "generals") && renderEvent(data.event, "")}
                                {data.sheet && <BackgroundDetail data={data.sheet} download />}
                            </div>
                        );
                    })
                }
            </>
            : data && <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                <BackgroundDetail data={data} download />
            </div>;


    };
    const preconsultingSheet = (isGeneral?: boolean, data?: Preconsulting) => {
        const preconsulting = props.data.some(x => x.preconsulting) ? props.data.filter(x => x.preconsulting).map(data => ({
            sheet: { ...data.preconsulting, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body
        })) : [];
        return isGeneral
            ?
            <>
                {preconsulting.length > 0 &&
                    preconsulting.map((data) => {
                        return ( 
                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {(data.event && currentTab === "generals") && renderEvent(data.event, "Preconsulta")}
                                {data.sheet && <PreconsultDetails preconsultingDetail={data.sheet} />}
                            </div>
                        );
                    })
                }
            </>
            : data && <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                <div><h4 className="fw-bold m-0 text-secondary mt-4">
                    Preconsulta
                </h4></div>
                <PreconsultDetails preconsultingDetail={data} />
            </div>;


    };

 

  


    const sheetsBody = () => {
        const ophthalmology = props.data.some(x => x.ophthalmology) ? props.data.filter(x => x.ophthalmology).map(data => ({
            sheet: { ...data.ophthalmology, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];
        const optometry = props.data.some(x => x.optometry) ? props.data.filter(x => x.optometry).map(data => ({
            sheet: { ...data.optometry, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];
        const adaptation = props.data.some(x => x.adaptation) ? props.data.filter(x => x.adaptation).map(data => ({
            sheet: { ...data.adaptation, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];
        const lowVision = props.data.some(x => x.lowVision) ? props.data.filter(x => x.lowVision).map(data => ({
            sheet: { ...data.lowVision, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];

        const preoperative = props.data.some(x => x.preoperative) ? props.data.filter(x => x.preoperative).map(data => ({
            sheet: { ...data.preoperative, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];
        const analysisPlan = props.data.some(x => x.analysisPlan) ? props.data.filter(x => x.analysisPlan).map(data => ({
            sheet: data.analysisPlan,
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];
        const diagnosticImpression = props.data.some(x => x.diagnosticImpression) ? props.data.filter(x => x.diagnosticImpression).map(data => ({
            sheet: data.diagnosticImpression,
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];
        const attentionOrigin = props.data.some(x => x.attentionOrigin) ? props.data.filter(x => x.attentionOrigin).map(data => ({
            sheet: data.attentionOrigin,
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];
        const pachymetry = props.data.some(x => x.pachymetry) ? props.data.filter(x => x.pachymetry).map(data => ({
            sheet: data.pachymetry,
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];
        const pio = props.data.some(x => x.pio) ? props.data.filter(x => x.pio).map(data => ({
            sheet: data.pio,
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];
        const orthoptic = props.data.some(x => x.orthoptic) ? props.data.filter(x => x.orthoptic).map(data => ({
            sheet: { ...data.orthoptic, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body, preconsultingSheet: data.preconsulting, orders: data.orders, background: data.background, unexpectedSituation: data.unexpectedSituation
        })) : [];

        return (
            <div ref={ref} >

                {ophthalmology.length > 0 &&
                    ophthalmology.map((data) => {
                       
                        return (
                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {data.event && renderEvent(data.event, "Oftalmología")}
                                {data.sheet && <OphthalmologyDetails ophthalmologyDetails={data.sheet} />}
                                {data.preconsultingSheet && preconsultingSheet(false, data.preconsultingSheet)}
                                {data.background && backgroundSheet(false, data.background)}
                                {data.orders && ordersSheet(false, data.orders)}
                                {data.unexpectedSituation && unexpectedSituationSheet(false, data.unexpectedSituation)}
                            </div>
                        );
                    })

                }
                {optometry.length > 0 &&
                    optometry.map((data, ind) => {
                       
                        return (

                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {data.event && renderEvent(data.event, "Optometría")}
                                {data.sheet && <OptometryDetails optometryDetails={data.sheet} />}
                                {data.preconsultingSheet && preconsultingSheet(false, data.preconsultingSheet)}
                                {data.background && backgroundSheet(false, data.background)}
                                {data.orders && ordersSheet(false, data.orders)}
                                {data.unexpectedSituation && unexpectedSituationSheet(false, data.unexpectedSituation)}




                            </div>
                        );
                    })

                }
                {adaptation.length > 0 &&
                    adaptation.map((data, ind) => {

                        return ( 
                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {data.event && renderEvent(data.event, "Adaptación de LC")}
                                {data.sheet && <AdaptationDetails adaptationDetail={data.sheet} />}
                                {data.preconsultingSheet && preconsultingSheet(false, data.preconsultingSheet)}
                                {data.background && backgroundSheet(false, data.background)}
                                {data.orders && ordersSheet(false, data.orders)}
                                {data.unexpectedSituation && unexpectedSituationSheet(false, data.unexpectedSituation)}  </div>
                        );
                    })

                }
                {lowVision.length > 0 &&
                    lowVision.map((data, ind) => {
                        
                        return (

                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {data.event && renderEvent(data.event, "Baja visión")}
                                {data.sheet && <LowVisionDetails lowVisionDetails={data.sheet} />}
                                {data.preconsultingSheet && preconsultingSheet(false, data.preconsultingSheet)}
                                {data.background && backgroundSheet(false, data.background)}
                                {data.orders && ordersSheet(false, data.orders)}
                                {data.unexpectedSituation && unexpectedSituationSheet(false, data.unexpectedSituation)} 
                            </div>
                        );
                    })

                }
                {orthoptic.length > 0 &&
                    orthoptic.map((data) => {
                        
                        return ( 
                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {data.event && renderEvent(data.event, "Ortóptica")}
                                {data.sheet && <OrthopticDetails orthopticDetails={data.sheet} dataVersionList={props.dataVersionList}
                                    degreesData={props.degreesData}
                                    squintsData={props.squintsData}
                                    forDownload
                                />}
                                {data.preconsultingSheet && preconsultingSheet(false, data.preconsultingSheet)}
                                {data.background && backgroundSheet(false, data.background)}
                                {data.orders && ordersSheet(false, data.orders)}
                                {data.unexpectedSituation && unexpectedSituationSheet(false, data.unexpectedSituation)}




                            </div>
                        );
                    })
                }
                {preoperative.length > 0 &&
                    preoperative.map((data, ind) => {
                        
                        return (
                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "always" }}>
                                {data.event && renderEvent(data.event, "Valoración preoperatoria")}
                                {data.sheet && <PreoperativeDetails preoperativeDetails={data.sheet} />}
                                {data.preconsultingSheet && preconsultingSheet(false, data.preconsultingSheet)}
                                {data.background && backgroundSheet(false, data.background)}
                                {data.orders && ordersSheet(false, data.orders)}
                                {data.unexpectedSituation && unexpectedSituationSheet(false, data.unexpectedSituation)}
                            </div>
                        );
                    })
                }

                {currentTab === "generals" && preconsultingSheet(true)}
                {currentTab === "generals" && backgroundSheet(true)}
                {currentTab === "generals" && unexpectedSituationSheet(true)}
                {currentTab === "generals" && ordersSheet(true)}

                {analysisPlan.length > 0 &&
                    analysisPlan.map((data, ind) => { 
                        return (
                            <div >
                                {data.event && renderEvent(data.event, "Análisis y plan")}
                                {data.sheet && <AnalysisAndPlanDetails data={data.sheet} />}

                            </div>
                        );
                    })
                }
                {(currentTab === "generals" &&  diagnosticImpression.length > 0) &&
                    diagnosticImpression.map((data, ind) => { 
                        const cie10ExtDiagnostic = formatHistoryDetails(data.sheet);
                        return (
                            <div className="py-4">
                                {data.event && renderEvent(data.event, "Impresión diagnóstica")}
                                {data.sheet && <DiagnosticImpressionNewDetails data={cie10ExtDiagnostic} />}

                            </div>
                        );
                    })
                }
                {attentionOrigin.length > 0 &&
                    attentionOrigin.map((data, ind) => {
                        return (
                            <div className="py-4">
                                {data.event && renderEvent(data.event, "Origen de la atención")}
                                {data.sheet && <AttentionOriginDetails data={data.sheet} />}

                            </div>
                        );
                    })
                }
                {pachymetry.length > 0 &&
                    pachymetry.map((data, ind) => {
                        return (
                            <div className="py-4">
                                {data.event && renderEvent(data.event, "")}
                                {data.sheet && <PressionAndPachimetryDetails dataPa={data.sheet} />}

                            </div>
                        );
                    })
                }
                {pio.length > 0 &&
                    pio.map((data, ind) => { 
                        return (
                            <div className="py-4">
                                {data.event && renderEvent(data.event, "")}
                                {data.sheet && <PressionAndPachimetryDetails dataPe={data.sheet} />}

                            </div>
                        );
                    })
                }




            </div>
        );
    };

    const renderDownload = () => {
        return (
            downloadData ?
                <table border={0} ref={ref} className="w-100">
                    <thead>
                        <tr>
                            <td>
                                <div className="print-header">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img src={downloadData?.header?.logo} alt="cofca_logo" width={150} height={50} style={{ objectFit: "contain" }} />
                                        <h2 className="text-secondary fw-bold">
                                            {downloadData?.header?.name_of_the_institution}
                                        </h2>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <div className="row p-3 py-2 align-items-center bg-light text-muted mt-3">
                                        <div className="col-6">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h5 className="fw-bold">Paciente:</h5>
                                                    <h5 className="fw-bold">Documento:</h5>
                                                </div>
                                                <div className="col-8">
                                                    <h5>{downloadData?.header?.patient?.name}</h5>
                                                    <h5>{formatteDocument({value: downloadData?.header?.patient?.doc}).format}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <h5 className="fw-bold nowrap">Fecha de nacimiento:</h5>
                                                    <h5 className="fw-bold">Género:</h5>
                                                </div>
                                                <div className="col-6">
                                                    <h5>{downloadData?.header?.patient?.birthdate}</h5>
                                                    <h5>{downloadData?.header?.patient?.gender}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 10 }}></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="printable">


                                    {sheetsBody()}

                                    <div className="printable">
                                        <img width={200} src={downloadData?.footer?.doctor?.signature} alt="" />
                                        <h5 className="fw-bold m-0">{downloadData?.footer?.doctor?.name}</h5>
                                        <h5 className="fst-italic m-0">{downloadData?.footer?.doctor?.position}</h5>
                                        <h5 className="fst-italic m-0">R.M {downloadData?.footer?.doctor?.medical_record}</h5>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>
                                <div style={{ height: 25 }}></div>
                                <div className="align-bottom text-end text-muted position-fixed bottom-0 w-100" style={{ marginBottom: 0 }}>
                                    <h6 className="d-inline text-end mx-2">
                                        <span className="fw-bold">Teléfono: </span>
                                        {downloadData?.footer?.appointment_phone}
                                    </h6>
                                    <h6 className="d-inline text-end mx-2">
                                        <span className="fw-bold">Email: </span>
                                        {downloadData?.footer?.appointment_email}
                                    </h6>
                                    <h6 className="d-inline text-end mx-2">
                                        <span className="fw-bold">Dirección: </span>
                                        {downloadData?.footer?.address}
                                    </h6>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                :
                <table border={0} ref={ref} className="w-100">
                </table>
        );
    };


    return renderDownload();






});




export default DownloadHistoryFile;