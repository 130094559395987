import { IconChevronRight, IconSearch } from "@tabler/icons-react";
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
//
import { yupResolver } from "@hookform/resolvers/yup";
//
import { useAppDispatch, useAppSelector } from "@/hooks";
//
import { convertFileBase64, correctionValue, fireErrorAlert, getNameOfImageUrl } from "@/utils";
//
import {
    getScreening,
    getVisionShoot,
    updateScreening,
} from "../preConsultation.actions";
//
import { File, Modal, SectionCard, Select } from "@/components";
import { Tooltip as ReactTooltip } from "react-tooltip";
//
import {
    IVisionShoot,
    TCategoryEye,
    TData,
    TFormDataScreening,
    TOnChangeFileData,
    TOnClearFileData,
    TSideOfEye,
    schemaScreening,
} from "@/models";
import { useDebouncedCallback } from "use-debounce";
import ConfrontationComponentEye from "./ConfrontationComponentEye";

interface ScreeningProps {
    isDisabledForm?: boolean;
    historySheet?: boolean;
    data?: TData;

}
export const Screening = memo(({ isDisabledForm, historySheet, data }: ScreeningProps) => {

    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.auth.user_data.id) as number;
    const eaccount = useAppSelector(state => state.workspace.id);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const { app_id } = useAppSelector(state => state.patientAttention.appointmentSelected);
    const [dataScreening, setDataScreening] = useState<TData>({});
    const [triggerUpdate, setTriggerUpdate] = useState(0);

    const {
        register: registerPreConsultation,
    } = useForm<TFormDataScreening>({ resolver: yupResolver(schemaScreening) });

    const onChangeFileData = ({ category, eye, file }: TOnChangeFileData) => {
        setDataScreening(state => ({
            ...state,
            [category]: {
                ...state[category],
                [eye]: {
                    base64: file.data.base64,
                    fileName: file.data.filename,
                    src: file.data.src
                }
            },
        }));
    };

    const onClearFileData = ({ category, eye }: TOnClearFileData) => {
        setDataScreening(state => ({
            ...state,
            [category]: {
                ...state[category],
                [eye]: {
                    base64: "",
                    fileName: "",
                    src: "",
                    clear: true
                }
            },
        }));
    };

    const onActiveImagenEye = (category: TCategoryEye) => {
        setDataScreening(state => ({
            ...state,
            activeImageEye: category
        }));
    };
    const formatData = () => {
        return {
            eaccount,
            userId,
            appId: app_id,
            id: dataScreening.id,
            visionShootOd: dataScreening.visionShootOdId,
            visionShootOi: dataScreening.visionShootOiId,
            pachymetryOd: dataScreening?.pachymetryOd,
            pachymetryOi: dataScreening?.pachymetryOi,
            pachymetryCorrectionOd: dataScreening.pachymetryCorrectionOd,
            pachymetryCorrectionOi: dataScreening.pachymetryCorrectionOi,
            confrontationVisualFieldTest: dataScreening.confrontationVisualFieldTest,
            intraocularPressureUncorrectedOd: dataScreening?.intraocularPressureUncorrectedOd as number | undefined,
            intraocularPressureUncorrectedOi: dataScreening?.intraocularPressureUncorrectedOi as number | undefined,
            correctedIntraocularPressureOd: dataScreening.correctedIntraocularPressureOd as number,
            correctedIntraocularPressureOi: dataScreening.correctedIntraocularPressureOi as number,
            backgroundEyeOd: dataScreening.fundusEye?.rightEye?.fileName ? {
                filename: dataScreening.fundusEye?.rightEye?.fileName,
                fileBase64: dataScreening.fundusEye?.rightEye?.base64,
            } : undefined,
            backgroundEyeOi: dataScreening.fundusEye?.leftEye?.fileName ? {
                filename: dataScreening.fundusEye?.leftEye?.fileName,
                fileBase64: dataScreening.fundusEye?.leftEye?.base64,
            } : undefined,
            octRetinaOd: dataScreening.octRetina?.rightEye?.fileName ? {
                filename: dataScreening.octRetina?.rightEye?.fileName,
                fileBase64: dataScreening.octRetina?.rightEye?.base64,
            } : undefined,
            octRetinaOi: dataScreening.octRetina?.leftEye?.fileName ? {
                filename: dataScreening.octRetina?.leftEye?.fileName,
                fileBase64: dataScreening.octRetina?.leftEye?.base64,
            } : undefined,
            octOpticNerveOd: dataScreening.octOpticNerve?.rightEye?.fileName ? {
                filename: dataScreening.octOpticNerve?.rightEye?.fileName,
                fileBase64: dataScreening.octOpticNerve?.rightEye?.base64,
            } : undefined,
            octOpticNerveOi: dataScreening.octOpticNerve?.leftEye?.fileName ? {
                filename: dataScreening.octOpticNerve?.leftEye?.fileName,
                fileBase64: dataScreening.octOpticNerve?.leftEye?.base64,
            } : undefined,
            backgroundEyeOdUrl: dataScreening.fundusEye?.rightEye?.clear ? "" : undefined,
            backgroundEyeOiUrl: dataScreening.fundusEye?.leftEye?.clear ? "" : undefined,
            octOpticNerveOdUrl: dataScreening.octOpticNerve?.rightEye?.clear ? "" : undefined,
            octOpticNerveOiUrl: dataScreening.octOpticNerve?.leftEye?.clear ? "" : undefined,
            octRetinaOdUrl: dataScreening.octRetina?.rightEye?.clear ? "" : undefined,
            octRetinaOiUrl: dataScreening.octRetina?.leftEye?.clear ? "" : undefined,
        };
    };

    const formatDataUpdate = () => {
        return { ...formatData(), id: dataScreening.id as number };
    };

    const onSaveScreeningDebounce = useDebouncedCallback(async () => {
        const response = await dispatch(updateScreening(formatDataUpdate()));
        if (response) {
            setDataScreening(state => ({ ...state, ...response.results }));
            setTriggerUpdate(triggerUpdate + 1);
        }
    }, 2000);

    useEffect(() => {
        (async function () {
            if (historySheet) {
                const resultVisionShoot = await dispatch(getVisionShoot());
                if (data && resultVisionShoot) {

                    setDataScreening(state => ({
                        ...state,
                        ...data,
                        fundusEye: {
                            leftEye: {
                                ...state.fundusEye?.leftEye,
                                src: data.backgroundEyeOiUrl
                            },
                            rightEye: {
                                ...state.fundusEye?.rightEye,
                                src: data.backgroundEyeOdUrl
                            },
                        },
                        octRetina: {
                            leftEye: {
                                ...state.octRetina?.leftEye,
                                src: data.octRetinaOiUrl
                            },
                            rightEye: {
                                ...state.octRetina?.rightEye,
                                src: data.octRetinaOdUrl
                            },
                        },
                        octOpticNerve: {
                            leftEye: {
                                ...state.octRetina?.leftEye,
                                src: data.octOpticNerveOiUrl
                            },
                            rightEye: {
                                ...state.octRetina?.rightEye,
                                src: data.octOpticNerveOdUrl
                            },
                        },
                        selects: { ...state.selects, vision_shoot: resultVisionShoot as IVisionShoot[] },
                    }));
                }
            } else {
                const resultVisionShoot = await dispatch(getVisionShoot());
                const resultScreening = await dispatch(getScreening({ mcfId, appId: app_id, userId, eaccount }));

                if (resultScreening?.rowTotal) {

                    setDataScreening(state => ({
                        ...state,
                        ...resultScreening.results,

                        fundusEye: {
                            leftEye: {
                                ...state.fundusEye?.leftEye,
                                src: resultScreening.results.backgroundEyeOiUrl
                            },
                            rightEye: {
                                ...state.fundusEye?.rightEye,
                                src: resultScreening.results.backgroundEyeOdUrl
                            },
                        },
                        octRetina: {
                            leftEye: {
                                ...state.octRetina?.leftEye,
                                src: resultScreening.results.octRetinaOiUrl
                            },
                            rightEye: {
                                ...state.octRetina?.rightEye,
                                src: resultScreening.results.octRetinaOdUrl
                            },
                        },
                        octOpticNerve: {
                            leftEye: {
                                ...state.octRetina?.leftEye,
                                src: resultScreening.results.octOpticNerveOiUrl
                            },
                            rightEye: {
                                ...state.octRetina?.rightEye,
                                src: resultScreening.results.octOpticNerveOdUrl
                            },
                        },
                        selects: { ...state.selects, vision_shoot: resultVisionShoot as IVisionShoot[] },
                    }));
                } else {
                    setDataScreening(state => ({
                        ...state,
                        selects: { ...state.selects, vision_shoot: resultVisionShoot as IVisionShoot[] },
                    }));
                }
            }
        })();
    }, [dispatch, mcfId, app_id, userId, eaccount, data, historySheet]);

    const validations = {
        max: 645,
        min: 445,
    };

    const renderModalEye = () => {
        const file = dataScreening[dataScreening?.eyePreview?.data.category as TCategoryEye]?.[dataScreening?.eyePreview?.data.eye as TSideOfEye];
        return (
            <Modal
                isOpen={dataScreening?.eyePreview?.showModal}
                width={900}
                positionModal={"center"}
                onClose={() => {
                    setDataScreening(state => ({
                        ...state,
                        eyePreview: {
                            showModal: false,
                            data: { category: "", eye: "" },
                        }
                    }));
                }}
            >

                <div className="w-100">
                    <div className="mb-3">
                        <h3 className="text-primary text-center fw-bold">
                            {`Ojo ${(dataScreening?.eyePreview?.data.eye as TSideOfEye) === "rightEye" ? "derecho" : "izquierdo"}`}
                        </h3>
                        <hr />
                    </div>

                    <div
                        className="p-4 m-auto d-flex justify-content-center"
                        style={{ maxHeight: "50vh", width: "70%" }}
                    >
                        <img
                            className="rounded img-fluid mw-100"
                            src={file?.src || dataScreening.eyePreview?.imageActiveModal}
                            alt={file?.fileName || dataScreening.eyePreview?.imageActiveModalName}
                        />
                    </div>
                </div>

            </Modal>
        );
    };

    const renderImageEye = ({ eye, category }: TOnClearFileData) => {
        let url = "";
        let urlName = "";

        if (category === "fundusEye" && eye === "rightEye") {
            url = dataScreening?.backgroundEyeOdUrl as string || "";
            urlName = getNameOfImageUrl(url) || "";
        }

        if (category === "fundusEye" && eye === "leftEye") {
            url = dataScreening?.backgroundEyeOiUrl as string || "";
            urlName = getNameOfImageUrl(url) || "";
        }

        if (category === "octRetina" && eye === "rightEye") {
            url = dataScreening?.octRetinaOdUrl as string || "";
            urlName = getNameOfImageUrl(url) || "";
        }

        if (category === "octRetina" && eye === "leftEye") {
            url = dataScreening?.octRetinaOiUrl as string || "";
            urlName = getNameOfImageUrl(url) || "";
        }

        if (category === "octOpticNerve" && eye === "rightEye") {
            url = dataScreening?.octOpticNerveOdUrl as string || "";
            urlName = getNameOfImageUrl(url) || "";
        }

        if (category === "octOpticNerve" && eye === "leftEye") {
            url = dataScreening?.octOpticNerveOiUrl as string || "";
            urlName = getNameOfImageUrl(url) || "";
        }

        if ((dataScreening?.activeImageEye && dataScreening?.[category]?.[eye]?.src) || (dataScreening?.activeImageEye && url)) {

            return (
                <div>
                    <img
                        className="rounded"
                        style={{ height: "150px", width: "150px" }}
                        src={dataScreening?.[category]?.[eye]?.src || url}
                        alt={dataScreening?.[category]?.[eye]?.fileName || urlName}
                    />
                    <IconSearch
                        className="IconSearch"
                        strokeWidth={3}
                        size={32}
                        onClick={() => {
                            setDataScreening(state => ({
                                ...state,
                                eyePreview: {
                                    showModal: true,
                                    imageActiveModal: url,
                                    imageActiveModalName: urlName,
                                    data: {
                                        category,
                                        eye
                                    }
                                }
                            }));

                        }}
                    />
                </div>
            );
        }

        return <span className="text-black-50">Adjuntar imagen para poder visualizarla</span>;
    };

    return (
        <div>
            <div className="row mt-4">
                <div className="col-6">
                    <SectionCard className="mb-3">
                        <div className="">
                            <span className="text-secondary fw-bold mb-2" style={{ minWidth: 100 }}>Toma de visión</span>
                            <div className="d-flex gap-3">
                                <div style={{ width: "47%" }}>
                                    <label className="text-label">OD</label>
                                    <Select
                                        disabled={isDisabledForm}
                                        width={"100%"}
                                        changeToInput={historySheet}
                                        inputValue={dataScreening.visionShootOdName}
                                        options={dataScreening?.selects?.vision_shoot?.map(el => ({ label: el.name, value: el.id }))}
                                        value={{
                                            label: dataScreening?.selects?.vision_shoot?.find(el => el.id === dataScreening.visionShootOdId)?.name as string,
                                            value: dataScreening?.selects?.vision_shoot?.find(el => el.id === dataScreening.visionShootOdId)?.id as number
                                        }}
                                        onChange={({ option }) => {
                                            setDataScreening(state => ({
                                                ...state,
                                                visionShootOdId: option.value
                                            }));
                                            onSaveScreeningDebounce();
                                        }}
                                    />

                                </div>
                                <div style={{ width: "47%" }}>
                                    <label className="text-label">OI</label>
                                    <Select
                                        disabled={isDisabledForm}
                                        changeToInput={historySheet}
                                        inputValue={dataScreening.visionShootOiName}
                                        options={dataScreening?.selects?.vision_shoot?.map(el => ({ label: el.name, value: el.id }))}
                                        value={{
                                            label: dataScreening?.selects?.vision_shoot?.find(el => el.id === dataScreening.visionShootOiId)?.name as string,
                                            value: dataScreening?.selects?.vision_shoot?.find(el => el.id === dataScreening.visionShootOiId)?.id as number
                                        }}
                                        onChange={({ option }) => {
                                            setDataScreening(state => ({
                                                ...state,
                                                visionShootOiId: option.value
                                            }));
                                            onSaveScreeningDebounce();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </SectionCard>

                    <SectionCard className="mb-3">
                        <div className="d-flex align-items-end">
                            <span className="text-secondary fw-bold mb-2" style={{ minWidth: 100 }}>Paquimetría</span>
                            <div className="me-3">
                                <label className="text-label">OD</label>
                                <input
                                    disabled={isDisabledForm}
                                    id="pachymetry_od"
                                    type="number"
                                    value={dataScreening.pachymetryOd}
                                    onChange={(event) => {
                                        if ((Number(event.target.value) >= validations.min && Number(event.target.value) <= validations.max) || event.target.value === "") {
                                            event.target.classList.remove("border-danger");
                                        } else {
                                            event.target.classList.add("border-danger");
                                        }
                                        const correction = correctionValue(Number(event.target.value));

                                        setDataScreening(state => ({
                                            ...state,
                                            pachymetryOd: event.target.value,
                                            pachymetryCorrectionOd: correction as number,
                                        }));

                                        onSaveScreeningDebounce();
                                    }}
                                    className={"form-control form-control-sm h-50"}
                                />
                                <ReactTooltip
                                    anchorId="pachymetry_od"
                                    place="bottom"
                                    content="El valor ingresado debe ser entre el rango de 445 a 645"
                                    style={{ width: "200px", textAlign: "center", opacity: "0.6" }}
                                />

                            </div>
                            <div className="me-3">
                                <label className="text-label">Corrección</label>
                                <input
                                    disabled
                                    type="number"
                                    value={dataScreening?.pachymetryCorrectionOd !== undefined ? dataScreening.pachymetryCorrectionOd : ""}
                                    className={"form-control form-control-sm h-50"}
                                />

                            </div>
                            <div className="me-3">
                                <label className="text-label">OI</label>
                                <input
                                    id="pachymetry_oi"
                                    type="number"
                                    disabled={isDisabledForm}
                                    value={dataScreening?.pachymetryOi}
                                    onChange={(event) => {
                                        if ((Number(event.target.value) >= validations.min && Number(event.target.value) <= validations.max) || event.target.value === "") {
                                            event.target.classList.remove("border-danger");
                                        } else {
                                            event.target.classList.add("border-danger");
                                        }
                                        const correction = correctionValue(Number(event.target.value));
                                        setDataScreening(state => ({
                                            ...state,
                                            pachymetryOi: event.target.value,
                                            pachymetryCorrectionOi: correction as number
                                        }));
                                        onSaveScreeningDebounce();
                                    }}
                                    className={"form-control form-control-sm h-50"}
                                />
                                <ReactTooltip
                                    anchorId="pachymetry_oi"
                                    place="bottom"
                                    content="El valor ingresado debe ser entre el rango de 445 a 645"
                                    style={{ width: "200px", textAlign: "center", opacity: "0.6" }}
                                />
                            </div>
                            <div className="me-3">
                                <label className="text-label">Corrección</label>
                                <input
                                    disabled
                                    type="number"
                                    value={dataScreening?.pachymetryCorrectionOi !== undefined ? dataScreening.pachymetryCorrectionOi : ""}
                                    className={"form-control form-control-sm h-50"}
                                />
                            </div>
                        </div>
                    </SectionCard>

                    <SectionCard className="mb-3">
                        <span className="fs-5 fw-bold text-secondary">Presión intraocular</span>
                        <hr className="my-2" />
                        <div className="d-flex align-items-end mb-1">
                            <span className="text-secondary fw-bold mb-2" style={{ minWidth: 100 }}>Sin corrección</span>
                            <div className="me-3 w-100">
                                <label className="text-label">OD</label>
                                <input
                                    id="intraocular_pressure_uncorrected_od"
                                    type="number"
                                    disabled={(dataScreening.pachymetryCorrectionOd === undefined) || isDisabledForm}
                                    {...registerPreConsultation("intraocular_pressure_uncorrected_od")}
                                    value={dataScreening.intraocularPressureUncorrectedOd || ""}
                                    className={"form-control form-control-sm h-50"}
                                    onChange={(({ target }) => {
                                        setDataScreening(state => ({
                                            ...state,
                                            intraocularPressureUncorrectedOd: target.value as unknown as number
                                        }));
                                        onSaveScreeningDebounce();
                                    })}
                                />
                                <ReactTooltip
                                    anchorId="intraocular_pressure_uncorrected_od"
                                    place="bottom"
                                    content="No se aceptan valores negativos"
                                    style={{ width: "200px", textAlign: "center", opacity: "0.6" }}
                                />
                            </div>
                            <div className="w-100">
                                <label className="text-label">OI</label>
                                <input
                                    id="intraocular_pressure_uncorrected_oi"
                                    type="number"
                                    value={dataScreening.intraocularPressureUncorrectedOi || ""}
                                    disabled={(dataScreening.pachymetryCorrectionOi === undefined) || isDisabledForm}
                                    {...registerPreConsultation("intraocular_pressure_uncorrected_oi")}
                                    className={"form-control form-control-sm h-50"}
                                    onChange={(({ target }) => {
                                        setDataScreening(state => ({
                                            ...state,
                                            intraocularPressureUncorrectedOi: target.value as unknown as number
                                        }));
                                        onSaveScreeningDebounce();
                                    })}
                                />
                                <ReactTooltip
                                    anchorId="intraocular_pressure_uncorrected_oi"
                                    place="bottom"
                                    content="No se aceptan valores negativos"
                                    style={{ width: "200px", textAlign: "center", opacity: "0.6" }}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-end">
                            <span className="text-secondary fw-bold mb-2" style={{ minWidth: 100 }}>Corregida</span>
                            <div className="me-3 w-100">
                                <label className="text-label">OD</label>
                                <input
                                    disabled
                                    key={triggerUpdate + "OD"}
                                    type="number"
                                    value={dataScreening.correctedIntraocularPressureOd}
                                    className={"form-control form-control-sm h-50"}
                                />
                            </div>
                            <div className="w-100">
                                <label className="text-label">OI</label>
                                <input
                                    disabled
                                    key={triggerUpdate + "OI"}
                                    type="number"
                                    value={dataScreening.correctedIntraocularPressureOi}
                                    className={"form-control form-control-sm h-50"}
                                />
                            </div>
                        </div>
                    </SectionCard>

                    <SectionCard className={`mb-3 ${dataScreening.activeImageEye === "fundusEye" ? "bg-orange_40" : ""}`}>
                        <div className="d-flex align-items-end">
                            <span className="text-secondary fw-bold mb-2" style={{ minWidth: 100 }}>Fondo Ojo</span>
                            <div className="me-3 w-100">
                                <label className="text-label">OD</label>
                                <File
                                    disabled={isDisabledForm}
                                    className="form-control form-control-sm h-50"
                                    fileName={dataScreening.fundusEye?.rightEye?.fileName || getNameOfImageUrl(dataScreening.backgroundEyeOdUrl as string || "") || undefined}
                                    allowedFormats={[".jpeg", ".jpg", ".png"]}
                                    onDelete={() => {
                                        onClearFileData({ category: "fundusEye", eye: "rightEye" });
                                        setDataScreening(state => ({
                                            ...state,
                                            backgroundEyeOdUrl: undefined
                                        }));
                                        onSaveScreeningDebounce();
                                    }}
                                    onChange={({ target }) => {
                                        convertFileBase64({ allowedFormats: ["png", "jpeg", "jpg"], files: target.files })
                                            ?.then((file) => onChangeFileData({ category: "fundusEye", eye: "rightEye", file }))
                                            .catch(error => {
                                                fireErrorAlert(error.message);
                                            });
                                        onSaveScreeningDebounce();
                                    }}
                                />
                            </div>
                            <div className="w-100">
                                <label className="text-label">OI</label>
                                <File
                                    disabled={isDisabledForm}
                                    className="form-control form-control-sm h-50"
                                    fileName={dataScreening.fundusEye?.leftEye?.fileName || getNameOfImageUrl(dataScreening?.backgroundEyeOiUrl as string || "") || undefined}
                                    allowedFormats={[".jpeg", ".jpg", ".png"]}
                                    onDelete={() => {
                                        onClearFileData({ category: "fundusEye", eye: "leftEye" });
                                        setDataScreening(state => ({
                                            ...state,
                                            backgroundEyeOiUrl: undefined
                                        }));
                                        onSaveScreeningDebounce();
                                    }}
                                    onChange={({ target }) => {
                                        convertFileBase64({ allowedFormats: ["png", "jpeg", "jpg"], files: target.files })
                                            ?.then((file) => onChangeFileData({ category: "fundusEye", eye: "leftEye", file }))
                                            .catch(error => {
                                                fireErrorAlert(error.message);
                                            });
                                        onSaveScreeningDebounce();
                                    }}
                                />
                            </div>
                            <IconChevronRight
                                className="text-primary pointer"
                                width={100}
                                onClick={() => onActiveImagenEye("fundusEye")}
                            />
                        </div>
                    </SectionCard>

                    <SectionCard className={`mb-3 ${dataScreening.activeImageEye === "octRetina" ? "bg-orange_40" : ""}`}>
                        <div className="d-flex align-items-end">
                            <span className="text-secondary fw-bold mb-2" style={{ minWidth: 100 }}>OCT de retina</span>
                            <div className="me-3 w-100">
                                <label className="text-label">OD</label>
                                <File
                                    disabled={isDisabledForm}
                                    className="form-control form-control-sm h-50"
                                    fileName={dataScreening?.octRetina?.rightEye?.fileName || getNameOfImageUrl(dataScreening?.octRetinaOdUrl as string || "") || undefined}
                                    allowedFormats={[".jpeg", ".jpg", ".png"]}
                                    onDelete={() => {
                                        onClearFileData({ category: "octRetina", eye: "rightEye" });
                                        setDataScreening(state => ({
                                            ...state,
                                            octRetinaOdUrl: undefined
                                        }));
                                        onSaveScreeningDebounce();
                                    }}
                                    onChange={({ target }) => {
                                        convertFileBase64({ allowedFormats: ["png", "jpeg", "jpg"], files: target.files })
                                            ?.then((file) => onChangeFileData({ category: "octRetina", eye: "rightEye", file }))
                                            .catch(error => {
                                                fireErrorAlert(error.message);
                                            });
                                        onSaveScreeningDebounce();
                                    }}
                                />
                            </div>
                            <div className="w-100">
                                <label className="text-label">OI</label>
                                <File
                                    disabled={isDisabledForm}
                                    className="form-control form-control-sm h-50"
                                    fileName={dataScreening?.octRetina?.leftEye?.fileName || getNameOfImageUrl(dataScreening?.octRetinaOiUrl as string || "") || undefined}
                                    allowedFormats={[".jpeg", ".jpg", ".png"]}
                                    onDelete={() => {
                                        onClearFileData({ category: "octRetina", eye: "leftEye" });
                                        setDataScreening(state => ({
                                            ...state,
                                            octRetinaOiUrl: undefined
                                        }));
                                        onSaveScreeningDebounce();
                                    }}
                                    onChange={({ target }) => {
                                        convertFileBase64({ allowedFormats: ["png", "jpeg", "jpg"], files: target.files })
                                            ?.then((file) => onChangeFileData({ category: "octRetina", eye: "leftEye", file }))
                                            .catch(error => {
                                                fireErrorAlert(error.message);
                                            });
                                        onSaveScreeningDebounce();
                                    }}
                                />
                            </div>
                            <IconChevronRight
                                className="text-primary pointer"
                                width={100}
                                onClick={() => onActiveImagenEye("octRetina")}
                            />
                        </div>
                    </SectionCard>

                    <SectionCard className={`${dataScreening.activeImageEye === "octOpticNerve" ? "bg-orange_40" : ""}`}>
                        <div className="d-flex align-items-end">
                            <span className="text-secondary fw-bold" style={{ minWidth: 100 }}>OCT de nervio óptico</span>
                            <div className="me-3 w-100">
                                <label className="text-label">OD</label>
                                <File
                                    disabled={isDisabledForm}
                                    className="form-control form-control-sm h-50"
                                    fileName={dataScreening?.octOpticNerve?.rightEye?.fileName || getNameOfImageUrl(dataScreening?.octOpticNerveOdUrl as string || "") || undefined}
                                    allowedFormats={[".jpeg", ".jpg", ".png"]}
                                    onDelete={() => {
                                        onClearFileData({ category: "octOpticNerve", eye: "rightEye" });
                                        setDataScreening(state => ({
                                            ...state,
                                            octOpticNerveOdUrl: undefined
                                        }));
                                        onSaveScreeningDebounce();
                                    }}
                                    onChange={({ target }) => {
                                        convertFileBase64({ allowedFormats: ["png", "jpeg", "jpg"], files: target.files })
                                            ?.then((file) => onChangeFileData({ category: "octOpticNerve", eye: "rightEye", file }))
                                            .catch(error => {
                                                fireErrorAlert(error.message);
                                            });
                                        onSaveScreeningDebounce();
                                    }}
                                />
                            </div>
                            <div className="w-100">
                                <label className="text-label">OI</label>
                                <File
                                    disabled={isDisabledForm}
                                    className="form-control form-control-sm h-50"
                                    fileName={dataScreening.octOpticNerve?.leftEye?.fileName || getNameOfImageUrl(dataScreening?.octOpticNerveOiUrl as string || "") || undefined}
                                    allowedFormats={[".jpeg", ".jpg", ".png"]}
                                    onDelete={() => {
                                        onClearFileData({ category: "octOpticNerve", eye: "leftEye" });
                                        setDataScreening(state => ({
                                            ...state,
                                            octOpticNerveOiUrl: undefined
                                        }));
                                        onSaveScreeningDebounce();
                                    }}
                                    onChange={({ target }) => {
                                        convertFileBase64({ allowedFormats: ["png", "jpeg", "jpg"], files: target.files })
                                            ?.then((file) => onChangeFileData({ category: "octOpticNerve", eye: "leftEye", file }))
                                            .catch(error => {
                                                fireErrorAlert(error.message);
                                            });
                                        onSaveScreeningDebounce();
                                    }}
                                />
                            </div>
                            <IconChevronRight
                                className="text-primary pointer"
                                width={100}
                                onClick={() => onActiveImagenEye("octOpticNerve")}
                            />
                        </div>
                    </SectionCard>

                </div>
                <div className="col-6">
                    <SectionCard className="h-100">
                        <h3 className="text-primary text-center fw-bold">Detalle imágenes</h3>
                        <hr />
                        <div className="h-100 mb-5">
                            <label className="text-secondary fw-bold ms-1">Ojo derecho</label>
                            <div className="position-relative h-100 p-3 border rounded d-flex align-items-center justify-content-center pointer bg-light bg-opacity-10">
                                {renderImageEye({ eye: "rightEye", category: dataScreening.activeImageEye as TCategoryEye, })}
                            </div>
                        </div>
                        <div className="h-100 mb-5">
                            <label className="text-secondary fw-bold ms-1">Ojo izquierdo</label>
                            <div className="position-relative h-100 p-3 border rounded d-flex align-items-center justify-content-center pointer bg-light bg-opacity-10">
                                {renderImageEye({ eye: "leftEye", category: dataScreening.activeImageEye as TCategoryEye })}
                            </div>
                        </div>
                    </SectionCard>
                </div>
                <div className="py-3">
                    <SectionCard className="h-100">
                        <h5 className="text-primary text-start fw-bold">Campo visual por confrontación</h5>
                        <hr />
                        <ConfrontationComponentEye
                            payload={dataScreening}
                            setDataScreening={setDataScreening}
                            onSaveScreeningDebounce={onSaveScreeningDebounce}
                        />
                    </SectionCard>

                </div>
                {renderModalEye()}
            </div>
        </div>
    );
}); 