import Swal from "sweetalert2";
//
import * as uiActions from "@/config/slices/ui";
import * as homeService from "@/services/home.service";
import { AppDispatch } from "@/config/store";
import { statusCodes } from "@/constants/http";
import { Account, CurrentAccount, NewTokenParams } from "@/models/account";
import { setWorkspace } from "@/config/slices/workspace";
import { Location } from "react-router-dom";

export function getAccountsByUser(userId: string, location?: Location) {
    return async function (dispatch: AppDispatch): Promise<Account[] | undefined> {
        try {
            dispatch(uiActions.startLoading());
            const response = await homeService.getAccountsByUser(userId);

            if (response.status !== statusCodes.OK) {
                Swal.fire({
                    title: "Error",
                    text: response.message,
                    icon: "error",
                    confirmButtonColor: "#00B4CC",
                });
                dispatch(uiActions.finishLoading());
                return;
            }
            return response.accounts;

        } catch (error) {
            console.error(error);
            dispatch(uiActions.finishLoading());

        } finally {
            if (location && !location.pathname.includes("home")) {
                dispatch(uiActions.finishLoading());
            }

        }
    };
}

export function saveAccount(account: CurrentAccount) {
    return function (dispatch: AppDispatch) {
        dispatch(setWorkspace(account));
        localStorage.setItem("account", JSON.stringify(account));
    };
}
export function createNewToken(payload: NewTokenParams) {
    return async function () {
        const response = await homeService.getNewTokenService(payload);
        if (response) {
            localStorage.setItem("x_token", response.results.token as string);
            return true;
        }
    };
}
export function loadAccount() {
    return function (dispatch: AppDispatch) {
        const account = localStorage.getItem("account");

        if (account) {
            dispatch(setWorkspace(JSON.parse(account)));
        }
    };
}

export function loader(state: boolean) {
    return function (dispatch: AppDispatch) {
        if (state) {
            dispatch(uiActions.startLoading());
        } else {
            dispatch(uiActions.finishLoading());
        }
    };
}