import {
    IconArrowsMaximize,
    IconChecklist,
    IconCirclePlus,
    IconCircleX,
    IconEdit,
    IconRefresh
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDebouncedCallback } from "use-debounce";

import {
    deletePressure,
    getDiagnosticAidsSheet,
    getDoctorList,
    getReading,
    updateDiagnosticAids,
    updateReading
} from "./diagnosticAids.actions";

import {
    BadgeTextField,
    Button,
    Checkbox,
    Modal,
    SectionCard,
    Select,
    TextField
} from "@/components";

import { Appointment, DilatationItem, Variables } from "@/models";
import { Data, Structure } from "@/models/historyDetails/diagnosticAdxDetails";
import { Article } from "@/models/pre-consultation/generals";
import {
    BodyFile,
    DoctorListPayload,
    ExamDone,
    FieldBody,
    Images,
    PressureCurveBody,
    Report
} from "@/models/sheets/diagnosticAids";

import {
    finishLoading,
    setAppointmentSelected,
    startLoading
} from "@/config/slices";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
    convertFileBase64,
    correctionValue,
    fireErrorAlert,
    fireSuccessAlert,
    fireWarningAlert,
    generateId
} from "@/utils";
import { getArticles } from "../PatientAttention/Consultation/PreConsultation/preConsultation.actions";

import AudioRecorderComponent from "@/components/AudioRecorder/AudioRecorder";
import { SelectOption } from "@/components/Select/select";
import TranscriptionForm from "@/features/Workspace/Transcription/TranscriptionForm";
import ConsultationControls from "../PatientAttention/Consultation/Controls/Controls";
import ConsultationHeader from "../PatientAttention/Consultation/Header/Header";
import History from "../PatientAttention/Consultation/History/History";
import ProvocativeChart from "../PatientAttention/Consultation/Oftalmology/ProvocativeChart/ProvocativeChart";

import "./DiagnosticAids.scss";

interface Example {
    id?: number;
    quantity?: number;
    articleId?: number;
    articleName?: string;
    lotId?: number;
    lotName?: string;
    index: number;
    wmId?: number;
    time?: string;
}

interface SelectOptions {
    value: string | number;
    label: string;
    url: string;
}

const DiagnosticAidsSheet = ({ onlyBodyAdxSheet = false, structure, dataForm, isDisabled }: { onlyBodyAdxSheet?: boolean, structure?: Structure, dataForm?: Data, isDisabled?: boolean; }) => {
    const dispatch = useAppDispatch();
    const {
        referringDoctor: doctorName,
        mcfId,
        name: serviceName,
        vars: varsADX,
        reportRequired: reportRequiredADX,
        requiresDilation: requiresDilationADX,
        requiresImage: requiresImageADX,
        requires_pressure_curve: requiresPressureCurveADX,
        id: srvId,
        isAtipical,
    } = useAppSelector(state => state.patientAttention.patientStatus);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const currentAppointment = useAppSelector(state => state.patientAttention.appointmentSelected);
    const isDisabledForm = isDisabled;
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const { state } = useLocation();
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [dilatationRegisterPayload, setDilatationRegisterPayload] = useState<Example[]>([]);
    const [articles, setArticles] = useState<Article[]>([]);
    const [selectedSection, setSelectedSection] = useState("");
    const [filesOptions, setFilesOptions] = useState<SelectOptions[]>([]);
    const [doctorListOptions, setDoctorListOptions] = useState<DoctorListPayload[]>([]);
    const [trigger, setTrigger] = useState<number>(0);
    const [showModalViewer, setShowModalViewer] = useState<boolean>(false);
    const [reportRequired, setReportRequired] = useState(false);
    const [requiresDilation, setRequiresDilation] = useState(false);
    const [requiresImage, setRequiresImage] = useState(false);
    const [requiresPressure, setRequiresPressure] = useState(false);
    const [variables, setVariables] = useState<Variables[]>([]);
    const [appId, setAppId] = useState<number | null>(null);
    const [cluId, setCluId] = useState<number | null | undefined>(null);
    const [isAudioSaved, setIsAudioSaved] = useState(false);
    const [triggerChart, setTriggerChart] = useState(0);
    const [tooltipOfVariable, setTooltipOfVariable] = useState("");
    const [showButtons, setShowButtons] = useState(true);
    const [provocativeItems, setProvocativeItems] = useState<PressureCurveBody[]>([
        {
            order: 1,
            time: dayjs().format("HH:mm:ss"),
            identifier: new Date().getTime().toString() + 1
        }
    ]);
    const [fileSelected, setFileSelected] = useState<
        {
            url: string,
            value: string | number;
        }
    >({
        url: "",
        value: ""
    });
    const [affects, setAffects] = useState<{
        affects: number,
        value: number,
    }[]>([]);
    const [adxId, setAdxId] = useState<number | null>(null);
    const [examDonePayload, setExamDonePayload] = useState<ExamDone>({
        comment: "",
        variables: []
    });
    const [imagesPayload, setImagesPayload] = useState<Images>({
        od: [],
        oi: [],
    });
    const [reportPayload, setReportPayload] = useState<Report>({
        needInterpretation: false,
        reader: "",
        files: []
    });
    const [inabilityField, setInabilityField] = useState({
        needInterpretation: false,
        reader: false,
        report: false,
    });
    const [urlAudio, setUrlAudio] = useState<string>("");
    const [triggerSelect, setTriggerSelect] = useState(0);

    useEffect(() => {
        if (state?.tab !== "transcription" || onlyBodyAdxSheet) {
            setReportRequired(structure ? false : state?.tab === "adx" ? reportRequiredADX : state?.reportRequired ?? false);
            setRequiresImage(structure ? false : state?.tab === "adx" ? requiresImageADX : state?.requiresImage ?? false);
            setRequiresDilation(structure ? structure.requiresDilation : state?.tab === "adx" ? requiresDilationADX : state?.requiresDilatation ?? false);
            setRequiresPressure(structure ? structure.requires_pressure_curve : state?.tab === "adx" ? requiresPressureCurveADX : state?.requires_pressure_curve ?? false);
            setVariables(structure ? structure.vars : state?.tab === "adx" ? varsADX : state?.vars ?? []);
            if (state?.tab === "reading" && !onlyBodyAdxSheet) {
                localStorage.setItem("appointment", JSON.stringify({ ...currentAppointment, status: "admitted" }));
                dispatch(setAppointmentSelected({ ...currentAppointment as Appointment, status: "admitted", }));
                getReadingMethod();
            } else {
                getDiagnosticAidsMethod();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, appointmentId, accountId, mcfId, userId]);

    useEffect(() => {
        async function fetchData() {
            if (state?.tab !== "transcription" && !onlyBodyAdxSheet) {
                const data = await dispatch(getArticles(appointmentId));
                setArticles(data as Article[]);
                if (srvId) {
                    const doctorList = await dispatch(getDoctorList({ srvId }));
                    setDoctorListOptions(doctorList as DoctorListPayload[]);
                }
            }
        }
        const images = structure ? false : state?.tab === "adx" ? requiresImageADX : state?.requiresImage ?? false;
        const report = structure ? false : state?.tab === "adx" ? reportRequiredADX : state?.reportRequired ?? false;
        if ((report || images)) {
            setShowHistory(false);
        } else {
            setShowHistory(true);
        }
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, accountId, appointmentId, mcfId, userId, srvId]);


    useEffect(() => {
        if (reportPayload.files && reportPayload.files.length > 0) {
            setInabilityField({
                needInterpretation: true,
                reader: true,
                report: false,
            });
        } else if (reportPayload.needInterpretation) {
            setInabilityField({
                needInterpretation: false,
                reader: true,
                report: true,
            });
        } else if (reportPayload.reader && reportPayload.reader !== null && reportPayload.reader !== "") {
            setInabilityField({
                needInterpretation: true,
                reader: false,
                report: true,
            });
        } else {
            setInabilityField({
                needInterpretation: false,
                reader: false,
                report: false,
            });
        }
    }, [reportPayload]);
    const [segment, setSegment] = useState({});
    // ----------------------- transcription section ------------------------------

    // ----------------------- reading section ---------------------------------
    const readingSection = () => {

        const onSubmitAudio = (base64: string, ext?: string) => {

            const data = {
                "filename": "audio",
                "base64": base64,
                "ext": ext ?? "webm",
            };
            setSegment((states)=>({...states,audio:data}));
            onSubmitSection("audio");
        };

        return (
            <div className="controls">
                <AudioRecorderComponent onSubmitAudio={onSubmitAudio} urlAudio={urlAudio}
                    isAudioSaved={isAudioSaved} setIsAudioSaved={setIsAudioSaved}
                />
            </div>
        );
    };

    const getReadingMethod = async () => {
        async function fetchData() {
            const data = await dispatch(getReading({
                eaccount: accountId,
                userId: userId as number,
                srvId: state?.data?.srvId,
                adxId: state?.data?.adxId,
            }));
            if (data) {
                if (data.audio) { setUrlAudio(data.audio.url); }
                if (data.cluId) { setCluId(data.cluId); }
                if (data.adxId) { setAdxId(data.adxId); }
                if (data.images) {
                    setImagesPayload(data.images ?? {});
                }
                setAppId(data?.appId);
                setCluId(data?.cluId);
                setExamDonePayload(data.examDone ?? {});
                setVariables(data.vars ?? []);
                // --------------------------------------------- 
                if (data?.report?.files && data?.report?.files?.length > 0) {
                    uploadFilesFunction("report", true);
                    setFileSelected({ value: (data?.report?.files[0].id ?? data?.report?.files[0].value) ?? "", url: (data?.report?.files[0].url ?? `data:application/pdf;base64,${data?.report?.files[0].base64}`) ?? "" });
                } else if (data?.images?.od && data?.images?.od?.length > 0) {
                    uploadFilesFunction("od", true);
                    setFileSelected({ value: (data?.images?.od[0].id ?? data?.images?.od[0].value) ?? "", url: (data?.images?.od[0].url ?? `data:application/pdf;base64,${data?.images?.od[0].base64}`) ?? "" });
                } else if (data?.images?.oi && data?.images?.oi?.length > 0) {
                    uploadFilesFunction("oi", true);
                    setFileSelected({ value: (data?.images?.oi[0].id ?? data?.images?.oi[0].value) ?? "", url: (data?.images?.oi[0].url ?? `data:application/pdf;base64,${data?.images?.oi[0].base64}`) ?? "" }); 
                }else if (data?.images?.ao && data?.images?.ao?.length > 0) {
                    uploadFilesFunction("ao", true);
                    setFileSelected({ value: (data?.images?.ao[0].id ?? data?.images?.ao[0].value) ?? "", url: (data?.images?.ao[0].url ?? `data:application/pdf;base64,${data?.images?.ao[0].base64}`) ?? "" });
                }
            }
        }
        fetchData();
    };

    // ----------------------- diagnostic ADX consultation ----------------------------
    const getDiagnosticAidsMethod = async (hide?: boolean) => {
        async function fetchData() {
            let data;
            if (dataForm && onlyBodyAdxSheet) {
                data = dataForm;
            } else {
                data = await dispatch(getDiagnosticAidsSheet({
                    appId: appointmentId,
                    eaccount: accountId,
                    userId: userId as number
                }, () => {
                    if (hide) {
                        setShowButtons(true);
                    }
                }));
            }
            if (data) {
                if (data.images) {
                    setImagesPayload(data.images ?? {});
                }
                if (data.report) {
                    setReportPayload(data.report ?? {
                        needInterpretation: false,
                        reader: "",
                        files: []
                    });
                }
                if (data.pressureCurve) {
                    setProvocativeItems(data.pressureCurve);
                }
                setExamDonePayload(data.examDone ?? {});
                if (data.dilatationRegister) {
                    setDilatationRegisterPayload(data.dilatationRegister.map((item, index) => ({
                        id: item.id,
                        quantity: item.qty,
                        articleId: item.artId,
                        lotId: item.lotId,
                        lotName: item.lotName,
                        index: index + 1,
                        wmId: item.wmId,
                        createdAt: item.createdAt,
                        time: item.time,
                    })));
                }

                // ---------------------------------------------
                if (data?.report?.files && data?.report?.files?.length > 0) {
                    uploadFilesFunction("report", true, data?.report);
                    setFileSelected({ value: (data?.report?.files[0].id ?? data?.report?.files[0].value) ?? "", url: (data?.report?.files[0].url ?? `data:application/pdf;base64,${data?.report?.files[0].base64}`) ?? "" });
                } else if (data?.images?.od && data?.images?.od?.length > 0) {
                    uploadFilesFunction("od", true, data?.images);
                    setFileSelected({ value: (data?.images?.od[0].id ?? data?.images?.od[0].value) ?? "", url: (data?.images?.od[0].url ?? `data:application/pdf;base64,${data?.images?.od[0].base64}`) ?? "" });
                } else if (data?.images?.oi && data?.images?.oi?.length > 0) {
                    uploadFilesFunction("oi", true, data?.images);
                    setFileSelected({ value: (data?.images?.oi[0].id ?? data?.images?.oi[0].value) ?? "", url: (data?.images?.oi[0].url ?? `data:application/pdf;base64,${data?.images?.oi[0].base64}`) ?? "" }); 
                } else if (data?.images?.ao && data?.images?.ao?.length > 0) {
                    uploadFilesFunction("ao", true, data?.images);
                    setFileSelected({ value: (data?.images?.ao[0].id ?? data?.images?.ao[0].value) ?? "", url: (data?.images?.ao[0].url ?? `data:application/pdf;base64,${data?.images?.ao[0].base64}`) ?? "" });
                }
            }
            setTriggerChart(triggerChart + 1);
        }
        fetchData();
    };

    const uploadFilesFunction = (section: "report" | "od" | "oi" | "ao", setting: boolean, files?: Report | Images) => {
        setSelectedSection(section);
        if (section === "report") {
            if (setting && reportPayload.files && reportPayload.files?.length > 0) {
                setFileSelected({ value: (reportPayload.files[0].id ?? reportPayload.files[0].value) ?? "", url: (reportPayload.files[0].url ?? `data:application/pdf;base64,${reportPayload.files[0].base64}`) ?? "" });
            }
            if (files) {
                const options = files.files && files.files?.length > 0 ? files.files.map(file => ({
                    label: file.filename ?? "",
                    value: (file.id ?? file.value) ?? "",
                    url: file.url ?? "",
                    base64: file.base64 ?? ""
                })) : [];
                setFilesOptions(options);
                setTrigger(trigger + 1);
            } else {
                const options = reportPayload.files && reportPayload.files?.length > 0 ? reportPayload.files.map(file => ({
                    label: file.filename ?? "",
                    value: (file.id ?? file.value) ?? "",
                    url: file.url ?? "",
                    base64: file.base64 ?? ""
                })) : [];
                setFilesOptions(options);
                setTrigger(trigger + 1);
            }

        } else if (section === "od") {
            if (setting && imagesPayload.od && imagesPayload.od?.length > 0) {
                setFileSelected({ value: (imagesPayload.od[0].id ?? imagesPayload.od[0].value) ?? "", url: (imagesPayload.od[0].url ?? `data:application/pdf;base64,${imagesPayload.od[0].base64}`) ?? "" });
            }
            if (files) {
                const options = files.od && files.od?.length > 0 ? files.od.map(file => ({
                    label: file.filename ?? "",
                    value: (file.id ?? file.value) ?? "",
                    url: file.url ?? "",
                    base64: file.base64 ?? ""
                })) : [];
                setFilesOptions(options);
                setTrigger(trigger + 1);
            } else {
                const options = imagesPayload.od && imagesPayload.od?.length > 0 ? imagesPayload.od.map(file => ({
                    label: file.filename ?? "",
                    value: (file.id ?? file.value) ?? "",
                    url: file.url ?? "",
                    base64: file.base64 ?? ""
                })) : [];
                setFilesOptions(options);
                setTrigger(trigger + 1);
            }


        } else if (section === "oi") {
            if (setting && imagesPayload.oi && imagesPayload.oi?.length > 0) {
                setFileSelected({ value: (imagesPayload.oi[0].id ?? imagesPayload.oi[0].value) ?? "", url: (imagesPayload.oi[0].url ?? `data:application/pdf;base64,${imagesPayload.oi[0].base64}`) ?? "" });
            }
            if (files) {
                const options = files.oi && files.oi?.length > 0 ? files.oi.map(file => ({
                    label: file.filename ?? "",
                    value: (file.id ?? file.value) ?? "",
                    url: file.url ?? "",
                    base64: file.base64 ?? ""
                })) : [];
                setFilesOptions(options);
                setTrigger(trigger + 1);
            } else {
                const options = imagesPayload.oi && imagesPayload.oi?.length > 0 ? imagesPayload.oi.map(file => ({
                    label: file.filename ?? "",
                    value: (file.id ?? file.value) ?? "",
                    url: file.url ?? "",
                    base64: file.base64 ?? ""
                })) : [];
                setFilesOptions(options);
                setTrigger(trigger + 1);
            } 
        } else if (section === "ao") {
            if (setting && imagesPayload.ao && imagesPayload.ao?.length > 0) {
                setFileSelected({ value: (imagesPayload.ao[0].id ?? imagesPayload.ao[0].value) ?? "", url: (imagesPayload.ao[0].url ?? `data:application/pdf;base64,${imagesPayload.ao[0].base64}`) ?? "" });
            }
            if (files) {
                const options = files.ao && files.ao?.length > 0 ? files.ao.map(file => ({
                    label: file.filename ?? "",
                    value: (file.id ?? file.value) ?? "",
                    url: file.url ?? "",
                    base64: file.base64 ?? ""
                })) : [];
                setFilesOptions(options);
                setTrigger(trigger + 1);
            } else {
                const options = imagesPayload.ao && imagesPayload.ao?.length > 0 ? imagesPayload.ao.map(file => ({
                    label: file.filename ?? "",
                    value: (file.id ?? file.value) ?? "",
                    url: file.url ?? "",
                    base64: file.base64 ?? ""
                })) : [];
                setFilesOptions(options);
                setTrigger(trigger + 1);
            }

        }
        setTriggerSelect(triggerSelect + 1);
    };

    const renderPdfSection = () => {
        const showFullScreenModal = () => {
            return (
                <Modal isOpen={showModalViewer} onClose={() => setShowModalViewer(false)} fullScreen className="d-flex justify-content-center">
                    <iframe
                        key={trigger}
                        title="document" src={fileSelected?.url}
                        style={{ width: "100%", height: "90%" }}
                        frameBorder="0"
                        className="pdfRender "
                    ></iframe>
                </Modal>
            );
        };

        return (
            <div className="pr-2 backgroundPdf ">
                <div className="row py-3">
                    <div className="col-5 d-flex ">
                        <div onClick={() => setShowHistory(true)} className=" pointer align-self-center">
                            <ReactTooltip
                                anchorId="replace"
                                place="bottom"
                                content="Ver historial"
                                style={{ width: "100px", textAlign: "center", opacity: "0.8", zIndex: 50 }}
                            />
                            <IconRefresh className="text-black-50" id="replace"
                            />
                        </div>
                        {reportPayload?.files && reportPayload?.files.length > 0 &&
                            <Button
                                variant={selectedSection === "report" ? "primary" : "outline"}
                                className="mx-2"
                                onClick={() => uploadFilesFunction("report", true)}
                            >
                                Informe
                            </Button>
                        }
                        {imagesPayload?.od && imagesPayload?.od.length > 0 &&
                            <Button
                                variant={selectedSection === "od" ? "primary" : "outline"}
                                className="mx-2"
                                onClick={() => uploadFilesFunction("od", true)}
                            >
                                OD
                            </Button>
                        }
                        {imagesPayload?.oi && imagesPayload?.oi.length > 0 &&
                            <Button
                                variant={selectedSection === "oi" ? "primary" : "outline"}
                                className="mx-2"
                                onClick={() => uploadFilesFunction("oi", true)}
                            >
                                OI
                            </Button>
                        }
                        {imagesPayload?.ao && imagesPayload?.ao.length > 0 &&
                            <Button
                                variant={selectedSection === "ao" ? "primary" : "outline"}
                                className="mx-2"
                                onClick={() => uploadFilesFunction("ao", true)}
                            >
                                AO
                            </Button>
                        }
                    </div>
                    < div className="col-7 d-flex" key={triggerSelect}>
                        <div className="col">
                            {filesOptions.length > 0 &&
                                <Select
                                    key={triggerSelect + filesOptions.length}
                                    options={filesOptions}
                                    onChange={({ option }) => {
                                        setFileSelected(
                                            {
                                                ...fileSelected, url: (option.url !== "" ? option.url
                                                    : `data:application/pdf;base64,${option.base64}`
                                                ) ?? ""
                                            }
                                        );
                                    }}
                                    value={filesOptions.find(option => option.value === fileSelected.value)}
                                />
                            }
                        </div>
                        {fileSelected.url &&
                            <div className="col-1">
                                <Button
                                    variant="text"
                                    onClick={() => setShowModalViewer(true)}
                                >
                                    <IconArrowsMaximize />
                                </Button>
                            </div>
                        }


                    </div>
                </div>
                {fileSelected.url ?
                    <iframe
                        key={trigger}
                        title="document" src={fileSelected?.url}
                        style={{ width: "100%", height: "94%" }}
                        frameBorder="0"
                        className="pdfRender pb-4"
                    ></iframe>
                    : <h4 className=" w-100 text-muted d-flex align-items-center justify-content-center" style={{ height: "80%" }}>Seleccione un segmento y un documento para visualizarlo</h4>
                }
                {showFullScreenModal()}
            </div >
        );
    };

    const onSubmitSection = useDebouncedCallback((section) => {
        if (state?.tab === "reading") {
            if (section === "audio") dispatch(startLoading());

            dispatch(updateReading({
                userId,
                appId: appointmentId,
                eaccount: accountId,
                cluId,
                adxId,
                ...segment
            }, () => {
                if (section === "audio") {
                    dispatch(finishLoading());
                    fireSuccessAlert("Audio guardado con éxito", "Guardado exitosamente");
                    setIsAudioSaved(true);
                }
            }));
        } else {
            if (section === "pressureCurve") {
                setShowButtons(false);
            }
            dispatch(updateDiagnosticAids({
                userId,
                appId: appointmentId,
                eaccount: accountId,
                cluId,
                ...segment
            }, () => {
                if (section === "pressureCurve") {
                    getDiagnosticAidsMethod(true);
                }
            }
            ));
        }
        
    }, 2000);

    const onSubmitPressureCurve = (payload: PressureCurveBody[]) => {
        setShowButtons(false);
        dispatch(updateDiagnosticAids({
            userId,
            appId: appointmentId,
            eaccount: accountId,
            cluId,
            pressureCurve: payload
        }, () => {
            getDiagnosticAidsMethod(true);
        }
        ));
    };


    const onSubmitExam = useDebouncedCallback((data) => {
        dispatch(updateDiagnosticAids({
            userId,
            appId: appointmentId,
            eaccount: accountId,
            cluId,
            examDone: data as ExamDone
        }));
    }, 2000);

    const bodyForm = () => {

        const renderDilatationSection = () => {
            const onAddNewDilatation = () => {
                const now = new Date();
                const hour = now.getHours();
                const minutes = now.getMinutes();
                const time = dayjs().hour(hour).minute(minutes);
                const timeFormatted = time.format("HH:mm");
                setDilatationRegisterPayload([...dilatationRegisterPayload, {
                    articleId: undefined,
                    lotId: undefined,
                    quantity: undefined,
                    articleName: "",
                    lotName: "",
                    index: dilatationRegisterPayload.length + 1,
                    wmId: undefined,
                    time: timeFormatted
                }]);
            };

            const onChangeQuantity = (value: string, index: number,) => {
                const updated = dilatationRegisterPayload.map(item => {
                    if (item.index === index) {
                        return {
                            ...item,
                            quantity: +value
                        };
                    } else {
                        return item;
                    }
                });
                setDilatationRegisterPayload(updated);
                const formattedDilatations: DilatationItem[] = updated.map(dl => ({
                    id: dl?.id,
                    qty: dl.quantity as number,
                    artId: dl.articleId as number,
                    lotId: dl.lotId as number,
                    wmId: dl.wmId as number,
                    time: dl.time as string,
                }));
                setSegment((states)=>({...states,dilatationRegister:formattedDilatations}));
                onSubmitSection("dilatationRegister");
            };
            const onChangeTime = (value: string, index: number) => {
                const updated = dilatationRegisterPayload.map(item => {
                    if (item.index === index) {
                        return {
                            ...item,
                            time: value
                        };
                    } else {
                        return item;
                    }
                });
                setDilatationRegisterPayload(updated);
                const formattedDilatations: DilatationItem[] = updated.map(dl => ({
                    id: dl?.id,
                    qty: dl.quantity as number,
                    artId: dl.articleId as number,
                    lotId: dl.lotId as number,
                    wmId: dl.wmId as number,
                    time: dl.time as string,
                }));
                setSegment((states)=>({...states,dilatationRegister:formattedDilatations}));
                onSubmitSection("dilatationRegister");
            };

            const onChangeArticle = (option: SelectOption, index: number) => {
                const lot = articles.find(art => art.artId === option.value);

                const updated = dilatationRegisterPayload.map(item => {
                    if (item.index === index) {
                        return {
                            ...item,
                            articleId: +option.value,
                            lotId: lot?.lotId,
                            lotName: lot?.lotName,
                            wmId: lot?.wmId
                        };
                    } else {
                        return item;
                    }
                });

                const formattedDilatations: DilatationItem[] = updated.map(dl => ({
                    ...dl,
                    id: dl?.id,
                    qty: dl.quantity as number,
                    artId: dl.articleId as number,
                    lotId: dl.lotId as number,
                    wmId: dl.wmId as number,
                    time: dl.time as string,
                }));
                setSegment((states)=>({...states,dilatationRegister:formattedDilatations}));
                onSubmitSection("dilatationRegister");
                setDilatationRegisterPayload(updated);
            };

            const onDeleteDilatation = (index: number) => {
                const newOptions = dilatationRegisterPayload.filter(dil => dil.index !== index);
                setDilatationRegisterPayload(newOptions);
            };

            const renderDilatationItem = (dilatation: Example) => {
                const formattedArticles = articles && articles.map(article => ({
                    label: article.artDescription,
                    value: article.artId
                }));

                return (
                    <div key={dilatation.id || `key-${dilatation.index}`} className="preconsultation-quest mt-4">
                        <span className="text-secondary fw-bold nowrap me-2">Dilatación {dilatation.index}</span>
                        <div className="ms-2" style={{ width: 120 }}>
                            <label className="text-muted">Hora</label>
                            <TextField
                                variant="plain"
                                value={dilatation.time}
                                onChange={({ target }) => onChangeTime(target.value, dilatation.index)}
                                type="time"
                                disabled={isDisabledForm}
                            />
                        </div>
                        <div className="ms-4" style={{ width: 120 }}>
                            <label className="text-muted">No. gotas</label>
                            <TextField
                                variant="plain"
                                placeholder="No. Gotas"
                                value={Number(dilatation.quantity).toString()}
                                onChange={({ target }) => onChangeQuantity(target.value, dilatation.index,)}
                                type="number"
                                min={0}
                                disabled={isDisabledForm}
                                onKeyDown={(event) => {
                                    if (event.key === "-") {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                        <div className="ms-4 w-100">
                            <label className="text-muted">Medicamento</label>
                            <Select
                                variant="plain"
                                placeholder="Seleccionar artículo"
                                options={formattedArticles}
                                emptyOptionsLabel="No hay artículos disponibles"
                                value={formattedArticles.find(item => item.value === dilatation.articleId)}
                                name={`article-${dilatation.index}`}
                                onChange={({ option }) => onChangeArticle(option, dilatation.index)}
                                disabled={isDisabledForm}
                            />
                        </div>
                        <div className="ms-4" style={{ width: 300 }}>
                            <label className="text-muted">Lote</label>
                            <TextField
                                variant="plain"
                                placeholder="Lote"
                                value={dilatation.lotName}
                                readOnly
                            />
                        </div>
                        <div className="ms-4">
                            <IconCircleX
                                className="text-primary pointer"
                                size={20}
                                onClick={() => !isDisabledForm && onDeleteDilatation(dilatation.index)}
                            />
                        </div>
                    </div>
                );
            };
            return (
                <SectionCard className="" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Registro de dilatación</h5>
                    </div>
                    <hr className="lineCards" />
                    {dilatationRegisterPayload.length > 0 && dilatationRegisterPayload?.map((dilatation) => renderDilatationItem(dilatation))}
                    <Button
                        variant="text"
                        className="align-self-end pt-3"
                        startIcon={<IconCirclePlus />}
                        onClick={onAddNewDilatation}
                        disabled={isDisabledForm}
                    >
                        Agregar registro
                    </Button>
                </SectionCard>
            );
        };

        const renderImagesSection = () => {

            const handleOnDeleteItem = (laterality: "od" | "oi" | "ao", itemValue: number | string) => {
                const dataLaterality = imagesPayload[laterality] ?? [];
                const data: Images = {
                    ...imagesPayload,
                    [laterality]: dataLaterality.filter(lat => (lat.id ?? lat.value) !== itemValue)
                };
                setImagesPayload(data);
                setSegment((states)=>({...states,images:data}));
                onSubmitSection("images");
                if (dataLaterality.length === 0) {
                    setSelectedSection("");
                    setFileSelected({ value: "", url: "" });
                }
                if (fileSelected.value === itemValue) {
                    setFileSelected({ value: "", url: "" });
                }
            };

            const handleFileSelect = (laterality: "od" | "oi" | "ao") => {
                function generateNumericId(length: number) {
                    const min = Math.pow(10, length - 1);
                    const max = Math.pow(10, length) - 1;
                    return Math.floor(Math.random() * (max - min + 1) + min);
                }
                const fileId = `${laterality}-${generateId()}`;
                const fileInput = document.createElement("input");
                fileInput.type = "file";
                fileInput.id = fileId;
                fileInput.accept = ".pdf";
                fileInput.style.display = "none";

                document.body.appendChild(fileInput);
                fileInput.addEventListener("change", (event) => {
                    const selectedFile = (event.target as HTMLInputElement).files?.[0];
                    if (selectedFile) {
                        convertFileBase64({ allowedFormats: ["pdf"], files: (event.target as HTMLInputElement).files })
                            ?.then((file) => {
                                const dataLaterality = imagesPayload[laterality] ?? [];
                                const files: BodyFile = {
                                    filename: selectedFile.name,
                                    base64: file.data.base64,
                                    ext: "pdf",
                                    label: selectedFile.name,
                                    value: generateNumericId(8),
                                };
                                dataLaterality.push(files);
                                const data: Images = {
                                    ...imagesPayload,
                                    [laterality]: dataLaterality
                                };
                                setImagesPayload(data);
                                setSegment((states)=>({...states,images:data}));
                                onSubmitSection("images");
                                uploadFilesFunction(laterality, false);
                                setFileSelected(
                                    {
                                        value: files.value ?? "", url: `data:application/pdf;base64,${files.base64}`
                                    }
                                );
                            })
                            .catch(error => {
                                fireErrorAlert(error.message);
                            });
                    }
                });
                fileInput.click();

                fileInput.addEventListener("cancel", () => {
                    document.getElementById(fileId)?.remove();
                });
            };

            const readerOptions = doctorListOptions.map(option => ({
                label: option.name,
                value: option.id
            }));

            const handleReaderSelect = (value: number) => {
                const data: Report = {
                    ...reportPayload,
                    reader: value
                };
                setReportPayload(data);
                setSegment((states)=>({...states,report:data}));
                onSubmitSection("report");
            };
            return (
                <SectionCard className="" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Subir imágenes</h5>
                    </div>
                    <hr className="lineCards" />
                    <div className="d-flex flex-column">
                        <div className="row pb-3">
                            <div className="col-1 fw-bold text-secondary align-self-center">OD</div>
                            <div className="col-11">
                                <BadgeTextField
                                    placeholder="Cargar documento"
                                    bookmarks={(imagesPayload.od && imagesPayload.od?.length > 0) ? imagesPayload.od.map(i => ({ label: i.filename as string, value: Number(i.id ?? i.value) })) : []}
                                    onClick={(event) => {
                                        (!isDisabledForm && state?.tab !== "reading") && handleFileSelect("od");
                                    }}
                                    onDeleteItem={(item) => (!isDisabledForm && state?.tab !== "reading") && handleOnDeleteItem("od", item)}
                                    isErasable={(!isDisabledForm && state?.tab !== "reading")}
                                    fileType
                                    disabled={state?.tab === "reading" || isDisabledForm}
                                />
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-1 fw-bold text-secondary align-self-center">OI</div>
                            <div className="col-11">
                                <BadgeTextField
                                    placeholder="Cargar documento"
                                    bookmarks={(imagesPayload.oi && imagesPayload.oi?.length > 0) ? imagesPayload.oi.map(i => ({ label: i.filename as string, value: Number(i.id ?? i.value) })) : []}
                                    onClick={(event) => {
                                        (!isDisabledForm && state?.tab !== "reading") && handleFileSelect("oi");
                                    }}
                                    onDeleteItem={(item) => (!isDisabledForm && state?.tab !== "reading") && handleOnDeleteItem("oi", item)}
                                    isErasable={(!isDisabledForm && state?.tab !== "reading")}
                                    disabled={state?.tab === "reading" || isDisabledForm}
                                    fileType
                                />
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-1 fw-bold text-secondary align-self-center">AO</div>
                            <div className="col-11">
                                <BadgeTextField
                                    placeholder="Cargar documento"
                                    bookmarks={(imagesPayload.ao && imagesPayload.ao?.length > 0) ? imagesPayload.ao.map(i => ({ label: i.filename as string, value: Number(i.id ?? i.value) })) : []}
                                    onClick={(event) => {
                                        (!isDisabledForm && state?.tab !== "reading") && handleFileSelect("ao");
                                    }}
                                    onDeleteItem={(item) => (!isDisabledForm && state?.tab !== "reading") && handleOnDeleteItem("ao", item)}
                                    isErasable={(!isDisabledForm && state?.tab !== "reading")}
                                    disabled={state?.tab === "reading" || isDisabledForm}
                                    fileType
                                />
                            </div>
                        </div>
                        <div className={`${!reportRequired ? "sectionDisabled" : "row "}`}
                        >
                            <div className="col-3 fw-bold subtitleColor align-self-center">A lectura por:</div>
                            <div className="col">
                                <Select
                                    disabled={inabilityField.reader || isDisabledForm}
                                    options={readerOptions}
                                    value={readerOptions.find(option => option.value === reportPayload.reader)}
                                    onChange={({ option }) => {
                                        handleReaderSelect(option.value);
                                    }}
                                /></div>
                        </div>
                    </div>

                </SectionCard>
            );
        };

        const renderReportSection = () => {
            const handleOnDeleteItem = (itemValue: number | string) => {
                const dataLaterality = reportPayload.files ?? [];
                const data: Report = {
                    ...reportPayload,
                    files: dataLaterality.filter(lat => (lat.id ?? lat.value) !== itemValue)
                };
                setReportPayload(data);
                setSegment((states)=>({...states,report:data}));
                onSubmitSection("report");
                if (dataLaterality.length === 0) {
                    setSelectedSection("");
                    setFileSelected({ value: "", url: "" });
                }
                if (fileSelected.value === itemValue) {
                    setFileSelected({ value: "", url: "" });
                }
            };

            const handleFileSelect = () => {
                function generateNumericId(length: number) {
                    const min = Math.pow(10, length - 1);
                    const max = Math.pow(10, length) - 1;
                    return Math.floor(Math.random() * (max - min + 1) + min);
                }
                const id = `inf-${generateId()}`;

                const fileInput = document.createElement("input");
                fileInput.type = "file";
                fileInput.accept = ".pdf";
                fileInput.id = id;
                fileInput.style.display = "none";

                document.body.appendChild(fileInput);
                fileInput.addEventListener("change", (event) => {
                    const selectedFile = (event.target as HTMLInputElement).files?.[0];
                    if (selectedFile) {
                        convertFileBase64({ allowedFormats: ["pdf"], files: (event.target as HTMLInputElement).files })
                            ?.then((file) => {
                                const dataReport = reportPayload.files ?? [];
                                const files: BodyFile = {
                                    filename: selectedFile.name,
                                    base64: file.data.base64,
                                    ext: "pdf",
                                    label: selectedFile.name,
                                    value: generateNumericId(8),
                                };
                                dataReport.push(files);
                                const data: Report = {
                                    ...reportPayload,
                                    files: dataReport
                                };
                                setReportPayload(data);
                                setSegment((states)=>({...states,report:data}));
                                onSubmitSection("report");
                                uploadFilesFunction("report", false);
                                setFileSelected(
                                    {
                                        value: files.value ?? "", url: `data:application/pdf;base64,${files.base64}`
                                    }
                                );
                            })
                            .catch(error => {
                                fireErrorAlert(error.message);
                            });
                    }
                    document.body.removeChild(fileInput);
                });
                fileInput.click();

                fileInput.addEventListener("cancel", () => {
                    document.getElementById(id)?.remove();
                });
            };

            const handleNeedInterpretationCheck = (value: boolean) => {
                const data: Report = {
                    ...reportPayload,
                    needInterpretation: value
                };
                setReportPayload(data);
                setSegment((states)=>({...states,report:data}));
                onSubmitSection("report");
            };
            return (
                <SectionCard className="" cardAidStyle height="100%" >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Subir informe</h5>
                    </div>
                    <hr className="lineCards" />
                    <div className="gap-3 ">
                        <div className="row pb-3">
                            <div className="col-12">
                                <BadgeTextField
                                    placeholder="Cargar documento"
                                    disabled={inabilityField.report || isDisabledForm}
                                    bookmarks={(reportPayload.files && reportPayload.files?.length > 0) ? reportPayload.files.map(i => ({ label: i.filename as string, value: Number(i.id ?? i.value) })) : []}
                                    onClick={(event) => {
                                        (!inabilityField.report && !isDisabledForm) &&
                                            handleFileSelect();
                                    }}
                                    onDeleteItem={(item) => !isDisabledForm && handleOnDeleteItem(item)}
                                    isErasable
                                    fileType
                                /></div>
                        </div>
                        <div className="row pt-4">
                            <div className="col gap-2 fw-bold text-secondary align-self-center">
                                <Checkbox
                                    className="text-muted fw-normal"
                                    disabled={inabilityField.needInterpretation || isDisabledForm}
                                    label="Interpretación médica en siguiente consulta"
                                    onChange={({ target }) => handleNeedInterpretationCheck(target.checked)}
                                    checked={reportPayload.needInterpretation}
                                />
                            </div>
                        </div>
                    </div>

                </SectionCard>
            );
        };

        const renderCommentSection = () => {

            const onChangeComment = (value: string, field: string) => {
                const data: ExamDone = { ...examDonePayload, [field]: value };
                setExamDonePayload(data);
                setSegment((states)=>({...states,examDone:data}));
                onSubmitSection("examDone");
            };
            const onChangeVariables = (laterality: "oi" | "od" | null, value: string | number, field: string, variable: Variables) => {

                const affectsFunction = (valueToCalc: number, listData: ExamDone) => {
                    const affectsValue = variable.relDboVar?.find(rel => rel.laterality === laterality)?.affects;
                    const temp = [...affects];
                    let indexVariableAffects: {
                        id: number | null, laterality: string | null,
                        variableItem: Variables | null;
                    } = {
                        id: null, laterality: null, variableItem: null
                    };
                    const variableListPayload = listData.variables && listData.variables?.length > 0 ? listData.variables : [];
                    if (affectsValue) {
                        const correction = correctionValue(valueToCalc);
                        if (correction === "") {
                            setTooltipOfVariable("El valor ingresado debe ser entre el rango de 445 a 645");
                        } else {
                            setTooltipOfVariable("");
                        }
                        const variableList = variables && variables?.length > 0 ? variables : [];
                        variableList.forEach((variableItem) => {
                            variableItem.relDboVar?.forEach((rel) => {
                                if (rel.id === affectsValue) {
                                    indexVariableAffects = { id: variableItem.id, laterality: rel.laterality, variableItem: variableItem };
                                }
                            });
                        });

                        const findVariable = variableListPayload.find(vari => vari.id === indexVariableAffects.id);

                        if (findVariable) {
                            const findField = findVariable.fields && findVariable?.fields.find(field => field.laterality === indexVariableAffects.laterality);
                            if (findField) {
                                findField.value = correction;
                            } else {
                                findVariable.fields && findVariable.fields.push({
                                    laterality: indexVariableAffects.laterality, value: correction
                                });
                            }
                            const dataVar: ExamDone = {
                                ...listData,
                                [field]: variableListPayload
                            };

                            setExamDonePayload(dataVar);
                            setSegment((states)=>({...states,examDone:dataVar}));
                            onSubmitSection("examDone");

                        }
                        else {
                            const dataVar: ExamDone = {
                                ...listData,
                                [field]: [
                                    ...(listData.variables || []),
                                    {
                                        ...indexVariableAffects.variableItem,
                                        fields: [{
                                            laterality,
                                            value: correction,
                                        }]
                                    }]
                            };
                            setExamDonePayload(dataVar);
                            setSegment((states)=>({...states,examDone:dataVar}));
                            onSubmitSection("examDone");
                        }
                        temp.push({ affects: affectsValue, value: Number(correction) });
                        setAffects(temp);
                    }
                };

                const variableList = examDonePayload.variables && examDonePayload.variables?.length > 0 ? examDonePayload.variables : [];
                if (variableList.some(vari => vari.id === variable.id)) {
                    const tempVariable = variableList.find(vari => vari.id === variable.id);
                    if ((tempVariable && tempVariable?.fields && tempVariable?.fields?.length > 0) && tempVariable.fields.some(field => field.laterality === laterality)) {
                        const tempField = tempVariable.fields.find(field => field.laterality === laterality);
                        if (tempField) {


                            tempField.value = value;
                            const data: ExamDone = {
                                ...examDonePayload,
                                [field]: variableList
                            };
                            setExamDonePayload(data);
                            setSegment((states)=>({...states,examDone:data}));
                            onSubmitSection("examDone");

                            affectsFunction(Number(value), data);

                        }
                    } else {
                        if (tempVariable) {
                            tempVariable.fields = [...(tempVariable.fields || []), { laterality, value }];
                            const data: ExamDone = {
                                ...examDonePayload,
                                [field]: variableList
                            };
                            setExamDonePayload(data);
                            setSegment((states)=>({...states,examDone:data}));
                            onSubmitSection("examDone");

                            affectsFunction(Number(value), data);

                        }
                    }
                } else {
                    const data: ExamDone = {
                        ...examDonePayload,
                        [field]: [
                            ...(examDonePayload.variables || []),
                            {
                                ...variable,
                                fields: [{
                                    laterality,
                                    value,
                                }]
                            }]
                    };
                    setExamDonePayload(data);
                    setSegment((states)=>({...states,examDone:data}));
                    onSubmitSection("examDone");
                    affectsFunction(Number(value), data);
                }
            };
            const fieldValueInput = (laterality: "oi" | "od" | null, variable: Variables) => {
                const variableFind = examDonePayload.variables && examDonePayload.variables.find(vari => vari.id === variable.id);
                const field = (variableFind?.fields && variableFind?.fields.find(field => field.laterality === laterality))?.value;
                return field;
            };
            const fieldValueSelect = (laterality: "oi" | "od" | null, variable: Variables, optionsSelect: SelectOption[]) => {
                const variableFind = examDonePayload.variables && examDonePayload.variables.find(vari => vari.id === variable.id);
                const field = (variableFind?.fields && variableFind?.fields.find(field => field.laterality === laterality));
                const selectOptionValue: SelectOption = {
                    label: (optionsSelect && optionsSelect.find(option => option.value === field?.value)?.label) ?? "",
                    value: field?.value ?? ""
                };
                return selectOptionValue;
            };

            const isDisabledField = (laterality: "oi" | "od" | null, variable: Variables) => {
                let isDisabled = false;
                if (variable.relDboVar) {
                    isDisabled = Boolean(variable.relDboVar.find((rel) => rel.laterality === laterality)?.disabled);
                }
                return isDisabled;
            };

            const showLaterality = variables && variables?.length > 0 && variables.some((variable) =>
                variable.od || variable.oi
            );
            return (
                <SectionCard className="" cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Realización del examen</h5>
                    </div>
                    <hr className="lineCards" />
                    <div className="row">
                        <div className="col pb-4">
                            <span className="subtitleColor fw-bold"> Notas del examen</span>
                            <textarea
                                name="comment"
                                className="form-control no-resize w-100"
                                onChange={({ target }) => onChangeComment(target.value, target.name)}
                                value={examDonePayload?.comment}
                                style={{ height: "100%" }}
                                disabled={state?.tab === "reading" || isDisabledForm}
                            />
                        </div>
                        {variables && variables?.length > 0 &&
                            <div className="col d-grid gap-2">
                                <div className="row">
                                    <div className="col-4">
                                    </div>
                                    {
                                        showLaterality && <>
                                            <div className="col-3 text-center">
                                                <span className="text-secondary fw-bold text-center">OD</span>
                                            </div>
                                            <div className="col-3 text-center">
                                                <span className="text-secondary fw-bold ">OI</span>
                                            </div>
                                        </>
                                    }
                                    <div className="col-2">
                                        <label className="text-secondary fw-bold"></label>
                                    </div>
                                </div>
                                {variables?.length > 0 && variables?.map((variable) => {
                                    const optionsSelect = variable.options.map(option => ({
                                        label: option.name,
                                        value: option.id
                                    }));
                                    return (
                                        !variable.relDboVar?.some(x => x.prefix === "method") ?
                                            (variable.oi || variable.od) ?
                                                <div className="row" key={variable.id}>
                                                    <div className="col-4 align-self-center">
                                                        <label className="text-label ">{variable.name}</label>
                                                    </div>
                                                    {variable.type === "fieldtext" ?
                                                        <>
                                                            <div className="col-3">
                                                                <input
                                                                    id={"od"}
                                                                    disabled={!variable.od || isDisabledForm || isDisabledField("od", variable)}
                                                                    type="text"
                                                                    className={"form-control form-control-sm"}
                                                                    value={fieldValueInput("od", variable)}
                                                                    onChange={({ target }) => {

                                                                        if (variable.name === "Grosor corneal" && target.id === "od") {
                                                                            if ((Number(target.value) >= 445 && Number(target.value) <= 645) || target.value === "") {
                                                                                target.classList.remove("border-danger");
                                                                            } else {
                                                                                target.classList.add("border-danger");
                                                                            }
                                                                        }
                                                                        onChangeVariables("od", target.value, "variables", variable);
                                                                    }}
                                                                />
                                                                <ReactTooltip
                                                                    anchorId={"od"}
                                                                    place="top"
                                                                    content={tooltipOfVariable}
                                                                    style={{ width: "200px", textAlign: "center", opacity: "0.6" }}
                                                                />
                                                            </div>
                                                            <div className="col-3">
                                                                <input
                                                                    id={"oi"}
                                                                    disabled={!variable.oi || isDisabledForm || isDisabledField("oi", variable)}
                                                                    type="text"
                                                                    className={"form-control form-control-sm "}
                                                                    value={fieldValueInput("oi", variable)}
                                                                    onChange={({ target }) => {
                                                                        if (variable.name === "Grosor corneal" && target.id === "oi") {
                                                                            if ((Number(target.value) >= 445 && Number(target.value) <= 645) || target.value === "") {
                                                                                target.classList.remove("border-danger");
                                                                            } else {
                                                                                target.classList.add("border-danger");
                                                                            }
                                                                        }
                                                                        onChangeVariables("oi", target.value, "variables", variable);
                                                                    }}
                                                                />
                                                                <ReactTooltip
                                                                    anchorId={"oi"}
                                                                    place="top"
                                                                    content={tooltipOfVariable}
                                                                    style={{ width: "200px", textAlign: "center", opacity: "0.6" }}
                                                                />
                                                            </div>

                                                        </>
                                                        :
                                                        <>
                                                            <div className="col-3">
                                                                <Select
                                                                    style={{ width: "100%" }} 
                                                                    disabled={!variable.od || isDisabledForm || isDisabledField("od", variable)}
                                                                    options={optionsSelect}
                                                                    onChange={({ option }) => onChangeVariables("od", option.value, "variables", variable)}
                                                                    value={fieldValueSelect("od", variable, optionsSelect)}
                                                                />
                                                            </div>
                                                            <div className="col-3">
                                                                <Select
                                                                    style={{ width: "100%" }} 
                                                                    options={optionsSelect}
                                                                    disabled={!variable.oi || isDisabledForm || isDisabledField("oi", variable)}
                                                                    onChange={({ option }) => onChangeVariables("oi", option.value, "variables", variable)}
                                                                    value={fieldValueSelect("oi", variable, optionsSelect)}
                                                                />
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="col-2 align-self-center">
                                                        <label className="text-muted align-self-center">{variable.unit ?? ""}</label>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row" key={variable.id}>
                                                    <div className="col-4 align-self-center">
                                                        <label className="text-label ">{variable.name}</label>
                                                    </div>
                                                    {variable.type === "fieldtext" ?
                                                        <div className="col">
                                                            <div className="col">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm "
                                                                    onChange={({ target }) => onChangeVariables(null, target.value, "variables", variable)}
                                                                    value={fieldValueInput(null, variable)}
                                                                    disabled={isDisabledForm || isDisabledField(null, variable)}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col">
                                                            <Select
                                                                style={{ width: "100%" }} 
                                                                options={optionsSelect}
                                                                disabled={isDisabledForm || isDisabledField(null, variable)}
                                                                onChange={({ option }) => onChangeVariables(null, option.value, "variables", variable)}
                                                                value={fieldValueSelect(null, variable, optionsSelect)}
                                                            />
                                                        </div>
                                                    }
                                                    <div className="col-2 align-self-center">
                                                        <label className="text-muted align-self-center">{variable.unit ?? ""}</label>
                                                    </div>
                                                </div>
                                            :
                                            <></>
                                    );
                                })}
                                <hr className="lineCards" />
                            </div>
                        }
                    </div>

                </SectionCard>
            );
        };

        const renderPressureCurve = () => {
            const handleAddProvocativeEvidence = () => {
                const lastVar = provocativeItems[provocativeItems.length - 1];
                if (lastVar) {
                    if (lastVar.od || lastVar.oi) {
                        setProvocativeItems([
                            ...provocativeItems,
                            {
                                order: provocativeItems.length + 1,
                                time: dayjs().format("HH:mm:ss"),
                                identifier: new Date().getTime().toString() + 1,
                                isDisabled: false
                            }
                        ]);
                    } else {
                        fireWarningAlert("Por favor llenar al menos un campo", "Intenta de nuevo");
                    }
                }
            };

            const handleChangeEvidence = (id: string | number | undefined, eye: "od" | "oi" | "time", value: string) => {

                const optionsTemp = provocativeItems.map(item => {
                    if ((item.id ?? item?.identifier) === id) {
                        if (eye === "time") {
                            return {
                                ...item,
                                [eye]: value
                            };
                        } else {
                            return {
                                ...item,
                                [eye]: value === "" ? "" : value
                            };
                        }
                    }
                    return item;
                });

                const options = optionsTemp.sort((a, b) => {
                    if (a?.time && b?.time) {
                        if (a?.time < b?.time) {
                            return -1;
                        }
                        if (a?.time > b?.time) {
                            return 1;
                        }
                    }
                    return 0;
                });
                setProvocativeItems(options);

                function calcMedia(arr: number[]) {
                    if (arr.length > 0) {
                        const sum = arr.reduce((acc, val) => acc + val, 0);
                        return sum / arr.length;
                    }
                }

                function calcDesv(arr: number[]) {
                    const media = calcMedia(arr);
                    if (media) {
                        const sumSquaredDiffs = arr.reduce((acc, val) => acc + Math.pow(val - media, 2), 0);
                        const variance = sumSquaredDiffs / arr.length;
                        const desviacionEstandar = Math.sqrt(variance);
                        return desviacionEstandar;
                    }
                }

                function variabilityMethod(examDone: ExamDone) {

                    const settingAndSendInfo = (data: ExamDone) => {
                        setExamDonePayload(data as ExamDone);
                        onSubmitExam(data as ExamDone);
                    };

                    const variableStructureVariability = variables.find((v) =>
                        v?.relDboVar && v?.relDboVar.find((rel) => rel.prefix === "variabilityOd")
                    );
                    const calculation = calcDesv(options ? options.map((pre) => Number(pre[eye])) : []);
                    const variableTemp = examDone.variables && (
                        examDone.variables.find((v) => v.id === variableStructureVariability?.id));

                    if (variableTemp) {
                        const exist = variableTemp.fields && variableTemp.fields.some(f => f.laterality === String([eye]));

                        if (exist) {
                            const variableX = variableTemp.fields && variableTemp.fields.map((f) => f.laterality === String([eye]) ? { ...f, value: calculation } : f);

                            if (examDone.variables) {
                                const data = {
                                    ...examDone,
                                    variables: examDone.variables.map(x => x.id === variableStructureVariability?.id ? { ...variableTemp, fields: variableX } : x)
                                };

                                settingAndSendInfo(data as ExamDone);
                            }
                        } else {
                            if (variableTemp.fields) {

                                const tempArr = [...variableTemp.fields, { laterality: eye, value: calculation }];
                                if (examDone.variables) {
                                    const data = {
                                        ...examDone,
                                        variables: examDone.variables.map(x => x.id === variableStructureVariability?.id ? { ...variableTemp, fields: tempArr } : x)
                                    };

                                    settingAndSendInfo(data as ExamDone);
                                }
                            }
                        }
                    } else {
                        if (examDone.variables) {
                            const data = {
                                ...examDone,
                                variables: [...examDone.variables, {
                                    ...variableStructureVariability, fields: [
                                        { laterality: eye, value: calculation }
                                    ]
                                }]
                            };
                            settingAndSendInfo(data as ExamDone);

                        } else {
                            const data: ExamDone = {
                                ...examDone,
                                variables: [
                                    {
                                        ...variableStructureVariability, fields: [
                                            { laterality: eye, value: calculation }
                                        ]
                                    }
                                ]
                            };
                            settingAndSendInfo(data as ExamDone);
                        }
                    }
                }
                function diurnalMethod() {


                    const settingAndSendInfo = (data: ExamDone) => {
                        variabilityMethod(data);
                    };

                    const calculation = calcMedia(options ? options.map((pre) => Number(pre[eye])) : []);

                    const variableStructure = variables.find((v) =>
                        v?.relDboVar && v?.relDboVar.find((rel) => rel.prefix === "diurnalMeanOd")
                    );
                    const variableTemp = examDonePayload.variables && (
                        examDonePayload.variables.find((v) => v.id === variableStructure?.id));

                    if (variableTemp) {

                        const exist = variableTemp.fields && variableTemp.fields.some(f => f.laterality === String([eye]));

                        if (exist) {
                            const variableX = variableTemp.fields && variableTemp.fields.map((f) => f.laterality === String([eye]) ? { ...f, value: calculation } : f);

                            if (examDonePayload.variables) {
                                const data = {
                                    ...examDonePayload,
                                    variables: examDonePayload.variables.map(x => x.id === variableStructure?.id ? { ...variableTemp, fields: variableX } : x)
                                };
                                settingAndSendInfo(data as ExamDone);
                            }
                        } else {
                            if (variableTemp.fields) {
                                const tempArr = [...variableTemp.fields, { laterality: eye, value: calculation }];
                                if (examDonePayload.variables) {
                                    const data = {
                                        ...examDonePayload,
                                        variables: examDonePayload.variables.map(x => x.id === variableStructure?.id ? { ...variableTemp, fields: tempArr } : x)
                                    };
                                    settingAndSendInfo(data as ExamDone);
                                }
                            }
                        }
                    } else {
                        if (examDonePayload.variables) {
                            const data = {
                                ...examDonePayload,
                                variables: [...examDonePayload.variables, {
                                    ...variableStructure, fields: [
                                        { laterality: eye, value: calculation }
                                    ]
                                }]
                            };
                            settingAndSendInfo(data as ExamDone);
                        } else {
                            const data: ExamDone = {
                                ...examDonePayload,
                                variables: [
                                    {
                                        ...variableStructure, fields: [
                                            { laterality: String(eye), value: calculation }
                                        ]
                                    }
                                ]
                            };
                            settingAndSendInfo(data as ExamDone);
                        }
                    }
                }
                if (eye !== "time") {
                    diurnalMethod();
                }

            };

            const handleEditCurve = (item: PressureCurveBody, action: "edit" | "send") => {
                if (action === "edit") {
                    if (provocativeItems.some(item => item.isDisabled === false)) {
                        fireWarningAlert("Debes guardar el registro actual para poder editar otro", "Intenta de nuevo");
                    } else {
                        const options = provocativeItems.map(vari => (vari.id ?? vari.identifier) === (item.id ?? item.identifier) ? { ...vari, isDisabled: false } : vari);
                        setProvocativeItems(options);
                    }

                } else {
                    const options = provocativeItems.map(vari => (vari.id ?? vari.identifier) === (item.id ?? item.identifier) ? { ...vari, isDisabled: true } : vari);
                    const optionToSend = provocativeItems.find(vari => (vari.id ?? vari.identifier) === (item.id ?? item.identifier));
                    setProvocativeItems(options);
                    onSubmitPressureCurve([optionToSend] as PressureCurveBody[]);
                }
            };

            const handleDeleteEvidence = async (item: PressureCurveBody) => {
                const filteredItems = provocativeItems.filter(pi => (pi?.id ?? pi?.identifier) !== (item?.id ?? item?.identifier));
                const itemDeleted = provocativeItems.filter(pi => (pi?.id ?? pi?.identifier) === (item?.id ?? item?.identifier));

                const handleChangeEvidence = () => {

                    function calcMedia(arr: number[]) {
                        if (arr.length > 0) {
                            const sum = arr.reduce((acc, val) => acc + val, 0);
                            return sum / arr.length;
                        }
                    }

                    function calcDesv(arr: number[]) {
                        const media = calcMedia(arr);
                        if (media) {
                            const sumSquaredDiffs = arr.reduce((acc, val) => acc + Math.pow(val - media, 2), 0);
                            const variance = sumSquaredDiffs / arr.length;
                            const desviacionEstandar = Math.sqrt(variance);
                            return desviacionEstandar;
                        }
                    }

                    function updateVariables() {
                        const settingAndSendInfo = (data: ExamDone) => {
                            setExamDonePayload(data as ExamDone);
                            onSubmitExam(data as ExamDone);
                        };
                        const variableStructure = variables.find((v) =>
                            v?.relDboVar && v?.relDboVar.find((rel) => rel.prefix === "diurnalMeanOd")
                        );
                        const variableStructureVariability = variables.find((v) =>
                            v?.relDboVar && v?.relDboVar.find((rel) => rel.prefix === "variabilityOd")
                        );
                        const variableTempDiurnal = examDonePayload.variables && (
                            examDonePayload.variables.find((v) => v.id === variableStructure?.id));
                        const variableTempVariability = examDonePayload.variables && (
                            examDonePayload.variables.find((v) => v.id === variableStructureVariability?.id));

                        if (variableTempDiurnal && variableTempVariability) {
                            const variableDiurnal: FieldBody[] =
                                variableTempDiurnal.fields ? variableTempDiurnal.fields.map((f) => f.laterality === "od" ? { ...f, value: calcMedia(filteredItems.map((pre) => Number(pre.od))) } :
                                    { ...f, value: calcMedia(filteredItems.map((pre) => Number(pre.oi))) }
                                ) : [];
                            const variableVariability: FieldBody[] =
                                variableTempVariability.fields ? variableTempVariability.fields.map((f) => f.laterality === "od" ? { ...f, value: calcDesv(filteredItems.map((pre) => Number(pre.od))) } :
                                    { ...f, value: calcDesv(filteredItems.map((pre) => Number(pre.oi))) }
                                ) : [];
                            if (filteredItems && examDonePayload.variables) {
                                const dataDiurnal = {
                                    ...examDonePayload,
                                    variables: examDonePayload.variables.map(x => x.id === variableStructure?.id ? { ...variableTempDiurnal, fields: variableDiurnal } : x)
                                };
                                const data = {
                                    ...dataDiurnal,
                                    variables: dataDiurnal.variables.map(x => x.id === variableStructureVariability?.id ? { ...variableTempVariability, fields: variableVariability } : x)
                                };
                                settingAndSendInfo(data as ExamDone);
                            }
                        }

                    }

                    updateVariables();
                };

                setProvocativeItems(filteredItems);

                if (item?.id ?? item?.identifier) {
                    if (item?.id) {
                        dispatch(deletePressure({
                            userId, appId: appointmentId,
                            eaccount: accountId,
                            cluId,
                            pressureCurve: itemDeleted
                        }));
                    }
                    handleChangeEvidence();
                    setTriggerChart(triggerChart + 1);
                    return;
                }

            };

            const isDisabledField = (laterality: "oi" | "od" | null, variable: Variables | undefined) => {
                let isDisabled = false;
                if (variable && variable.relDboVar) {
                    isDisabled = Boolean(variable.relDboVar.find((rel) => rel.laterality === laterality)?.disabled);
                }
                return isDisabled;
            };

            const onChangeVariables = (laterality: "oi" | "od" | null, value: string | number, field: string, variable: Variables | undefined) => {

                const affectsFunction = (valueToCalc: number, listData: ExamDone) => {
                    const affectsValue = variable && variable.relDboVar?.find(rel => rel.laterality === laterality)?.affects;
                    const temp = [...affects];
                    let indexVariableAffects: {
                        id: number | null, laterality: string | null,
                        variableItem: Variables | null;
                    } = {
                        id: null, laterality: null, variableItem: null
                    };
                    const variableListPayload = listData.variables && listData.variables?.length > 0 ? listData.variables : [];
                    if (affectsValue) {
                        const correction = correctionValue(valueToCalc);
                        if (correction === "") {
                            setTooltipOfVariable("El valor ingresado debe ser entre el rango de 445 a 645");
                        } else {
                            setTooltipOfVariable("");
                        }
                        const variableList = variables && variables?.length > 0 ? variables : [];
                        variableList.forEach((variableItem) => {
                            variableItem.relDboVar?.forEach((rel) => {
                                if (rel.id === affectsValue) {
                                    indexVariableAffects = { id: variableItem.id, laterality: rel.laterality, variableItem: variableItem };
                                }
                            });
                        });

                        const findVariable = variableListPayload.find(vari => vari.id === indexVariableAffects.id);

                        if (findVariable) {
                            const findField = findVariable.fields && findVariable?.fields.find(field => field.laterality === indexVariableAffects.laterality);
                            if (findField) {
                                findField.value = correction;
                            } else {
                                findVariable.fields && findVariable.fields.push({
                                    laterality: indexVariableAffects.laterality, value: correction
                                });
                            }
                            const dataVar: ExamDone = {
                                ...listData,
                                [field]: variableListPayload
                            };

                            setExamDonePayload(dataVar);
                            setSegment((states)=>({...states,examDone:dataVar}));
                            onSubmitSection("examDone");

                        }
                        else {
                            const dataVar: ExamDone = {
                                ...listData,
                                [field]: [
                                    ...(listData.variables || []),
                                    {
                                        ...indexVariableAffects.variableItem,
                                        fields: [{
                                            laterality,
                                            value: correction,
                                        }]
                                    }]
                            };
                            setExamDonePayload(dataVar);
                            setSegment((states)=>({...states,examDone:dataVar}));
                            onSubmitSection("examDone");
                        }
                        temp.push({ affects: affectsValue, value: Number(correction) });
                        setAffects(temp);
                    }
                };

                const variableList = examDonePayload.variables && examDonePayload.variables?.length > 0 ? examDonePayload.variables : [];
                if (variable && variableList.some(vari => vari.id === variable.id)) {
                    const tempVariable = variableList.find(vari => vari.id === variable.id);
                    if ((tempVariable && tempVariable?.fields && tempVariable?.fields?.length > 0) && tempVariable.fields.some(field => field.laterality === laterality)) {
                        const tempField = tempVariable.fields.find(field => field.laterality === laterality);
                        if (tempField) {


                            tempField.value = value;
                            const data: ExamDone = {
                                ...examDonePayload,
                                [field]: variableList
                            };
                            setExamDonePayload(data);
                            setSegment((states)=>({...states,examDone:data}));
                            onSubmitSection("examDone");

                            affectsFunction(Number(value), data);

                        }
                    } else {
                        if (tempVariable) {
                            tempVariable.fields = [...(tempVariable.fields || []), { laterality, value }];
                            const data: ExamDone = {
                                ...examDonePayload,
                                [field]: variableList
                            };
                            setExamDonePayload(data);
                            setSegment((states)=>({...states,examDone:data}));
                            onSubmitSection("examDone");

                            affectsFunction(Number(value), data);

                        }
                    }
                } else {
                    const data: ExamDone = {
                        ...examDonePayload,
                        [field]: [
                            ...(examDonePayload.variables || []),
                            {
                                ...variable,
                                fields: [{
                                    laterality,
                                    value,
                                }]
                            }]
                    };
                    setExamDonePayload(data);
                    setSegment((states)=>({...states,examDone:data}));
                    onSubmitSection("examDone");
                    affectsFunction(Number(value), data);
                }
            };

            const fieldValueSelect = (laterality: "oi" | "od" | null, variable: Variables | undefined, optionsSelect: SelectOption[] | undefined) => {
                const variableFind = variable && examDonePayload.variables && examDonePayload.variables.find(vari => vari.id === variable.id);
                const field = (variableFind?.fields && variableFind?.fields.find(field => field.laterality === laterality));
                const selectOptionValue: SelectOption = {
                    label: (optionsSelect && optionsSelect.find(option => option.value === field?.value)?.label) ?? "",
                    value: field?.value ?? ""
                };
                return selectOptionValue;
            };

            const methodVariable = variables?.find(x => x.relDboVar?.find(v => v.prefix === "method"));

            const optionsSelect = methodVariable?.options.map(option => ({
                label: option.name,
                value: option.id
            }));
            return (
                <SectionCard className="" cardAidStyle  >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Curva de presión</h5>
                        <div className="d-flex mb-2">
                            <div className="align-self-end d-flex ">
                                <h5 className="fw-bold text-secondary mx-2 align-self-end">Método</h5>
                                <div >
                                    <Select
                                        options={optionsSelect}
                                        disabled={isDisabledForm || isDisabledField(null, methodVariable)}
                                        onChange={({ option }) => onChangeVariables(null, option.value, "variables", methodVariable)}
                                        value={fieldValueSelect(null, methodVariable, optionsSelect)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="lineCards" />
                    <div className="row px-3 pb-2">
                        <div className="col-6 d-flex flex-column ps-4">
                            <div style={{ maxHeight: 228, overflow: "auto" }}>
                                <table className="w-100 mt-4">
                                    <thead>
                                        <tr>
                                            <td className="text-secondary col-4" >Hora</td>
                                            <td className="text-secondary px-3 col-3">OD</td>
                                            <td className="text-secondary px-3 col-3">OI</td>
                                            <td className="text-secondary col-1" align="right">&nbsp;</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {provocativeItems.map(item => {
                                            const isDisabled = item.isDisabled ?? true;
                                            return (
                                                <tr key={item.id}>
                                                    <td className="pe-2 py-2 col-3">
                                                        <input
                                                            style={{ width: "110px" }}
                                                            className="form-control form-control-sm"
                                                            value={item.time as string}
                                                            onChange={({ target }) => handleChangeEvidence(item.id ?? item?.identifier, "time", target.value)}
                                                            type="time"
                                                            disabled={isDisabledForm || isDisabled}
                                                        />
                                                    </td>
                                                    <td className="px-2 py-2  col-3">
                                                        <input
                                                            disabled={isDisabledForm || isDisabled}
                                                            type="number"
                                                            className="form-control form-control-sm"
                                                            value={item.od}
                                                            onChange={({ target }) => handleChangeEvidence(item.id ?? item?.identifier, "od", target.value.toString())}
                                                            onKeyDown={(event) => {
                                                                if (event.key === "-") {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="px-2 py-2 col-3">
                                                        <input
                                                            disabled={isDisabledForm || isDisabled}
                                                            type="number"
                                                            className="form-control form-control-sm"
                                                            value={item.oi}
                                                            onChange={({ target }) => handleChangeEvidence(item.id ?? item?.identifier, "oi", target.value.toString())}
                                                            onKeyDown={(event) => {
                                                                if (event.key === "-") {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td align="right" className="col-1">
                                                        {isDisabled ?
                                                            showButtons &&
                                                            <div
                                                            >
                                                                <ReactTooltip
                                                                    anchorId={item.id + "edit"}
                                                                    place="top"
                                                                    content="Editar"
                                                                    style={{ width: "100px", textAlign: "center", opacity: "0.8", zIndex: 50 }}
                                                                />
                                                                <IconEdit
                                                                    className="pointer text-black-50"
                                                                    style={{ width: 20 }}
                                                                    id={item.id + "edit"}
                                                                    onClick={() => handleEditCurve(item, "edit")}
                                                                />
                                                            </div>
                                                            :
                                                            showButtons &&
                                                            <div
                                                            >
                                                                <ReactTooltip
                                                                    anchorId={item.id + "save"}
                                                                    place="top"
                                                                    content="Guardar"
                                                                    style={{ width: "100px", textAlign: "center", opacity: "0.8", zIndex: 50 }}
                                                                />
                                                                <IconChecklist
                                                                    className="pointer text-black-50"
                                                                    style={{ width: 20 }}
                                                                    id={item.id + "save"}
                                                                    onClick={() => handleEditCurve(item, "send")}
                                                                />

                                                            </div>
                                                        }
                                                    </td>
                                                    <td align="right" className="col-1">
                                                        {(provocativeItems.length > 1 && isDisabled &&
                                                            showButtons) &&
                                                            (<div>
                                                                <ReactTooltip
                                                                    anchorId={item.id + "delete"}
                                                                    place="top"
                                                                    content="Eliminar fila"
                                                                    style={{ width: "100px", textAlign: "center", opacity: "0.8", zIndex: 50 }}
                                                                />
                                                                <IconCircleX
                                                                    id={item.id + "delete"}
                                                                    className="pointer text-black-50"
                                                                    style={{ width: 20 }}
                                                                    onClick={() => handleDeleteEvidence(item)}
                                                                />
                                                            </div>
                                                            )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-100 d-flex flex-column mt-4">
                                {showButtons &&
                                    <Button
                                        variant="text"
                                        disabled={isDisabledForm}
                                        endIcon={<IconCirclePlus />}
                                        className="align-self-end"
                                        onClick={() => handleAddProvocativeEvidence()}
                                    >
                                        Añadir
                                    </Button>
                                }
                            </div>
                            <div className="flex-grow-1"></div>
                        </div>
                        <div className="col-6 align-self-center" key={triggerChart}>
                            <ProvocativeChart
                                data={provocativeItems}
                                sheet="ADX"
                            />
                        </div>
                    </div>
                </SectionCard>
            );
        };



        return <>
            <div className={`px-5 ${state?.tab === "reading" ? "bodyRegisterInfoReading" : " bodyRegisterInfo"}`}>
                <div className="px-2 my-3 ">
                    <div className="cardGeneralInfo row ">
                        <div className="col-3 fw-bold text-secondary">
                            <h5 className="fw-bold">Examen:</h5>
                        </div>
                        <div className="col-9 text-muted"><h5 className="fw-bold">{structure ? structure.name : state?.tab === "adx" ? serviceName : state?.data?.srvName}</h5></div>
                        <div className="col-3 fw-bold text-secondary">
                            <h5 className="fw-bold">Médico remitente:</h5>
                        </div>
                        <div className="col-9 text-muted"><h5 className="fw-bold">{(structure ? structure.referringDoctor : state?.tab === "adx" ? doctorName : state?.data?.attendedBy) ?? "Externo"}</h5></div>
                    </div>
                </div>

                <div className={` ${!requiresPressure && "sectionDisabled"} mb-3`}>
                    {renderPressureCurve()}
                </div>
                <div className={` ${!requiresDilation && "sectionDisabled"} mb-3`}>
                    {renderDilatationSection()}
                </div>
                <div className="d-flex row g-3 mb-3">
                    <div className={`${reportRequired ? "col-8" : "col"} ${!requiresImage && "sectionDisabled"}`}>
                        {renderImagesSection()}
                    </div>
                    <div className={`col-4 ${!reportRequired && "sectionDisabled"}`}>
                        {renderReportSection()}
                    </div>
                </div>
                <div className="pb-3 ">
                    {renderCommentSection()}
                </div>
            </div>
            <div className="d-flex align-items-end h-100">
                {!onlyBodyAdxSheet &&
                    (state?.tab === "reading" ?
                        readingSection()
                        :
                        <ConsultationControls isDisabledForm={isDisabledForm} tab={state?.tab} showButtonReport={(!requiresImage || !reportRequired)} />)
                }
            </div>
        </>;
    };
    const render = () => {
        return (
            <section className="diagnosticAids">
                <div className="d-flex h-100 overflow-auto ">
                    <section className={`${onlyBodyAdxSheet ? "col-12" : "col-7"} d-flex flex-column`}>
                        {!onlyBodyAdxSheet &&
                            <ConsultationHeader
                                isDisabledForm={isDisabledForm}
                                patientId={state?.data?.patientId}
                                appId={state.data?.appId ?? appId}
                                cluId={state.data?.cluId ?? cluId}
                                currentSection={state?.tab ?? ""} showHistory={showHistory} setShowHistory={(state) => setShowHistory(state)} />
                        }
                        {!onlyBodyAdxSheet && state?.tab === "transcription" ?
                            <TranscriptionForm data={state.data} setImagesPayload={setImagesPayload}
                                setFileSelected={setFileSelected} uploadFilesFunction={uploadFilesFunction}
                            />
                            :
                            bodyForm()
                        }
                    </section>
                    {!onlyBodyAdxSheet &&
                        (showHistory ?
                            <History setShowHistory={setShowHistory} width="100%" patientIdProp={state?.data?.patientId}
                                defaultHistory="diagnosticAids"
                                showClose={state.tab === "reading" || state.tab === "transcription" ? true : isAtipical ? (Boolean(isAtipical) === true ? false : true) : ((requiresImage || reportRequired))} />
                            :
                            <div
                                className={` col-5 backgroundSpaceSection ${(state?.showRenderSection ? !state?.showRenderSection : (!requiresImage && !reportRequired)) && "sectionDisabled"}`}>
                                {renderPdfSection()}
                            </div>)
                    }

                </div>
            </section>
        );
    };
    return render();
};

export default DiagnosticAidsSheet;