import { useEffect, useState } from "react";
//
import { Vision } from "@/models/select/vision";
import { getFarVisionList, getLensAdd, getLensType, getNearVisionList } from "@/services";
import { VisualAcuityParams } from "../VisualAcuity/VisualAcuity";
//
import { SectionCard, Select } from "@/components";
import { LentsVision } from "@/models/sheets/optometry";

export default function AvLensesInUse({ externalPayload, onSubmit, setExternalPayload, isDisabledForm, historySheet }: VisualAcuityParams) {
    const [farVision, setFarVision] = useState<Vision[]>([]);
    const [nearVision, setNearVision] = useState<Vision[]>([]);
    const [lensAdd, setLensAdd] = useState<Vision[]>([]);
    const [lensType, setLensType] = useState<Vision[]>([]);

    useEffect(() => {
        async function fetch() {
            if (!historySheet) {
                const farVisionResponse = await getFarVisionList();
                const nearVisionResponse = await getNearVisionList();
                const lensAddResponse = await getLensAdd();
                const lensTypeResponse = await getLensType();

                setFarVision(farVisionResponse.results);
                setNearVision(nearVisionResponse.results);
                setLensAdd(lensAddResponse.results);
                setLensType(lensTypeResponse.results);
            }
        }
        fetch();
    }, []);

    const farVisionFormatted = farVision?.map(item => ({ label: item.value, value: item.id }));
    const nearVisionFormatted = nearVision?.map(item => ({ label: item.value, value: item.id }));

    const lensAddFormatted = lensAdd?.map(item => ({ label: item.value, value: item.id }));
    const lensTypeFormatted = lensType?.map(item => ({ label: item.value, value: item.id }));

    const onChangeLentsInUse = (tableType: {
        vision: keyof LentsVision,
        eye: "oi" | "od";
        value?: number | string;
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                lentsInUse: {
                    ...externalPayload.visualAcuity?.lentsInUse,
                    [tableType.eye]: {
                        ...externalPayload.visualAcuity?.lentsInUse?.[tableType.eye],
                        [tableType.vision]: tableType.value
                    }
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const onChangeLentsInUseInputs = (inputType: {
        lentsType: "lentsOd" | "lentsOi";
        lentsItem: "sphere" | "cylinder" | "axis" | "brand";
        value: string;
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                lentsInUse: {
                    ...externalPayload.visualAcuity?.lentsInUse,
                    [inputType.lentsType]: {
                        ...externalPayload.visualAcuity?.lentsInUse?.[inputType.lentsType],
                        [inputType.lentsItem]: inputType.value
                    }
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const onChangeLentsType = (lentsType: {
        lentsType: "lentsOd" | "lentsOi";
        valueType: "add" | "len";
        value: number | string;
    }) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            visualAcuity: {
                ...externalPayload.visualAcuity,
                lentsInUse: {
                    ...externalPayload.visualAcuity?.lentsInUse,
                    [lentsType.lentsType]: {
                        ...externalPayload.visualAcuity?.lentsInUse?.[lentsType.lentsType],
                        [lentsType.valueType]: lentsType.value
                    }
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const render = () => {
        const odFarValue = farVisionFormatted.find(fv => fv.value === externalPayload.visualAcuity?.lentsInUse?.od?.far);
        const odFarValueInput = externalPayload.visualAcuity?.lentsInUse?.od?.far;
        const odNearValue = nearVisionFormatted.find(fv => fv.value === externalPayload.visualAcuity?.lentsInUse?.od?.near);
        const odNearValueInput = externalPayload.visualAcuity?.lentsInUse?.od?.near;

        const oiFarValue = farVisionFormatted.find(fv => fv.value === externalPayload.visualAcuity?.lentsInUse?.oi?.far);
        const oiFarValueInput = externalPayload.visualAcuity?.lentsInUse?.oi?.far;
        const oiNearValue = nearVisionFormatted.find(fv => fv.value === externalPayload.visualAcuity?.lentsInUse?.oi?.near);
        const oiNearValueInput = externalPayload.visualAcuity?.lentsInUse?.oi?.near;

        const odAddValue = lensAddFormatted.find(laf => laf.value === externalPayload.visualAcuity?.lentsInUse?.lentsOd?.add);
        const odAddValueInput = externalPayload.visualAcuity?.lentsInUse?.lentsOd?.add;
        const odLensTypeValue = lensTypeFormatted.find(laf => laf.value === externalPayload.visualAcuity?.lentsInUse?.lentsOd?.len);
        const odLensTypeValueInput = externalPayload.visualAcuity?.lentsInUse?.lentsOd?.len;

        const oIAddValue = lensAddFormatted.find(laf => laf.value === externalPayload.visualAcuity?.lentsInUse?.lentsOi?.add);
        const oIAddValueInput = externalPayload.visualAcuity?.lentsInUse?.lentsOi?.add;
        const oiLensTypeValue = lensTypeFormatted.find(laf => laf.value === externalPayload.visualAcuity?.lentsInUse?.lentsOi?.len);
        const oiLensTypeValueInput = externalPayload.visualAcuity?.lentsInUse?.lentsOi?.len;

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">AV Lentes en uso</h5>
                <hr className="text-primary mt-2" />
                <div className="row gx-1">
                    <div className="col">
                        <h5 className="text-secondary fw-bold text-center">Lente</h5>
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <td className="p-2"></td>
                                    <td align="center" className="text-secondary">Esfera</td>
                                    <td align="center" className="text-secondary">Cilindro</td>
                                    <td align="center" className="text-secondary">Eje°</td>
                                    <td align="center" className="text-secondary">ADD</td>
                                    <td align="center" className="text-secondary">Tipo de lente</td>
                                    <td align="center" className="text-secondary">Marca</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="ps-2 py-1 bg-od nowrap text-secondary" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                                    <td className="p-2 bg-od" width={70}>
                                        <input
                                            type="number"
                                            className="form-control"
                                            disabled={isDisabledForm}
                                            onChange={(event) => {
                                                const values = event.target.value.split(".");
                                                if (values[1] && values[1].length > 2) {
                                                    event.preventDefault();
                                                } else {
                                                    onChangeLentsInUseInputs({
                                                        lentsType: "lentsOd",
                                                        lentsItem: "sphere",
                                                        value: event.target.value
                                                    });
                                                }
                                            }}
                                            value={externalPayload.visualAcuity?.lentsInUse?.lentsOd?.sphere}
                                        />
                                    </td>
                                    <td className="p-2 bg-od" width={70}>
                                        <input
                                            type="number"
                                            disabled={isDisabledForm}
                                            className="form-control"
                                            onChange={(event) => {
                                                const values = event.target.value.split(".");
                                                if (values[1] && values[1].length > 2) {
                                                    event.preventDefault();
                                                } else {
                                                    onChangeLentsInUseInputs({
                                                        lentsType: "lentsOd",
                                                        lentsItem: "cylinder",
                                                        value: event.target.value
                                                    });
                                                }
                                            }}
                                            value={externalPayload.visualAcuity?.lentsInUse?.lentsOd?.cylinder}
                                        />
                                    </td>
                                    <td className="p-2 bg-od" width={70}>
                                        <input
                                            type="number"
                                            className="form-control"
                                            disabled={isDisabledForm}
                                            onChange={({ target }) => onChangeLentsInUseInputs({
                                                lentsType: "lentsOd",
                                                lentsItem: "axis",
                                                value: target.value
                                            })}
                                            value={externalPayload.visualAcuity?.lentsInUse?.lentsOd?.axis}
                                            onKeyDown={(event) => event.key === "." && event.preventDefault()}
                                        />
                                    </td>
                                    <td className="p-2 bg-od" width={80}>
                                        <Select
                                            options={lensAddFormatted}
                                            disabled={isDisabledForm}
                                            changeToInput={historySheet}
                                            inputValue={odAddValueInput}
                                            style={{ width: "100%" }}
                                            onChange={({ option }) => onChangeLentsType({
                                                lentsType: "lentsOd",
                                                valueType: "add",
                                                value: option.value
                                            })}
                                            value={odAddValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-od">
                                        <Select
                                            options={lensTypeFormatted}
                                            disabled={isDisabledForm}
                                            changeToInput={historySheet}
                                            inputValue={odLensTypeValueInput}
                                            style={{ width: "100%" }}
                                            onChange={({ option }) => onChangeLentsType({
                                                lentsType: "lentsOd",
                                                valueType: "len",
                                                value: option.value
                                            })}
                                            value={odLensTypeValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={isDisabledForm}
                                            onChange={({ target }) => onChangeLentsInUseInputs({
                                                lentsType: "lentsOd",
                                                lentsItem: "brand",
                                                value: target.value
                                            })}
                                            value={externalPayload.visualAcuity?.lentsInUse?.lentsOd?.brand}
                                        />
                                    </td>

                                </tr>
                                <tr>
                                    <td className="ps-2 py-1 bg-oi nowrap text-secondary" style={{ borderBottomLeftRadius: "0.5rem" }} width={30}>OI</td>
                                    <td className="p-2 bg-oi">
                                        <input
                                            type="number"
                                            className="form-control"
                                            disabled={isDisabledForm}
                                            onChange={(event) => {
                                                const values = event.target.value.split(".");
                                                if (values[1] && values[1].length > 2) {
                                                    event.preventDefault();
                                                } else {
                                                    onChangeLentsInUseInputs({
                                                        lentsType: "lentsOi",
                                                        lentsItem: "sphere",
                                                        value: event.target.value
                                                    });
                                                }
                                            }}
                                            value={externalPayload.visualAcuity?.lentsInUse?.lentsOi?.sphere}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi">
                                        <input
                                            type="number"
                                            className="form-control"
                                            disabled={isDisabledForm}
                                            onChange={(event) => {
                                                const values = event.target.value.split(".");
                                                if (values[1] && values[1].length > 2) {
                                                    event.preventDefault();
                                                } else {
                                                    onChangeLentsInUseInputs({
                                                        lentsType: "lentsOi",
                                                        lentsItem: "cylinder",
                                                        value: event.target.value
                                                    });
                                                }
                                            }}
                                            value={externalPayload.visualAcuity?.lentsInUse?.lentsOi?.cylinder}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi">
                                        <input
                                            type="number"
                                            className="form-control"
                                            disabled={isDisabledForm}
                                            onChange={({ target }) => onChangeLentsInUseInputs({
                                                lentsType: "lentsOi",
                                                lentsItem: "axis",
                                                value: target.value
                                            })}
                                            value={externalPayload.visualAcuity?.lentsInUse?.lentsOi?.axis}
                                            onKeyDown={(event) => event.key === "." && event.preventDefault()}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi">
                                        <Select
                                            options={lensAddFormatted}
                                            disabled={isDisabledForm}
                                            changeToInput={historySheet}
                                            inputValue={oIAddValueInput}
                                            style={{ width: "100%" }}
                                            onChange={({ option }) => onChangeLentsType({
                                                lentsType: "lentsOi",
                                                valueType: "add",
                                                value: option.value
                                            })}
                                            value={oIAddValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi">
                                        <Select
                                            options={lensTypeFormatted}
                                            disabled={isDisabledForm}
                                            changeToInput={historySheet}
                                            inputValue={oiLensTypeValueInput}
                                            style={{ width: "100%" }}
                                            onChange={({ option }) => onChangeLentsType({
                                                lentsType: "lentsOi",
                                                valueType: "len",
                                                value: option.value
                                            })}
                                            value={oiLensTypeValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                                        <input
                                            type="text"
                                            disabled={isDisabledForm}
                                            className="form-control"
                                            onChange={({ target }) => onChangeLentsInUseInputs({
                                                lentsType: "lentsOi",
                                                lentsItem: "brand",
                                                value: target.value
                                            })}
                                            value={externalPayload.visualAcuity?.lentsInUse?.lentsOi?.brand}
                                        />
                                    </td>
                                    <td className="p-2">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="vr h-100 ms-2 me-3" style={{ padding: 0.5 }}></div>
                    <div className="col-lg-6">
                        <h5 className="text-secondary fw-bold text-center">AV</h5>
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <td className="p-2"></td>
                                    <td align="center" className="text-secondary">VL</td>
                                    <td align="center" className="text-secondary">VP</td>
                                    <td align="center" className="text-secondary"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width={25} className="ps-2 py-1 bg-od text-secondary" style={{ borderTopLeftRadius: "0.5rem" }}>OD</td>
                                    <td width={100} className="p-2 bg-od">
                                        <Select
                                            options={farVisionFormatted}
                                            style={{ width: "100%" }}
                                            disabled={isDisabledForm}
                                            changeToInput={historySheet}
                                            inputValue={odFarValueInput}
                                            onChange={({ option }) => onChangeLentsInUse({
                                                eye: "od",
                                                vision: "far",
                                                value: option.value
                                            })}
                                            value={odFarValue}
                                        />
                                    </td>
                                    <td width={100} className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                                        <Select
                                            options={nearVisionFormatted}
                                            disabled={isDisabledForm}
                                            changeToInput={historySheet}
                                            inputValue={odNearValueInput}
                                            style={{ width: "100%" }}
                                            onChange={({ option }) => onChangeLentsInUse({
                                                eye: "od",
                                                vision: "near",
                                                value: option.value
                                            })}
                                            value={odNearValue}
                                        />
                                    </td>
                                    <td className="p-2" rowSpan={2}>
                                        <textarea
                                            className="form-control no-resize"
                                            style={{ height: 70 }}
                                            disabled={isDisabledForm}
                                            placeholder="Observaciones..."
                                            onChange={({ target }) => {
                                                setExternalPayload({
                                                    ...externalPayload,
                                                    visualAcuity: {
                                                        ...externalPayload.visualAcuity,
                                                        lentsInUse: {
                                                            ...externalPayload.visualAcuity?.lentsInUse,
                                                            observations: target.value
                                                        }
                                                    }
                                                });
                                                onSubmit();
                                            }}
                                            value={externalPayload.visualAcuity?.lentsInUse?.observations}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ps-2 bg-oi py-1 text-secondary" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                                    <td className="p-2 bg-oi">
                                        <Select
                                            options={farVisionFormatted}
                                            disabled={isDisabledForm}
                                            changeToInput={historySheet}
                                            inputValue={oiFarValueInput}
                                            style={{ width: "100%" }}
                                            onChange={({ option }) => onChangeLentsInUse({
                                                eye: "oi",
                                                vision: "far",
                                                value: option.value
                                            })}
                                            value={oiFarValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                                        <Select
                                            options={nearVisionFormatted}
                                            disabled={isDisabledForm}
                                            changeToInput={historySheet}
                                            inputValue={oiNearValueInput}
                                            style={{ width: "100%" }}
                                            onChange={({ option }) => onChangeLentsInUse({
                                                eye: "oi",
                                                vision: "near",
                                                value: option.value
                                            })}
                                            value={oiNearValue}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}