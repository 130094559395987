import { useEffect, useState } from "react";
import { IconChevronRight, IconSearch } from "@tabler/icons-react";
//
import { Modal, Table, TextField } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { getPredefinedTexts } from "@/features/Workspace/Gate/PredefinedTextsNoIns/predefinedTexts.actions";
import { PredefinedText, PredefinedTextsResponse } from "@/models";
//
import { useAppDispatch, useAppSelector } from "@/hooks";
import "../../Oftalmology/TextConfig/TextConfig.scss";
import "./TextConfig.scss";
import { useDebounce } from "use-debounce";

interface ITextConfigModal extends IModal {
    onSelectItem: (current: string, item: { label: string; value: number; }, index: number) => void;
    current: string;
}

export default function TextConfigModal({ onClose, onSelectItem, current, title, ...rest }: ITextConfigModal) {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);

    const [predefinedTextsResult, setPredefinedTextsResult] = useState<PredefinedTextsResponse>();
    const [selectedText, setSelectedText] = useState<PredefinedText | null>(null);
    const [searchValue, setSearchValue] = useDebounce<string>("", 1000);

    useEffect(() => {
        async function fetchData() {
            if (rest.isOpen) {
                const data = await dispatch(getPredefinedTexts({
                    eaccount: accountId,
                    type: current === "conclusion" || current === "surgery_area_admission_notes" ? "non-institutional" : "institutional",
                    search: searchValue,
                    users: [userId as number],
                    component: current === "surgery_area_admission_notes" ? "nurseryNotes" : current === "conclusion" ? "diagnosticAids" : currentSheet,
                    dtlSegmentPrefix: current === "surgery_area_admission_notes" ? current : current === "conclusion" ? current : current === "reasonForConsulting" ? "reason_attention" : "current_disease"
                }));
                setPredefinedTextsResult(data as PredefinedTextsResponse);
            }
        }
        fetchData();
    }, [dispatch, searchValue, accountId, userId, current, currentSheet, rest.isOpen]);

    const handleCloseModal = () => {
        setSelectedText(null);
        setSearchValue("");
        setPredefinedTextsResult(undefined);
        onClose();
    };

    const handleSearch = (value: string): void => setSearchValue(value);

    const handleSelectText = (text: PredefinedText) => {
        setSelectedText(text);
    };

    const renderSelectedText = (item: PredefinedText) => {
        return (
            <div className="flex-grow-1" style={{ marginTop: 40 }}>
                <div className="row">
                    <div className="col">
                        <div className="text-muted fw-bold mt-1">Código</div>
                        <div className="text-muted fw-bold mt-1">Módulo</div>
                        <div className="text-muted fw-bold mt-1">Componente</div>
                        <div className="text-muted fw-bold mt-1">Segmento</div>
                        <div className="text-muted fw-bold mt-1">Detalle del segmento</div>
                    </div>
                    <div className="col">
                        <div className="mt-1">{item.id}</div>
                        <div className="mt-1">{item.moduleName}</div>
                        <div className="mt-1">{item.componentName}</div>
                        <div className="mt-1">{item.segName}</div>
                        <div className="mt-1">{item.dtlSegmentName}</div>
                    </div>
                </div>
                <div className="textarea__with-header mt-3">
                    <div className="textarea-header">
                        <span>Descripción</span>
                    </div>
                    <textarea
                        value={item.description}
                        rows={6}
                        readOnly
                    />
                </div>
            </div>
        );
    };

    const renderRow = (item: PredefinedText, index: number) => {
        return (
            <tr key={item.id}>
                <td
                    style={{ minWidth: "60px" }}
                    onClick={() => handleSelectText(item)}
                >
                    {item.id}
                </td>
                <td
                    style={{ width: "100%" }}
                    onClick={() => handleSelectText(item)}
                >
                    {item.description}
                </td>
                <td>
                    <IconChevronRight
                        size={15}
                        className="text-secondary"
                        onClick={() => {
                            onSelectItem(current, { label: item.description, value: item.id }, index);
                            setSearchValue("");
                            setSelectedText(null);
                        }}
                    />
                </td>
            </tr>
        );
    };

    const renderTable = () => {
        return (
            <Table className="textconfig-modal__table-wrapper" isCompact>
                <thead>
                    <tr>
                        <th style={{ minWidth: "60px" }}>Código</th>
                        <th style={{ width: "100%" }}>Descripción</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{ maxHeight: 250 }}>
                    {predefinedTextsResult?.results?.length
                        ? predefinedTextsResult?.results?.map((item, index) => renderRow(item, index + 1))
                        : (
                            <tr>
                                <td className="text-center" style={{ width: "100%" }}>
                                    No hay textos predefinidos disponibles
                                </td>
                            </tr>
                        )}
                </tbody>
            </Table>
        );
    };

    const render = () => {
        return (
            <Modal onClose={handleCloseModal} {...rest}>
                <h2 className="fw-bold text-secondary">
                    {title ?? "Textos predefinidos institucionales"}
                </h2>
                <div className="row gx-5 w-100 mt-3">
                    <div className="col">
                        <TextField
                            className="w-100 mb-3"
                            variant="filled"
                            endIcon={<IconSearch />}
                            placeholder="Escribe código o descripción..."
                            onChange={({ target }) => handleSearch(target.value)}
                        />
                        {renderTable()}
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="text-muted fw-bold">Total</span>
                            <span className="text-muted">{predefinedTextsResult?.results?.length}</span>
                            <div></div>
                        </div>
                    </div>
                    <div className="col d-flex flex-column">
                        {selectedText ? renderSelectedText(selectedText) : (
                            <h3 className="text-muted text-center mt-1">
                                Seleccione un texto predefinido
                            </h3>
                        )}
                    </div>
                </div>
            </Modal >
        );
    };

    return render();
}