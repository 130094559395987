import { SectionCard, Select } from "@/components";
import { useAppDispatch } from "@/hooks";
import { IdentifiedLetter } from "@/models/select/indentifiedLetter";
import { OptotypeItem } from "@/models/select/optotype";
import { Vision } from "@/models/select/vision";
import { GeneralSelects, SubjectiveRefractionEye } from "@/models/shared/subjectiveRefraction";
import { LowVisionPayload } from "@/models/sheets/lowVision";
import { OptometryPayload } from "@/models/sheets/optometry";
import { useEffect, useState } from "react";
import { getSelects } from "./SubjectiveRefraction.actions";

interface SubjectiveRefractionParams {
    externalPayload: OptometryPayload | LowVisionPayload;
    setExternalPayload: (visualPayload: OptometryPayload | LowVisionPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
    historySheet?: boolean;
    title?: string;
    prefix?: string;
}

const SubjectiveRefraction = ({ externalPayload, setExternalPayload, onSubmit, isDisabledForm, historySheet, title, prefix }: SubjectiveRefractionParams) => {
    const dispatch = useAppDispatch();
    const startKey = prefix === "cicloplejia" ? externalPayload.subjectiveRefractionCycloplegia :  externalPayload.subjectiveRefraction;
    const [generalSelects, setGeneralSelects] = useState<GeneralSelects>({
        optotypes: [],
        farVision: [],
        nearVision: [],
        idLetters: [],
        lensAdd: [],
        lensType: []
    });
    const formattedAddSelect = generalSelects.lensAdd.map(sel => ({
        label: sel.value,
        value: sel.id
    }));
    const lensTypeFormatted = generalSelects.lensType?.map(item => ({ label: item.value, value: item.id }));
    const addODSelectValue = formattedAddSelect.find(item => item.value === startKey?.od?.add);
    const addODSelectValueInput = startKey?.od?.add;
    const addOISelectValue = formattedAddSelect.find(item => item.value === startKey?.oi?.add);
    const addOISelectValueInput = startKey?.oi?.add;
    const lensODSelectValue = lensTypeFormatted.find(item => item.value === startKey?.od?.len);
    const lensODSelectValueInput = startKey?.od?.len;
    const lensOISelectValue = lensTypeFormatted.find(item => item.value === startKey?.oi?.len);
    const lensOISelectValueInput = startKey?.oi?.len;

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await dispatch(getSelects());
                setGeneralSelects({
                    optotypes: data?.optotypesResponse as OptotypeItem[],
                    farVision: data?.farVisionResponse as Vision[],
                    nearVision: data?.nearVisionResponse as Vision[],
                    idLetters: data?.idLettersResponse as IdentifiedLetter[],
                    lensAdd: data?.lensAddResponse as Vision[],
                    lensType: data?.lensTypeResponse as Vision[]
                });
            }
        }
        fetchData();
    }, [dispatch]);

    const onChangeSRefractionValue = (eye: "od" | "oi", label: keyof SubjectiveRefractionEye, value: string | number) => {

        const key = prefix === "cicloplejia" ? "subjectiveRefractionCycloplegia"   :"subjectiveRefraction" ;

        const options: OptometryPayload = {
            ...externalPayload,
            [key]: {
                ...startKey,
                [eye]: {
                    ...startKey?.[eye],
                    [label]: value
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    };
    const onChangeRefractionObservation = (refraction: "objectiveRefraction" | "subjectiveRefraction", value: string) => {
        let key:  "objectiveRefraction" | "subjectiveRefraction" | "subjectiveRefractionCycloplegia" | "objectiveRefractionCycloplegia" ;
        if(refraction === "objectiveRefraction"){
            key = prefix === "cicloplejia" ?  "objectiveRefractionCycloplegia" : "objectiveRefraction";
        }else{
            key = prefix === "cicloplejia" ? "subjectiveRefractionCycloplegia" : "subjectiveRefraction"  ; 
        }
        const options: OptometryPayload = {
            ...externalPayload,
            [key]: {
                ...externalPayload[key],
                observations: value
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    return (
        <SectionCard className="h-100">
            <div className="d-flex justify-content-between align-items-center">
                <h5 className="text-secondary fw-bold">{title ? title :"Refracción subjetiva"}</h5> 
            </div>
            <hr className="text-primary mt-2" />
            <table>
                <thead>
                    <tr>
                        <td className="p-2"></td>
                        <td className="text-secondary" align="center">Esfera</td>
                        <td className="text-secondary" align="center">Cilindro</td>
                        <td className="text-secondary" align="center">Eje°</td>
                        <td className="text-secondary" align="center">ADD</td>
                        <td className="text-secondary" align="center">Tipo de lente</td>
                        <td className="text-secondary" align="center">Marca</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-secondary px-2 bg-od" style={{ borderTopLeftRadius: "0.5rem" }} width={20}>OD</td>
                        <td className="p-2 bg-od">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={startKey?.od?.sphere}
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeSRefractionValue("od", "sphere", event.target.value);
                                    }
                                }}
                            />
                        </td>
                        <td className="p-2 bg-od">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={startKey?.od?.cylinder}
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeSRefractionValue("od", "cylinder", event.target.value);
                                    }
                                }}
                            />
                        </td>
                        <td className="p-2 bg-od">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={startKey?.od?.axis}
                                onChange={({ target }) => onChangeSRefractionValue("od", "axis", target.value)}
                                onKeyDown={(event) => event.key === "." && event.preventDefault()}
                            />
                        </td>
                        <td className="p-2 bg-od">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={addODSelectValueInput}
                                options={formattedAddSelect}
                                value={addODSelectValue}
                                onChange={({ option }) => onChangeSRefractionValue("od", "add", option.value)}
                                style={{ width: "100%" }}
                            />
                        </td>
                        <td className="p-2 bg-od">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={lensODSelectValueInput}
                                options={lensTypeFormatted}
                                value={lensODSelectValue}
                                onChange={({ option }) => onChangeSRefractionValue("od", "len", option.value)}
                                style={{ width: "100%" }}
                            />
                        </td>
                        <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                value={startKey?.od?.brand}
                                onChange={({ target }) => onChangeSRefractionValue("od", "brand", target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="px-2 text-secondary bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                        <td className="p-2 bg-oi">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={startKey?.oi?.sphere}
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeSRefractionValue("oi", "sphere", event.target.value);
                                    }

                                }}
                            />
                        </td>
                        <td className="p-2 bg-oi">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={startKey?.oi?.cylinder}
                                onChange={(event) => {
                                    const values = event.target.value.split(".");
                                    if (values[1] && values[1].length > 2) {
                                        event.preventDefault();
                                    } else {
                                        onChangeSRefractionValue("oi", "cylinder", event.target.value);
                                    }
                                }}
                            />
                        </td>
                        <td className="p-2 bg-oi">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={startKey?.oi?.axis}
                                onChange={({ target }) => onChangeSRefractionValue("oi", "axis", target.value)}
                                onKeyDown={(event) => event.key === "." && event.preventDefault()}
                            />
                        </td>
                        <td className="p-2 bg-oi">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={addOISelectValueInput}
                                options={formattedAddSelect}
                                value={addOISelectValue}
                                onChange={({ option }) => onChangeSRefractionValue("oi", "add", option.value)}
                                style={{ width: "100%" }}
                            />
                        </td>
                        <td className="p-2 bg-oi">
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={lensOISelectValueInput}
                                options={lensTypeFormatted}
                                value={lensOISelectValue}
                                onChange={({ option }) => onChangeSRefractionValue("oi", "len", option.value)}
                                style={{ width: "100%" }}
                            />
                        </td>
                        <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                value={startKey?.oi?.brand}
                                onChange={({ target }) => onChangeSRefractionValue("oi", "brand", target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={7} className="p-2">
                            <textarea
                                className="form-control no-resize"
                                disabled={isDisabledForm}
                                value={startKey?.observations}
                                onChange={({ target }) => onChangeRefractionObservation("subjectiveRefraction", target.value)}
                                placeholder="Observaciones..."
                                style={{ height: 40 }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </SectionCard>
    );
};

export default SubjectiveRefraction;