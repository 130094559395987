interface FormatParams {
    value: string | number | null | undefined;
    patterns?: number[];
    separator?: string;
    execute?: boolean;
}

const setting = {
    patterns: process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR_PATTERN
        ? process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR_PATTERN.split(",").map(str => parseInt(str, 10)).filter(num => !isNaN(num))
        : [],
    separator: process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR || "",
    execute: process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR_PATTERN && process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR
        ? process.env.REACT_APP_APPLY_DOCUMENT_IDS_SEPARATOR === "true"
        : false,
};

/**
 * Formatea un número según un patrón especificado, insertando un separador en posiciones definidas.
 *
 * Esta función toma un número y lo convierte en una cadena formateada según los patrones y el separador proporcionados.
 * Los patrones definen la longitud de cada segmento antes de insertar el separador. Además, se puede activar o desactivar
 * el formateo según sea necesario.
 *
 * @param {FormatParams} params - Parámetros para formatear el número.
 * @returns {{value: string, format: string}} Un objeto que contiene el valor original y la cadena formateada.
 */
export const formatteDocument = ({ value, patterns = setting.patterns, separator = setting.separator, execute = setting.execute }: FormatParams): { value: string | number | null | undefined, format: string | number | null | undefined} => {
    if (!execute || !value) {
        return { value: value, format: value };
    }

    const string: string = value.toString().replace(/\D/g, "");
    let formattedStr = "";
    let index= 0;

    for (let i = 0; i < patterns.length; i++) {
        if (index >= string.length) break;

        const partLength = patterns[i];
        const part = string.slice(index, index + partLength);
        formattedStr += part;

        index += partLength;

        if (index < string.length) {
            formattedStr += separator;
        }
    }

    if (index < string.length) {
        formattedStr += string.slice(index);
    }

    const parsedValue = string.replace(/\D/g, "");

    return { value: parsedValue, format: formattedStr };
};