import { forwardRef } from "react";

import RecordChartComponent from "@/features/Workspace/PatientAttention/Surgery/components/RecordChart";

import { AnesthesiaNotes } from "@/models/surgery/patientAttention/Anesthesiologist";
import { SurgeryHistoryDownload } from "@/models/surgery/surgeryStages";

import { DownloadHistory } from "@/models/generalHistory/generalHistory";
import { BackgroundDetail as IBackgroundDetail } from "@/models/historyDetails/background";
import { OrderDetail } from "@/models/historyDetails/orders";
import { SurgicalDescription } from "@/models/historyDetails/surgicalDescription";
import { NurseryNotes } from "@/models/surgery/patientAttention/patientAttention";
import "../../Details.scss";
import BackgroundDetail from "./BackgroundDetail/BackgroundDetail";
import OrdersDetail from "./OrdersDetail/OrdersDetail";
import { formatteDocument } from "@/utils";


interface ISurgeryDownloadFileProps {
    appId?: number;
    data?: SurgeryHistoryDownload;
    isHistory?: boolean;
    dataHistory?: DownloadHistory[];
}

function calcTime(time1: string, time2: string) {
    const difSeconds = Date.parse(time1) - Date.parse(time2);

    const hours = Math.floor(difSeconds / (3600 * 1000));
    const min = Math.round((difSeconds % (3600 * 1000)) / (60 * 1000));

    const formato = `${hours ? hours + "h " : ""} ${min}m`;
    return formato;
}

const SurgeryDownloadFile = forwardRef<HTMLTableElement, ISurgeryDownloadFileProps>((props, ref) => {
    const { anesthesiaNotes, surgicalDescription, nurseryNotes, orders, background } = props?.data ?? {};
    const renderBackground = (data: IBackgroundDetail) => {
        return (
            <div style={{ marginTop: "1rem", display: "block", pageBreakAfter: "always" }}>
                <BackgroundDetail data={data} download />
            </div >
        );
    };

    const renderAnesthesiaNotes = (data: AnesthesiaNotes) => {

        return (
            <div style={{ marginTop: "1rem", display: "block", pageBreakAfter: "always" }}>
                <div className="mb-3 text-muted">
                    <h4 className="fw-bold text-secondary">Anestesia</h4>
                    <h4 className="text-primary my-4 fw-bold">Información anestesia</h4>
                    <div><span className="fw-bold">Anestesiólogo: </span>{data.anesthesiologistName}</div>
                    <div><span className="fw-bold">Tipo de anestesia: </span>{data.aneName}</div>
                    <div><span className="fw-bold">Oxígeno: </span>{data.oxiName}</div>
                </div>
                {data.drugs_for_induction && data.drugs_for_induction.length > 0 && (
                    <div className="mb-3 text-muted">
                        <h4 className="fw-bold my-4 text-primary">Líquidos y drogas para inducción</h4>
                        <div>{data.drugs_for_induction.join(", ")}</div>
                    </div>
                )}

                {data.anestheticRecord && (
                    <div className="mb-3 text-muted">
                        <h4 className="fw-bold my-4 text-primary">Récord anestésico</h4>
                        <div><span className="fw-bold">Inicio anestesia: </span>{data.anestheticRecord.startOfAnesthesia}</div>
                        <div><span className="fw-bold">Inicio cirugía: </span>{data.anestheticRecord.startOfSurgery}</div>
                        <div><span className="fw-bold">Fin cirugía: </span>{data.anestheticRecord.endOfSurgery}</div>
                        <div><span className="fw-bold">Duración cirugía: </span>
                            {data.anestheticRecord.endOfSurgery ?
                                calcTime(data.anestheticRecord?.endOfSurgery, data.anestheticRecord?.startOfSurgery) : "-"}
                        </div>
                        <RecordChartComponent
                            appId={props.appId}
                            data={data.anestheticRecord}
                            heightProp={300}
                            widthProp="70%"
                        />
                    </div>
                )}

                {data.ansGeneralAnesthesiaNotes && (
                    <div className="mb-3 text-muted">
                        <h4 className="fw-bold my-4 text-primary">Notas generales de anestesia</h4>
                        <div>{data.ansGeneralAnesthesiaNotes}</div>
                    </div>
                )}

                {data.ansDischargeAnesthesiaNotes && (
                    <div className="mb-3 text-muted">
                        <h4 className="fw-bold my-4 text-primary">Notas de egreso de anestesia</h4>
                        <div>{data.ansDischargeAnesthesiaNotes}</div>
                    </div>
                )}
            </div>
        );
    };

    const renderNurseryNotes = (data: NurseryNotes) => {
        return (
            <div className="mb-4" style={{ marginTop: "1rem", display: "block", pageBreakAfter: "always" }}>
                <h4 className="fw-bold  text-secondary my-4">Notas de enfermería</h4>

                <div>
                    {data?.admission &&
                        <div>
                            <h4 className="fw-bold text-primary my-4">Admisión</h4>
                            <div className="text-muted mb-2"><span className="fw-bold  mb-4">Signos vitales: </span>
                                FC: {data?.admission?.vitalSigns?.fc?.value} -
                                SO2: {data?.admission?.vitalSigns?.so2?.value} -
                                TAM: {data?.admission?.vitalSigns?.tam?.value} -
                                FR: {data?.admission?.vitalSigns?.fr?.value} -
                                TA: {data?.admission?.vitalSigns?.ta?.value} -
                                G: {data?.admission?.vitalSigns?.g?.value} -
                            </div>

                            {data?.admission?.checklist && data?.admission.checklist.length > 0 &&
                                data.admission.checklist.map(checklist => {
                                    return (
                                        <div className="text-muted">
                                            {checklist.description}
                                        </div >
                                    );
                                })
                            }

                            <div className="text-muted my-2"><span className="fw-bold  mb-4">Notas de admisión: </span>
                                {data?.admission?.admissionNotes}

                            </div>


                        </div>
                    }
                    {data?.income &&

                        <div>
                            <h4 className="fw-bold  text-primary my-4">Ingreso</h4>
                            <div className="text-muted my-2">
                                {data?.income?.numCheckOrgan && "Se marca órgano a operar"}

                            </div>
                            <div className="text-muted my-2"><span className="fw-bold  mb-4">Notas de ingreso: </span>
                                {data?.income?.numSurgeryAreaAdmissionNotes}

                            </div>

                        </div>
                    }
                    {data?.preparation &&

                        <div>
                            <h4 className="fw-bold  text-primary my-4">Preparación</h4>

                            <div className="text-muted my-2"><span className="fw-bold  mb-4">Asepsia y antisepsia del paciente: </span>
                                {data?.preparation.numAsepsisAntisepsis}

                            </div>
                            {data?.preparation.drugs_for_induction && data?.preparation.drugs_for_induction.length > 0 &&
                                <div className="text-muted my-2"><span className="fw-bold  mb-4">Drogas para la inducción: </span>
                                    {data?.preparation.drugs_for_induction.join(", ")}

                                </div>

                            }
                        </div>
                    }
                    {data?.surgery &&

                        <div>
                            <h4 className="fw-bold  text-primary my-4">Acto quirúrgico</h4>
                            <div className="text-muted my-2"><span className="fw-bold  mb-4">Comentarios sobre dispositivos médicos usados: </span>
                                {data?.surgery.numReviewsUsedMedicalDevices}

                            </div>
                            {data?.surgery.special_supplies && data?.surgery.special_supplies.length > 0 &&
                                <div className="text-muted my-2"><span className="fw-bold  mb-4">Insumos especiales: </span>
                                    {data?.surgery.special_supplies.map(item => item.name).join(", ")}

                                </div>

                            }
                            <div className="text-muted my-2"><span className="fw-bold  mb-4">Observación sala de cirugía: </span>
                                {data?.surgery.numSurgeryObservation}

                            </div>

                        </div>}
                    {data?.recovery &&

                        <div className="mb-2">
                            <h4 className="fw-bold  text-primary my-4">Recuperación</h4>

                            <div className="text-muted my-2"><span className="fw-bold  mb-4">Observación de recuperación: </span>
                                {data?.recovery.numRecoveryNotes}

                            </div>
                            <div className="text-muted my-2"><span className="fw-bold  mb-4">Correcta identificación y gestión de muestras biológicas: </span>
                                {data?.recovery.numCheckBiologicalSamples ? "SÍ" : "NO"}

                            </div>

                            <div className="text-muted my-2"><span className="fw-bold  mb-4">Se realiza procedimiento quirúrgico completo:</span>
                                {data?.recovery.numCheckInformationProcess ? " Sí" : " No aplica"}

                            </div>
                            <div className="text-muted my-2">
                                El paciente y su acompañante reciben por escrito Información sobre el proceso realizado, instrucciones para su cuidado post-quirúrgico, fórmula médica y recordatorio de cita para revisión.
                            </div>

                            <div className="printable">
                                <img width={200} height={100} src={data.recovery.companion.sucSignature} alt="" />
                                <h5 className="fw-bold m-0">{data.recovery.companion.sucCompleteName}</h5>
                                <h5 className="fst-italic m-0">{formatteDocument({ value: data.recovery.companion.sucDocumentNumber }).format}</h5>
                                <h5 className="fst-italic m-0">Acompañante</h5>
                            </div>
                        </div>}

                </div>
            </div>
        );
    };
    const renderOrders = (data: OrderDetail) => {
        return (
            <div style={{ marginTop: "1rem", display: "block" }}>
                <OrdersDetail data={data} download />

            </div>);
    };


    const renderSurgicalDescription = (data: SurgicalDescription) => {
        return (
            <div style={{ marginTop: "1rem", display: "block" }}>
                <div className="mb-3 text-muted" >
                    <h4 className="fw-bold text-secondary">Descripción quirúrgica</h4>
                    <h4 className="text-primary my-4 fw-bold">Equipo quirúrgico</h4>
                    <div><span className="fw-bold">Cirujano principal: </span>{data?.surgicalTeam?.surgeonName}</div>
                    <div><span className="fw-bold">Cirujano ayudante: </span> {data?.surgicalTeam?.supportSurgeon}</div>
                    <div><span className="fw-bold">Instrumentador: </span>{data?.surgicalTeam?.supportNurse}</div>
                    <div><span className="fw-bold">Rotador: </span>{data?.surgicalTeam?.supportRotator}</div>
                    <div><span className="fw-bold">Anestesiólogo: </span>{data?.surgicalTeam?.anesName}</div>
                </div>
                {data?.sunDescription && (
                    <div className="mb-3 text-muted">
                        <h4 className="fw-bold my-4 text-primary">Descripción</h4>
                        <div>{data.sunDescription}</div>
                    </div>
                )}

                {(data?.variables && data?.variables?.length > 0) && (
                    <div className="mb-3 text-muted">
                        <h4 className="fw-bold text-primary">Variables especificas</h4>
                        {data.variables.map(v => {
                            return (
                                <div><span className="fw-bold">{v.name}: </span>{v.value} {v.unit && (v.unit)}</div>

                            );
                        })}
                    </div>
                )}

                {data?.preDiagnosis && (
                    <div className="mb-3 text-muted">
                        <h4 className="fw-bold my-4 text-primary">Impresión diagnostica</h4>
                        <div><span className="fw-bold">Pre-operatoria: </span>
                            {data.preDiagnosis?.cie10} {data.preDiagnosis?.name} - {data.preDiagnosis?.organ}

                        </div>
                        <div>
                            <span className="fw-bold">Post-operatoria: </span>
                        </div>
                        <div className="p-4">

                            <div className="row">
                                <div className="fw-bold text-center text-primary col-2"> CIE-10</div>
                                <div className="fw-bold text-primary col-4"> Impresión diagnostica</div>
                                <div className="fw-bold text-primary col-4"> Impresión diagnostica ampliada</div>
                                <div className="fw-bold text-center text-primary col-2"> Órgano</div>
                                <hr className="text-muted my-2" />
                            </div>

                            {(data.surgicalNotesDiagnosis && data?.surgicalNotesDiagnosis.length > 0) &&
                                data?.surgicalNotesDiagnosis?.map(impression => {


                                    return (
                                        <div className="row">
                                            <div className=" text-center align-self-center text-muted col-2"> {impression.cie10Code}</div>
                                            <div className=" text-muted align-self-center col-4"> {impression.cie10Description}</div>
                                            <div className=" text-muted align-self-center col-4"> {impression.extDiagnosticDescription ?? "-"}</div>
                                            <div className="text-center align-self-center text-muted col-2"> {impression.dbo?.name}</div>
                                            <hr className="text-muted my-2" />
                                        </div>
                                    );
                                })


                            }



                        </div>

                    </div>
                )}

            </div>
        );
    };

    const renderComponent = () => {
        return <>
            {props.data ?
                <table {...props} border={0} ref={ref} className="w-100">
                    <thead>
                        <tr>
                            <td>
                                <div className="print-header">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img src={props.data.detailForDownload.header.logo} alt="cofca_logo" width={150} height={50} style={{ objectFit: "contain" }} />
                                        <h2 className="text-secondary fw-bold">
                                            {props.data.detailForDownload.header.name_of_the_institution}
                                        </h2>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className="row p-3 py-2 align-items-center bg-light text-muted mt-3">
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h5 className="fw-bold">Paciente:</h5>
                                                <h5 className="fw-bold">Documento:</h5>
                                            </div>
                                            <div className="col-8">
                                                <h5>{props.data.detailForDownload.header.patient.name}</h5>
                                                <h5>{formatteDocument({ value: props.data.detailForDownload.header.patient.doc }).format}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <h5 className="fw-bold nowrap">Fecha de nacimiento:</h5>
                                                <h5 className="fw-bold">Género:</h5>
                                            </div>
                                            <div className="col-6">
                                                <h5>{props.data.detailForDownload.header.patient.birthdate}</h5>
                                                <h5>{props.data.detailForDownload.header.patient.gender}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 10 }}></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="printable">
                                    <div className="mb-3 d-flex justify-content-between text-secondary w-100 bg-primary-lighter p-3" style={{ marginTop: -5 }}>
                                        <h5 className="fw-bold m-0">
                                            {props.data.detailForDownload.body.datetime_it_was_made}
                                        </h5>
                                        <h5 className="fw-bold m-0">
                                            Evento N. {props.data.detailForDownload.body.event_number} - {props.data.detailForDownload.body.schedule_name}
                                        </h5>
                                        <h5 className="fw-bold m-0">
                                            Edad: {props.data.detailForDownload.body.patient_age} años
                                        </h5>
                                    </div>
                                    <div className="px-2">
                                        {background && renderBackground(background)}
                                        {nurseryNotes && renderNurseryNotes(nurseryNotes)}
                                        {anesthesiaNotes && renderAnesthesiaNotes(anesthesiaNotes)}
                                        {surgicalDescription && renderSurgicalDescription(surgicalDescription)}
                                        {orders && renderOrders(orders)}
                                    </div>


                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div className="printable">
                                    <img width={200} src={props.data.detailForDownload.footer.doctor.signature} alt="" />
                                    <h5 className="fw-bold m-0">{props.data.detailForDownload.footer.doctor.name}</h5>
                                    <h5 className="fst-italic m-0">{props.data.detailForDownload.footer.doctor.position}</h5>
                                    <h5 className="fst-italic m-0">R.M {props.data.detailForDownload.footer.doctor.medical_record}</h5>
                                </div>
                                <div className="align-bottom text-end text-muted position-fixed bottom-0 w-100" style={{ marginBottom: 0 }}>
                                    <h6 className="d-inline text-end mx-2">
                                        <span className="fw-bold">Teléfono: </span>
                                        {props.data.detailForDownload.footer?.appointment_phone}
                                    </h6>
                                    <h6 className="d-inline text-end mx-2">
                                        <span className="fw-bold">Email: </span>
                                        {props.data.detailForDownload.footer?.appointment_email}
                                    </h6>
                                    <h6 className="d-inline text-end mx-2">
                                        <span className="fw-bold">Dirección: </span>
                                        {props.data.detailForDownload.footer?.address}
                                    </h6>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                :
                <div ref={ref} >
                    {
                        props.dataHistory && props.dataHistory.length > 0 &&
                        props.dataHistory.map((data, ind) => {
                            const { anesthesiaNotes, surgicalDescription, nurseryNotes, orders, background, detailForDownload } = data ?? {};
                            return (
                                <table {...props} border={0} className="w-100"
                                    style={{ marginTop: "1rem", display: "block", pageBreakAfter: "always", pageBreakBefore: "always" }}
                                >
                                    <thead>
                                        <tr>
                                            <td>
                                                <div className="print-header">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <img src={detailForDownload?.header.logo} alt="cofca_logo" width={150} height={50} style={{ objectFit: "contain" }} />
                                                        <h2 className="text-secondary fw-bold">
                                                            {detailForDownload?.header.name_of_the_institution}
                                                        </h2>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                                <div className="row p-3 py-2 align-items-center bg-light text-muted mt-3">
                                                    <div className="col-6">
                                                        <div className="row align-items-center">
                                                            <div className="col">
                                                                <h5 className="fw-bold">Paciente:</h5>
                                                                <h5 className="fw-bold">Documento:</h5>
                                                            </div>
                                                            <div className="col-8">
                                                                <h5>{detailForDownload?.header.patient.name}</h5>
                                                                <h5>{formatteDocument({ value: detailForDownload?.header.patient.doc }).format}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row align-items-center">
                                                            <div className="col-6">
                                                                <h5 className="fw-bold nowrap">Fecha de nacimiento:</h5>
                                                                <h5 className="fw-bold">Género:</h5>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5>{detailForDownload?.header.patient.birthdate}</h5>
                                                                <h5>{detailForDownload?.header.patient.gender}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ height: 10 }}></div>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="printable">
                                                    <div className="mb-3 d-flex justify-content-between text-secondary w-100 bg-primary-lighter p-3" style={{ marginTop: -5 }}>
                                                        <h5 className="fw-bold m-0">
                                                            {detailForDownload?.body.datetime_it_was_made}
                                                        </h5>
                                                        <h5 className="fw-bold m-0">
                                                            Evento N. {detailForDownload?.body.event_number} - {detailForDownload?.body.schedule_name}
                                                        </h5>
                                                        <h5 className="fw-bold m-0">
                                                            Edad: {detailForDownload?.body.patient_age} años
                                                        </h5>
                                                    </div>
                                                    <div className="px-2">
                                                        {background && renderBackground(background)}
                                                        {nurseryNotes && renderNurseryNotes(nurseryNotes)}
                                                        {anesthesiaNotes && renderAnesthesiaNotes(anesthesiaNotes)}
                                                        {surgicalDescription && renderSurgicalDescription(surgicalDescription)}
                                                        {orders && renderOrders(orders)}
                                                    </div>


                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    {ind === 0 &&
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <div className="printable">
                                                        <img width={200} src={detailForDownload?.footer.doctor.signature} alt="" />
                                                        <h5 className="fw-bold m-0">{detailForDownload?.footer.doctor.name}</h5>
                                                        <h5 className="fst-italic m-0">{detailForDownload?.footer.doctor.position}</h5>
                                                        <h5 className="fst-italic m-0">R.M {detailForDownload?.footer.doctor.medical_record}</h5>
                                                    </div>
                                                    <div className="align-bottom text-end text-muted position-fixed bottom-0 w-100" style={{ marginBottom: 0 }}>
                                                        <h6 className="d-inline text-end mx-2">
                                                            <span className="fw-bold">Teléfono: </span>
                                                            {detailForDownload?.footer?.appointment_phone}
                                                        </h6>
                                                        <h6 className="d-inline text-end mx-2">
                                                            <span className="fw-bold">Email: </span>
                                                            {detailForDownload?.footer?.appointment_email}
                                                        </h6>
                                                        <h6 className="d-inline text-end mx-2">
                                                            <span className="fw-bold">Dirección: </span>
                                                            {detailForDownload?.footer?.address}
                                                        </h6>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>

                                    }
                                </table>
                            );
                        })
                    }
                </div>


            }
        </>;

    };

    return renderComponent();
});

export default SurgeryDownloadFile;